import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, TextField, Divider } from '@material-ui/core';
import { postUrl } from '@utils/ApiAction';
import { useTheme } from '@material-ui/core/styles';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CompanyEditEasyParcel({ id, companyOwner, epApiKey, setEpApiKey, ep, setEp, setLoading, addAlert }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const [inputErrors, setInputErrors] = useState([]);
    const [reminderOpen, setReminderOpen] = useState(false);
    // const [epCreateUser, setEpCreateUser] = useState(false);

    const handleReminderOpen = () => {
        setReminderOpen(true);
    }
    const handleReminderClose = () => {
        setReminderOpen(false);
    }

    useEffect(() => {
        
    }, []);

    // const logistic_create_user = () => {
    //     setLoading(true);
    //     setInputErrors("");
    //     const updateData = {
    //         ep_email: ep.email,
    //         ep_first_name: ep.firstName,
    //         ep_last_name: ep.lastName,
    //         company_id: id,
    //     }
    //     postUrl(`admin/logistic/create_user`, updateData).then(response => {
    //         setLoading(false);
    //         if(response.status === 1) {
    //             setEpCreateUser(false);
    //             setEpApiKey(response.data.api_key?response.data.api_key:'');
    //             setEp({ ...ep, apiKey: response.data.api_key?response.data.api_key:''});
    //             let msg = '';
    //             if(response.msg){
    //                 msg = response.msg;
    //             }else{
    //                 msg = t('snackbarMsg.updateSuccess');
    //             }
    //             addAlert('', msg, 'success', '');
    //         }else{
    //             if(response.data){
    //                 let msg = response.data;
    //                 addAlert('', msg, 'error', '');
    //             }else{
    //                 let msg = t('snackbarMsg.updateError');
    //                 addAlert('', msg, 'error', '');
    //             }
    //             if(response.errors){
    //                 setInputErrors(response.errors);
    //             }
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         let msg = error+"\n"+t('error.contactSupport');
    //         addAlert('', msg, 'error', '');
    //     });
    // }
    const logistic_update = () => {
        setLoading(true);
        setInputErrors("");
        setReminderOpen(false);
        const updateData = {
            // ep_email: ep.email,
            // ep_first_name: ep.firstName,
            // ep_last_name: ep.lastName,
            ep_api_key: ep.apiKey,
            company_id: id,
        }
        postUrl(`admin/logistic/update_account`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                // setEpCreateUser(false);
                setEpApiKey(response.data.api_key ? response.data.api_key : '');
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
            } else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if (response.errors) {
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return (
        <Grid container spacing={3} direction="row">
            {/* <Grid item xs={12} md={6}>
                <TextField 
                    id="ep_email" 
                    label={t('easyParcel.email')}
                    variant="outlined" 
                    fullWidth 
                    value={ep.email}
                    helperText={inputErrors.ep_email?inputErrors.ep_email:''}
                    error={inputErrors.ep_email?true:false}
                    onChange={({ target }) => setEp({ ...ep, email: target.value }) }
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField 
                    id="ep_first_name" 
                    label={t('easyParcel.firstName')}
                    variant="outlined" 
                    fullWidth 
                    value={ep.firstName}
                    helperText={inputErrors.ep_first_name?inputErrors.ep_first_name:''}
                    error={inputErrors.ep_first_name?true:false}
                    onChange={({ target }) => setEp({ ...ep, firstName: target.value }) }
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField 
                    id="ep_last_name" 
                    label={t('easyParcel.lastName')}
                    variant="outlined" 
                    fullWidth 
                    value={ep.lastName}
                    helperText={inputErrors.ep_last_name?inputErrors.ep_last_name:''}
                    error={inputErrors.ep_last_name?true:false}
                    onChange={({ target }) => setEp({ ...ep, lastName: target.value }) }
                />
            </Grid>
            {
                (!epApiKey)
                ?
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                          <Switch
                            checked={epCreateUser?true:false}
                            onChange={({ target }) => setEpCreateUser( target.checked ) }
                            name="ep_create_user"
                            color="primary"
                            />
                        }
                        labelPlacement="start"
                        label={t('easyParcel.createUser')}
                    />
                </Grid>
                :
                null
            }
            {
                (!epApiKey && epCreateUser)
                ?
                <Grid item xs={12} md={6}>
                    <Button variant="contained" size="medium" color="primary" onClick={logistic_create_user} >{t('button.epCreateUser')}</Button>
                </Grid>
                :
                <Grid item xs={12} md={6}>
                <Grid container spacing={3} direction="row">
                <Grid item xs={12} md={8}>
                    <TextField 
                        id="ep_api_key" 
                        label={t('easyParcel.apiKey')}
                        variant="outlined" 
                        fullWidth 
                        value={ep.apiKey}
                        helperText={inputErrors.ep_api_key?inputErrors.ep_api_key:''}
                        error={inputErrors.ep_api_key?true:false}
                        onChange={({ target }) => setEp({ ...ep, apiKey: target.value }) }
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    {
                        epApiKey
                        ?
                        <Button variant="contained" size="medium" color="primary" onClick={handleReminderOpen} >{t('button.update')}</Button>
                        :
                        <Button variant="contained" size="medium" color="primary" onClick={logistic_update} >{t('button.save')}</Button>
                    }
                </Grid>
                </Grid>
                </Grid>
            } */}
            <Grid item xs={12} style={{margin: 10}} >
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('easyParcel.epLogistic')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="ep_api_key"
                    label={t('easyParcel.apiKey')}
                    variant="outlined"
                    fullWidth
                    value={ep.apiKey}
                    helperText={inputErrors.ep_api_key ? inputErrors.ep_api_key : ''}
                    error={inputErrors.ep_api_key ? true : false}
                    onChange={({ target }) => setEp({ ...ep, apiKey: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    companyOwner && epApiKey?
                    <Button variant="contained" size="medium" color="primary" onClick={handleReminderOpen} >{t('button.update')}</Button>
                    :
                    companyOwner && !epApiKey ?
                    <Button variant="contained" size="medium" color="primary" onClick={logistic_update} >{t('button.save')}</Button>
                    :
                    null
                }
            </Grid>
            <Dialog onClose={handleReminderClose} open={reminderOpen} fullWidth>
                <DialogTitle onClose={handleReminderClose}>
                    {t('dialog.reminderTitle')}
                </DialogTitle>
                <DialogContent dividers>
                    {t('seller.changeApiContent')}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="primary" onClick={logistic_update}>
                        {t('button.update')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}