import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Paper, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { getUrl} from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PropTypes from 'prop-types';


// Icon
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

import LandingSlideBannerEdit from './LandingSlideBannerEdit';
import LandingAdvBannerEdit from './LandingAdvBannerEdit';
// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function LandingBanner() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tabValue, setTabValue] = useState(0);
    const [id, setId] = useState();

    const [slideBanner, setSlideBanner] = useState({});
    const [advBanner, setAdvBanner] = useState({});
    

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`admin/filebannerinfo`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setAdvBanner({ banners: _.sortBy(response.data, ['priority']) });

            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        getUrl('admin/slidebannerinfo').then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setSlideBanner({ banners: _.sortBy(response.data, ['priority']) });

            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        getUrl(`admin/getuserid`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setId(response.data);

            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        // eslint-disable-next-line
    }, [id, addAlert]);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('banner.landingBannerEdit')}</Typography>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" justify="space-around" style={{ backgroundColor: 'transparent', color: theme.palette.primary.main , WebkitBoxShadow: 'none' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
                        <Tab icon={<InsertPhotoIcon />} label={t('banner.insertSlideBanner')} {...a11yProps(0)} />
                        <Tab icon={<InsertPhotoIcon />} label={t('banner.insertAdvBanner')} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.mt30 }}>
                    <TabPanel value={tabValue} index={0}>
                        <LandingSlideBannerEdit id={id} setSlideBanner={setSlideBanner} slideBanner={slideBanner} setLoading={setLoading} addAlert={addAlert} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <LandingAdvBannerEdit id={id} setAdvBanner={setAdvBanner} advBanner={advBanner} setLoading={setLoading} addAlert={addAlert} />
                    </TabPanel>
                </Paper>
            </Grid>

        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    inputFile: {
        display: 'none'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
