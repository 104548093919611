import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField } from '@material-ui/core';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PageviewIcon from '@material-ui/icons/Pageview';
// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function areEqualShallow(a, b) {
    for(var key in a) {
        if(a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

export default function MerchantAdd() {
    const classes = useStyles();
    const theme = useTheme();

    let { comId } = useParams();
    let history = useHistory();

    const { role, isAdmin } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [reminderPopupOpen, setReminderPopupOpen] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [state, setState] = useState({
        companyID: comId?comId:'',
        name: '',
        username: '',
        email: '',
        mobile: '',
        role: '',
        pwd: '',
        cfm_pwd: '',
    });
    const [oldData, setOldData] = useState({
        companyID: comId?comId:'',
        name: '',
        username: '',
        email: '',
        mobile: '',
        role: '',
        pwd: '',
        cfm_pwd: '',
    });

    const handleReminderPopupOpen = (event) => {
        let pop = false;
        if(!areEqualShallow(state, oldData)){
            pop = true;
        }
        if(pop){
            setReminderPopupOpen(true);
        }else{
            history.push("/sellers/"+[comId]);
        }
    }
    const handleReminderPopupClose = () => {
        setReminderPopupOpen(false);
    };

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/merchants/add`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setUserRoles(response.data);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/companies_by_roles`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setCompanyList(response.data);
                if(response.company_id !== '' && state.companyID === ''){
                    setState({...state, companyID: response.company_id});
                }else if(response.company_id !== '' && state.companyID !== ''){
                    if(Number(response.company_id) !== Number(state.companyID)){
                        history.push("/");
                    }
                }
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            company_id: state.companyID,
            name: state.name,
            username: state.username,
            email: state.email,
            // mobile: state.mobile,
            role: state.role,
            password: state.pwd,
            password_confirmation: state.cfm_pwd,
        }
        postUrl(`admin/merchants/add`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = t('snackbarMsg.updateSuccess')+' ID: ' + response.data.id;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
                setOldData(state);
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('merchant.addMerchant')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/merchants`} component={RouterLink}>{t('merchant.merchants')}</Link>
                        <Typography style={{ color: 'black' }}>{t('merchant.addMerchant')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                <Grid container spacing={3} direction="row" style={theme.p20}>
                    {
                        comId
                        ?
                        <Grid item xs={12} md={12}>
                            <Button size="small" startIcon={<PageviewIcon />} onClick={handleReminderPopupOpen}>{t('button.viewSeller')}</Button>
                        </Grid>
                        :
                        null
                    }
                    <Dialog onClose={handleReminderPopupClose} open={reminderPopupOpen} fullWidth>
                        <DialogTitle onClose={handleReminderPopupClose}>
                            {t('merchant.viewCompanyPageTitle')}
                        </DialogTitle>
                        <DialogContent dividers>
                            {t('merchant.viewCompanyPageContent')}
                        </DialogContent>
                        <DialogActions>
                            <Link underline='none' color="inherit" to={"/companies/"+[comId]} component={RouterLink}>
                                <Button autoFocus color="primary">
                                    {t('button.yesPlease')}
                                </Button>
                            </Link>
                        </DialogActions>
                    </Dialog>
                    {
                        isAdmin &&
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="companyID" 
                                label={t('seller.shopName')}
                                variant="outlined" 
                                fullWidth 
                                select
                                disabled = {comId?true:false}
                                value={state.companyID}
                                // helperText={inputErrors.company_id?inputErrors.company_id:''}
                                error={inputErrors.company_id?true:false}
                                onChange={({ target }) => setState({ ...state, companyID: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {companyList.map((value, key) => (
                                value.type === 1 ?
                                <option key={value.id} value={value.id}>
                                    { value.shop_name + ' [ID: ' + value.id + ']'}
                                </option>
                                : null
                            ))}
                            </TextField>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="role" 
                            label={t('user.role')} 
                            variant="outlined" 
                            fullWidth 
                            select
                            value={state.role}
                            helperText={inputErrors.role?inputErrors.role:''}
                            error={inputErrors.role?true:false}
                            onChange={({ target }) => setState({ ...state, role: target.value }) }
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                        <option key='' value='' disabled>
                            {t('general.pleaseSelect')}
                        </option>
                        {userRoles.map((value, key) => {
                            const displayName = _.split(value.display_name, '|');
                            return (
                                <option key={key} value={value.name}>
                                    { i18n.language === 'cn' ? _.size(displayName) > 0 ? displayName[1] : displayName[0] : displayName[0] }
                                </option>
                            )
                        })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="name" 
                            label={t('profile.name')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.name}
                            helperText={inputErrors.name?inputErrors.name:''}
                            error={inputErrors.name?true:false}
                            onChange={({ target }) => setState({ ...state, name: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="username" 
                            label={t('profile.username')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.username}
                            helperText={inputErrors.username?inputErrors.username:''}
                            error={inputErrors.username?true:false}
                            onChange={({ target }) => setState({ ...state, username: target.value }) }
                        />
                    </Grid>
                     <Grid item xs={12} md={6}>
                        <TextField 
                            id="email" 
                            label={t('profile.email')}
                            variant="outlined" 
                            fullWidth 
                            value={state.email}
                            helperText={inputErrors.email?inputErrors.email:''}
                            error={inputErrors.email?true:false}
                            onChange={({ target }) => setState({ ...state, email: target.value }) }
                        />
                    </Grid>
                    {/*
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="mobile" 
                            label={t('profile.mobile')} 
                            variant="outlined" 
                            fullWidth 
                            value={state.mobile}
                            helperText={inputErrors.mobile?inputErrors.mobile:''}
                            error={inputErrors.mobile?true:false}
                            onChange={({ target }) => setState({ ...state, mobile: target.value }) }
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="pwd" 
                            label={t('register.password')}
                            variant="outlined" 
                            fullWidth 
                            type="password"
                            value={state.pwd}
                            helperText={inputErrors.password?inputErrors.passwprd:''}
                            error={inputErrors.password?true:false}
                            onChange={({ target }) => setState({ ...state, pwd: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            id="cfm_pwd" 
                            label={t('register.passwordConfirmation')}
                            variant="outlined" 
                            fullWidth 
                            type="password"
                            value={state.cfm_pwd}
                            helperText={inputErrors.password_confirmation?inputErrors.password_confirmation:''}
                            error={inputErrors.password_confirmation?true:false}
                            onChange={({ target }) => setState({ ...state, cfm_pwd: target.value }) }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" onClick={form_submit} >{t('button.create')}</Button>
                    </Grid>
                </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));