import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, IconButton, FormHelperText, List, ListItem, ListItemSecondaryAction, RootRef } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { putUrl } from '@utils/ApiAction';
import DeleteIcon from '@material-ui/icons/Delete';

export default function LandingAdvBannerEdit({ id, advBanner, setAdvBanner, addAlert, setLoading }) {
    const { t } = useTranslation();
    const [inputErr, setInputErr] = useState([]);
    const [newImages, setNewImages] = useState([]);

    const classes = useStyles();
    const theme = useTheme();


    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const deleteBanner = (fileId) => {
        let currentArr = [...advBanner.banners];
        let deleteIndex = currentArr.findIndex(item => item.id === fileId);
        currentArr.splice(deleteIndex, 1);
        setAdvBanner({ ...advBanner, banners: currentArr });
    };


    const uploadBanner = async () => {

        let newImageBase64 = [];
        setLoading(true);
        setInputErr([]);

        if (_.size(newImages) > 0) {
            for (const newImg of newImages) {
                newImageBase64.push(await toBase64(newImg));
                console.log(newImg);

            }
        }

        putUrl(`admin/filebanners/${id}/banners`, { images: newImageBase64, ids: _.map(advBanner.banners, 'id') }).then(response => {
            setLoading(false);
            let { status, error, message, data } = response;
            if (status === 1) {
                addAlert('', message, 'success', '');
                setAdvBanner({ ...advBanner, banners: data });
            } else {
                addAlert('', message, 'error', '');
                if (error) {
                    setInputErr(error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');

        });
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            advBanner.banners,
            result.source.index,
            result.destination.index
        );

        setAdvBanner({ ...advBanner, banners: items });
    }


    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        ...(isDragging && { backgroundcolor: "rgb(235,235,235)" })
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '',
    });

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);

        return list;

    };



    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('banner.banners')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <RootRef rootRef={provided.innerRef}>
                                <List dense style={getListStyle(snapshot.isDraggingOver)}>
                                    {_.size(advBanner) ?
                                        _.map(advBanner.banners, (row, index) => {
                                            return ([
                                                <Draggable key={`listitem-${row.id}`} draggableId={`listitem-${row.id}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <ListItem
                                                            divider
                                                            ContainerComponent="li"
                                                            ContainerProps={{ ref: provided.innerRef }}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )} >
                                                            <img className={classes.bannerListImage} src={row.file_name} alt="advertisementbanner" />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" arial-label="delete" onClick={() => deleteBanner(row.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )}


                                                </Draggable>
                                            ]);
                                        })
                                        : null}
                                    {provided.placeholder}

                                </List>
                            </RootRef>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
            <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={t('banner.dragDropImageHere')}
                    maxFileSize={2000000}
                    onChange={(files) => setNewImages(files)}
                    style={{ outline: 'none' }}
                    dropzoneClass={classes.dropzone}
                />
                <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10 - _.size(advBanner.banners)}) + t('general.fileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                {inputErr ?
                    <FormHelperText style={{ color: "red" }}>{inputErr} </FormHelperText> : null
                }
            </Grid>
            <Grid item xs={12} md={12}>
                <Button variant="contained" size="medium" color="primary" onClick={uploadBanner}>{t('banner.save')}</Button>
            </Grid>

        </Grid>

    );

}


const useStyles = makeStyles((theme) => ({
    bannerListImage: {
        width: "200px",
        maxHeight: "120px"
    },
    dropzone: {
        outline: "none",
    }
}));