import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import MaterialTable from 'material-table';
import { getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, Link, Grid, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, TextField, Avatar, Divider, LinearProgress } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function ShopReviews() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions, role, isMerchant } = useSelector(state => state.general);

    const [tableData, setTableData] = useState([]);
    const [statusType, setStatusType] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const statusTypeToCode = { 'active': 1, 'inactive': 0 };
    const [updateDialog, setOpenUpdateDialog] = useState(false);
    const [modalData, setOpenModal] = useState({});
    const [inputErrors, setInputErrors] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    function callApi() {
        getUrl(`admin/shop_reviews`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
            }
        }).catch((error) => {
            setTableLoading(false);
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const alertChangeStatus = (event, data, type) => {
        setStatusType(type);
        setSelectedRow(data);
        setOpenUpdateDialog(true);
    };

    const updateStatus = () => {
        setLoading(true);

        let apiData = { status: statusTypeToCode[statusType], ids: _.map(selectedRow, 'id') };
        putUrl(`admin/shop_reviews/status`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];

                    _.map(selectedRow, (row, index) => {
                        let dataIndex = newTableData.findIndex(item => item.id === row.id);
                        if (statusType === "delete") {
                            newTableData.splice(dataIndex, 1);
                        } else {
                            newTableData[dataIndex] = { ...row, status: statusTypeToCode[statusType] };
                        }
                    })
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        setOpenUpdateDialog(false);
    };

    const saveReply = () => {
        setLoading(true);

        let apiData = {
            comment: modalData.comment,
            response: modalData.response
        };

        putUrl(`admin/shop_reviews/${modalData.id}`, apiData)
            .then((response) => {
                setLoading(false);
                let { status, message, error, data } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    let newTableData = [...tableData];
                    let dataIndex = newTableData.findIndex(item => item.id === modalData.id);
                    newTableData[dataIndex] = {...modalData, response_at: data.response_at, respondent: data.respondent};
                    setTableData(newTableData);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('review.shopReviews')}</Typography>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title=""
                    columns={[
                        { title: t('review.shopName'), field: 'merchant_company.shop_name', render: rowData => <Link underline='none' to={`/companies/${rowData.id}`} component={RouterLink}>{rowData.merchant_company.shop_name}</Link> },
                        { title: t('review.orderNo'), field: 'order_no', render: rowData => <Link underline='none' to={`/orders/${rowData.order_no}`} component={RouterLink}>{`#${rowData.order_no}`}</Link> },
                        { title: t('review.rating'), field: 'rating_display' },
                        { title: t('review.comment'), field: 'comment', render: rowData => <Typography classes={{ body2: classes.txtTruncate }} variant="body2" component="span">{rowData.comment}</Typography> },
                        { title: t('review.response'), field: 'response', render: rowData => <Typography classes={{ body2: classes.txtTruncate }} variant="body2" component="span">{rowData.response}</Typography> },
                        { title: t('table.status'), field: '', render: rowData => t('review.status.' + rowData.status) },
                        { title: t('review.commentDate'), field: 'created_at' },
                        { title: '', field: '', render: rowData => <IconButton onClick={() => setOpenModal(rowData)}><VisibilityIcon /></IconButton> },

                    ]}
                    data={tableData}
                    options={{
                        pageSize: 10,
                        selection: _.includes(permissions, "review_delete") ? true : false,
                    }}
                    actions={_.includes(permissions, "review_delete") ? [
                        {
                            tooltip: t('review.activateSelectedReview'),
                            icon: () => <RotateLeftIcon />,
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'active')
                        },
                        {
                            tooltip: t('review.inactiveSelectedReview'),
                            icon: 'block',
                            onClick: (evt, data) => alertChangeStatus(evt, data, 'inactive')
                        }
                    ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
                }
            </Grid>
            <Dialog
                open={updateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                aria-labelledby="update-status-dialog-title"
                aria-describedby="update-status-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">{t('review.confirmOnStatusUpdate')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`review.askUpdateStatus.${statusTypeToCode[statusType]}`, { 'number': selectedRow ? selectedRow.length : 0 })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={updateStatus} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={_.size(modalData) ? true : false}
                onClose={() => { setOpenModal({}); setInputErrors([]); }}
                aria-labelledby="review"
                aria-describedby="shop review modal"
            >
                <Grid container spacing={3} className={classes.modal}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} container>
                                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'center' }}>
                                    <Avatar style={{ 'margin': 'auto' }} className={classes.large} alt="icon" src={_.size(modalData) && _.size(modalData.merchant_company.merchant_company_icon) ? modalData.merchant_company.merchant_company_icon.file_name : ''} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={9}>
                                    <Typography variant="h6">{_.size(modalData) ? modalData.merchant_company.shop_name : ''}</Typography>
                                    <Grid container alignItems="center" justify="space-between">
                                        <Typography variant="body2" style={{ textAlign: 'center' }}>{t('review.positive')}<br />{_.size(modalData) ? modalData.merchant_company.shop_review_positive : ''}</Typography>
                                        <Divider orientation="vertical" flexItem />
                                        <Typography variant="body2" style={{ textAlign: 'center' }}>{t('review.neutral')}<br />{_.size(modalData) ? modalData.merchant_company.shop_review_neutral : ''}</Typography>
                                        <Divider orientation="vertical" flexItem />
                                        <Typography variant="body2" style={{ textAlign: 'center' }}>{t('review.negative')}<br />{_.size(modalData) ? modalData.merchant_company.shop_review_negative : ''}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">{t('review.rating')}: {_.size(modalData) ? modalData.rating_display : ''}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">{t('review.orderNo')}: {_.size(modalData) ? <Link style={{ color: "#929191" }} underline='none' to={`/orders/${modalData.order_no}`} component={RouterLink}>{`#${modalData.order_no}`}</Link> : ''}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">{t('review.commenter')}: {_.size(modalData) ? modalData.customer_name : ''}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">{t('review.commentDate')}: {_.size(modalData) ? modalData.created_at : ''}</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="comment"
                                    label={t('review.comment')}
                                    helperText={inputErrors.comment ? inputErrors.comment : ''}
                                    error={inputErrors.comment ? true : false}
                                    multiline
                                    variant="outlined"
                                    defaultValue={_.size(modalData) ? modalData.comment : ''}
                                    onChange={({ target }) => setOpenModal({ ...modalData, comment: target.value })}
                                    fullWidth
                                    InputProps={{
                                        readOnly: isMerchant ? true : false,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">{t('review.respondent')}: {_.size(modalData) && modalData.respondent ? modalData.respondent : '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">{t('review.responseDate')}: {_.size(modalData) && modalData.response_at ? modalData.response_at : '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="response"
                                    label={t('review.response')}
                                    helperText={inputErrors.response ? inputErrors.response : ''}
                                    error={inputErrors.response ? true : false}
                                    multiline
                                    variant="outlined"
                                    defaultValue={_.size(modalData) ? modalData.response : ''}
                                    onChange={({ target }) => setOpenModal({ ...modalData, response: target.value })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                <Grid item xs={6} sm={4} md={3}>
                                    <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => { setOpenModal({}); setInputErrors([]); }}>{t('button.close')}</Button>
                                </Grid>
                                { isMerchant && modalData.response !== "" ? null :
                                <Grid item xs={6} sm={4} md={3}>
                                    <Button type="button" fullWidth variant="contained" color="primary" onClick={saveReply}>{t('button.save')}</Button>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        height: '2.86em',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));