import {
    STORE_PROFILE,
    RESET_PROFILE
} from '@types';

const INITIAL_STATE = {
    id: null,
    name: '',
    email: '',
    mobile: '',
}

export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return { 
                ...state,
                id: actions.payload.id,
                name: actions.payload.name,
                email: actions.payload.email,
                mobile: actions.payload.mobile,
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}