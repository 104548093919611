import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, FormControl, FormHelperText, InputLabel, Select, MenuItem, Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField } from '@material-ui/core';

import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function RoleAdd() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        name: '',
        display_name: '',
        role_level: '',
        type: 'admins',
    });

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = state;

        postUrl(`admin/roles`, updateData).then(response => {
            console.log("response", response);
            setLoading(false);
            if(response.status) {
                if(response.data.id){
                    addAlert( `${t('snackbarMsg.addSuccess')} ID: ${response.data.id}`, '', 'success');
                }else{
                    addAlert( `${t('snackbarMsg.addSuccess')}`, '', 'success');
                }
            }else{
                if(response.data){
                    addAlert(t('snackbarMsg.addError'), '', 'error');
                }else{
                    addAlert(t('snackbarMsg.addError'), '', 'error');
                }
                if(response.error){
                    setInputErrors(response.error);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.addError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('role.addRole')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/roles`} component={RouterLink}>{t('role.roles')}</Link>
                        <Typography style={{ color: 'black' }}>{t('role.addRole')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                id="role_name" 
                                label={t('role.name')}
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                id="role_display_name" 
                                label={t('role.displayName')}
                                variant="outlined" 
                                fullWidth 
                                value={state.display_name}
                                helperText={inputErrors.display_name ? inputErrors.display_name : ''}
                                error={inputErrors.display_name ? true : false}
                                onChange={({ target }) => setState({ ...state, display_name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                id="role_level" 
                                label={t('role.level')}
                                variant="outlined" 
                                fullWidth 
                                value={state.role_level}
                                helperText={inputErrors.role_level ? inputErrors.role_level : ''}
                                error={inputErrors.role_level ? true : false}
                                onChange={({ target }) => setState({ ...state, role_level: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="role-type">{t('role.type')}</InputLabel>
                                <Select
                                    value={state.type}
                                    onChange={ ({target}) => setState({ ...state, type: target.value }) }
                                    // style={{ minWidth: 100, textAlign: 'center' }}
                                    label={t('role.type')}
                                    inputProps={{
                                        name: 'roleType',
                                        id: 'role-type',
                                    }}
                                >
                                    {
                                        _.map(['admins', 'merchants', 'members'], rolesType => {
                                            return <MenuItem key={rolesType} value={rolesType}>{ t(`role.types.${rolesType}`) }</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{ inputErrors.type ? inputErrors.type : '' }</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button variant="contained" onClick={form_submit} >{t('button.submit')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));