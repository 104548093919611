import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, LinearProgress, TextField, Checkbox, FormControl, FormLabel, FormGroup, Modal, Paper, Divider, FormControlLabel, FormHelperText, Avatar, Badge, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';

import MaterialTable from 'material-table';
import { Editor } from '@tinymce/tinymce-react';

export default function Announcements() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [tableData, setTableData] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedOptionIndex, setOptionIndex] = useState('');
    let initialState = {
        title: '',
        content: {en: '', cn: ''},
        popup: false,
        popup_sdate: "",
        popup_edate: "",
        display_setting: {member: true, admin: false},
        newImage: null
    };
    const [state, setState] = useState(initialState);
    const [inputErrors, setInputErrors] = useState([]);
    const [deleteId, setDeleteId] = useState("");

    function callApi(){
        getUrl(`admin/announcements`).then(response => {            
            if (response.status === 1 && isMountedRef.current) {
                setTableLoading(false);
                setTableData(response.data);
            }
        }).catch((error) => {            
            if (isMountedRef.current) {
                setTableLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setInputErrors([]);
        if(selectedOptionIndex && selectedOptionIndex !== "add"){
            getUrl(`admin/announcements/${selectedOptionIndex}`).then(response => {            
                if (response.status === 1 && isMountedRef.current) {
                    setState({...state, ...response.data});
                }
            }).catch((error) => {            
                if (isMountedRef.current) {
                    let msg = error + "\n" + t('error.contactSupport');
                    addAlert('', msg, 'error', '');
                }
            })

        }else if(!selectedOptionIndex){
            setState(initialState);
        }
    }, [selectedOptionIndex]);

    const saveOptions = () => {
        setInputErrors([]);
        setLoading(true);
        let apiData = {
            title: state.title,
            content: state.content,
            popup: state.popup ? 1:0,
            popup_sdate: state.popup_sdate,
            popup_edate: state.popup_edate,
            display_setting: state.display_setting,
            new_image: state.newImage
        };
        let apiMethod = postUrl;
        let apiUrl = `admin/announcements`;
        if(selectedOptionIndex !== "add"){
            apiMethod = putUrl;
            apiUrl = `admin/announcements/${selectedOptionIndex}`
        }

        apiMethod(apiUrl, apiData).then(response => {
            setLoading(false);
            let { status, message, error } = response;
            if (status === 1) {
                addAlert('', message, 'success', '');
                callApi();
                setOptionIndex('');
            } else {
                if (_.size(error) > 0) {
                    _.map(error, (value, key) => {
                        message += "\n " + value[0];
                    })
                    setInputErrors(error);
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const imageOnChange = async event => {
        if(event.target.files[0]){
            let newImageBase64 = await toBase64(event.target.files[0]);
            setState({...state, newImage: newImageBase64});
        }else{
            setState({...state, newImage: null});
        }
    };

    const deleteAnnouncement = () => {
        setLoading(true);
        deleteUrl(`admin/announcements/${deleteId}`)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                    callApi();
                }else{
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        setDeleteId("");
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {
                    tableLoading
                    ?
                    <LinearProgress />
                    :
                    <MaterialTable
                        title= { t('announcement.announcements') }
                        columns={[
                            { title: t('announcement.title'), field: 'title' },
                            // { title: t('announcement.content'), field: 'content', render: rowData => <Typography classes={{ body2: classes.txtTruncate }} variant="body2" component="span"><div dangerouslySetInnerHTML={{ __html:rowData['content'][`${i18n.language}`] }} /></Typography> },
                            { title: t('announcement.displayAt'), field: 'display_setting', render: rowData => <Typography variant="body2" component="span">{rowData['display_setting']['member'] ? "Member" : "" }{rowData['display_setting']['member'] && rowData['display_setting']['admin'] ? "|" : ""}{rowData['display_setting']['admin'] ? "Admin" : "" }</Typography>  },
                            { title: t('table.createdAt'), field: 'created_at' },
                        ]}
                        data={tableData}
                        options={{
                            pageSize: 10
                        }}
                        style={{ ...theme.box1, ...theme.p20 }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: t('announcement.editAnnouncement'),
                                onClick: (event, rowData) => { setOptionIndex(rowData.id); },
                                disabled: !_.includes(permissions, "announcement_edit"),
                                hidden: !_.includes(permissions, "announcement_edit")
                            },
                            {
                                icon: 'delete',
                                tooltip: t('announcement.deleteAnnouncement'),
                                onClick: (event, rowData) => { setDeleteId(rowData.id); },
                                disabled: !_.includes(permissions, "announcement_delete"),
                                hidden: !_.includes(permissions, "announcement_delete")
                            },
                            {
                                icon: 'add',
                                tooltip: t('announcement.addAnnouncement'),
                                isFreeAction: true,
                                onClick: (event) => setOptionIndex("add"),
                                disabled: !_.includes(permissions, "announcement_add"),
                                hidden: !_.includes(permissions, "announcement_add")
                            }
                        ]}
                        localization={{
                            pagination: {
                                labelDisplayedRows: t('table.labelDisplayedRows'),
                                labelRowsSelect: t('table.rows'),
                                firstTooltip: t('table.firstPage'),
                                previousTooltip: t('table.previousPage'),
                                nextTooltip: t('table.nextPage'),
                                lastTooltip: t('table.lastPage'),
                            },
                            toolbar: {
                                searchTooltip: t('table.search'),
                                searchPlaceholder: t('table.search'),
                                nRowsSelected: t('table.rowsSelected')
                            },
                            body: {
                                emptyDataSourceMessage: t('table.noRecord'),
                            },
                            header: {
                                actions: t('table.actions')
                            },
                        }}
                    />
                }
            </Grid>
            <Modal
                open={selectedOptionIndex !== '' ? true : false}
                onClose={() => {setOptionIndex("");} }
                aria-labelledby="announcement"
                aria-describedby="announcement modal"
                disableBackdropClick
            >
                <Grid container spacing={3} className={classes.modal}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{selectedOptionIndex === "add" ? t('announcement.addAnnouncement') : t('announcement.editAnnouncement')}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        badgeContent={<SmallAvatar alt="icon"><input accept="image/*" className={classes.inputFile} id="icon-button-file" type="file" onChange={imageOnChange} /><label htmlFor="icon-button-file"><IconButton component="span"><EditIcon style={{ fontSize: "small" }} /></IconButton></label></SmallAvatar>}
                                    >
                                        {/* <Avatar className={classes.large} alt="icon" src={_.size(companyMedia) && _.size(companyMedia.icon) ? companyMedia.icon.file_name : ''} /> */}
                                        {state.newImage ? 
                                            <Avatar className={classes.large} variant="square" alt="icon" src={state.newImage} />
                                            :
                                            (state.image && state.image.file_name ?
                                                <Avatar className={classes.large} variant="square" alt="icon" src={state.image.file_name} /> 
                                                :
                                                <Avatar className={classes.large} variant="square" alt="icon"><ImageIcon fontSize="large" /></Avatar>
                                            )
                                        }
                                    </Badge>
                                    <Typography variant='body2'>{t('general.recommendedSize', {H: '500', W: '500'})} + {t('general.maxFile', { max: "5MB" })}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="title"
                                        label={t('announcement.title')}
                                        variant="outlined"
                                        fullWidth
                                        value={(state && state.title ? state.title : "")}
                                        onChange={({ target }) => setState({ ...state, title: target.value })}
                                        helperText={inputErrors.title?inputErrors.title:''}
                                        error={inputErrors.title?true:false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" fullWidth size="small" error={inputErrors.content && inputErrors.content.en ? true : false}>
                                        <Typography variant="subtitle2" component="label">{t('announcement.content')}({t('announcement.english')})</Typography>
                                        <Editor
                                            apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                            value={state.content.en}
                                            init={{
                                                // height: "70vh",
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount quickbars'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                            }}
                                            onEditorChange={(content, editor) =>  setState({ ...state, content: {...state.content, en: content }})}
                                        />
                                        {inputErrors.content && inputErrors.content.en ?
                                            <FormHelperText>{inputErrors.content.en}</FormHelperText>
                                            : null
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" fullWidth size="small" error={inputErrors.content && inputErrors.content.cn ? true : false}>
                                        <Typography variant="subtitle2" component="label">{t('announcement.content')}({t('announcement.mandarin')})</Typography>
                                        <Editor
                                            apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                                            value={state.content.cn}
                                            init={{
                                                // height: "70vh",
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount quickbars'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                                            }}
                                            onEditorChange={(content, editor) =>  setState({ ...state, content: {...state.content, cn: content }})}
                                        />
                                        {inputErrors.content && inputErrors.content.cn ?
                                            <FormHelperText>{inputErrors.content.cn}</FormHelperText>
                                            : null
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl component="fieldset" error={inputErrors.display_setting ? true : false}>
                                        <FormLabel component="legend">{t('announcement.displayAt')}</FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox checked={state.display_setting.member ? true : false} onChange={({ target }) => setState({ ...state, display_setting: { ...state.display_setting, [target.name]: target.checked } })} name="member" />}
                                                label={t('announcement.memberSite')}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={state.display_setting.admin ? true : false} onChange={({ target }) => setState({ ...state, display_setting: { ...state.display_setting, [target.name]: target.checked } })} name="admin" />}
                                                label={t('announcement.adminSite')}
                                            />
                                        </FormGroup>
                                        {inputErrors.display_setting && inputErrors.display_setting ?
                                            <FormHelperText>{inputErrors.display_setting[0]}</FormHelperText>
                                            : null
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{t('announcement.popup')}</FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox checked={state.popup ? true : false} onChange={({ target }) => setState({ ...state, [target.name]: target.checked })} name="popup" />}
                                                label={t('announcement.yes')}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                { state.popup ?
                                    <><Grid item xs={12} md={6}>
                                        <TextField
                                            id="fdate"
                                            label={t('announcement.startDate')}
                                            variant="outlined"
                                            type="date"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={state.popup_sdate ? state.popup_sdate : ""}
                                            helperText={inputErrors.popup_sdate ? inputErrors.popup_sdate[0] : ''}
                                            error={inputErrors.popup_sdate ? true : false}
                                            onChange={({ target }) => setState({ ...state, popup_sdate: target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="tdate"
                                            label={t('announcement.endDate')}
                                            variant="outlined"
                                            type="date"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={state.popup_edate ? state.popup_edate : ""}
                                            helperText={inputErrors.popup_edate ? inputErrors.popup_edate[0] : ''}
                                            error={inputErrors.popup_edate ? true : false}
                                            onChange={({ target }) => setState({ ...state, popup_edate: target.value })}
                                        />
                                    </Grid></>
                                    : null 
                                }
                                
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => {setOptionIndex("");}}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={saveOptions}>{t('button.submit')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
            <Dialog
                open={deleteId !== "" ? true : false}
                onClose={() => setDeleteId("")}
                aria-labelledby="delete-announcement-dialog-title"
                aria-describedby="delete-announcement-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('announcement.confirmationOnDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('announcement.askDeleteAnnouncement')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteId("")} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={deleteAnnouncement} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    txtTruncate: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        // height: '2.86em'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);