import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, List, ListItem, ListItemText, makeStyles, Typography, IconButton } from '@material-ui/core'
import { ChevronRightOutlined, EditOutlined } from '@material-ui/icons';

export default memo(function CategoryPanel({ initialShow, selectedId, changeCategoryParent, initialData }) {
    const [expand, setExpand] = useState(false);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [selected, setSelected] = useState({
        1: null,
    });
    const [categoryList, setCategoryList] = useState([]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(_.size(categoryList) && selectedId > 0) {
            const selectedIdCategory = _.find(categoryList, { id: parseInt(selectedId) });
            if(_.size(selectedIdCategory)) {
                setCurrentLevel(selectedIdCategory.level + 1);

                let newSelected = selected;

                newSelected[selectedIdCategory.level] = parseInt(selectedId);
                if(_.size(selectedIdCategory.node)) {
                    const splitNode = _.split(selectedIdCategory.node, ",");
                    _.times((selectedIdCategory.level), levelItem => {
                        newSelected[parseInt(levelItem)] = parseInt(splitNode[`${parseInt(levelItem) - 1}`]);
                    })
                }
                setSelected(newSelected);
            }
        }
    }, [selectedId, categoryList]);

    useEffect(() => {
        setExpand(initialShow);
    }, [initialShow]);

    useEffect(() => {
        setCategoryList(initialData);
    }, [initialData]);

    const selectCategory = (level, id) => {

        let newSelected = {};

        if(_.hasIn(selected, level) && id === selected[level]) {
            setCurrentLevel(level === 1 ? 1 : level);

            let concatSelected = { ...selected, [level]: null };
            _.map(concatSelected, (selectedItem, loopedLevel) => {
                if(loopedLevel <= level) {
                    newSelected[loopedLevel] = selectedItem;
                }
            })
            const selectedLevelCategory = _.find(categoryList, { id });
            changeCategoryParent(selectedLevelCategory.parent_id);
    
        } else {
            setCurrentLevel(level + 1);

            let concatSelected = { ...selected, [level]: id };
            _.map(concatSelected, (selectedItem, loopedLevel) => {
                if(loopedLevel <= level) {
                    newSelected[loopedLevel] = selectedItem ? selectedItem : id;
                }
            })
            const selectedLevelCategory = _.find(categoryList, { id });
            changeCategoryParent(selectedLevelCategory.id);
    
        }
        setSelected(newSelected);
    }

    const toggleExpand = () => {
        setExpand(!expand);
    }

    return (
        <div>
            { expand && <div className={classes.panelContainerRoot}>
                <div className={classes.panelRoot}>
                { _.times(currentLevel, level => {
                    return <CategoryListing key={level} totalLevel={currentLevel} level={level + 1} data={categoryList} selected={selected} selectCategory={selectCategory} />
                } ) }
                </div>
            </div> }
            {/* current selection */}
            <div className={classes.selectedTextContainer}>
                <Typography variant="body2" style={{ paddingRight: 5 }}>{`${t('category.currentSelected')}: `}</Typography>
                { _.map(selected, (selectedItem, selectedItemIndex) => {
                    if(selectedItemIndex > 0) {
                        const currentItem = _.find(categoryList, { id: selectedItem });
                        let categoryName = "";
                        if(_.size(currentItem)) {
                            categoryName = currentItem[`name_${i18n.language}`];
                        }

                        return (
                            <Box key={selectedItemIndex} display="flex" flexDirection="row" paddingRight={1} alignItems="center">
                                { selectedItemIndex > 1 && <ChevronRightOutlined color="primary" /> }
                                <Typography variant="body2" color="primary">{ categoryName }</Typography>
                            </Box>
                        )
                    }
                    return null;
                })}
                <Box paddingX={1}>
                    <IconButton color="secondary" onClick={toggleExpand}>
                        <EditOutlined />
                    </IconButton>
                </Box>
            </div>
        </div>
    )
})

const CategoryListing = memo(({ totalLevel, level, data, selected, selectCategory }) => {
    let toLoopData = data;

    const { i18n } = useTranslation();
    const classes = useStyles();

    if(level > 1) {
        toLoopData = _.filter(data, { level, parent_id: selected[level - 1] });
    } else {
        toLoopData = _.filter(data, { level, status: 1 });
    }
    toLoopData = _.orderBy(toLoopData, ['id'], ['asc']);

    return (
        <Box display="flex" flexDirection="row" flex={ totalLevel <= 4 ? 'none' : 'auto' }>
            <List component="nav" classes={{ root: classes.listRoot }}>
                { _.map(toLoopData, item => {
                    return (
                        <ListItem selected={ !_.isUndefined(selected[level]) && selected[level] === item.id ? true : false} key={item.id} button onClick={() => selectCategory(level, item.id)}>
                            <ListItemText secondary={item[`name_${i18n.language}`]} />
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
});

const useStyles = makeStyles((theme) => ({
    selectedTextContainer: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    panelContainerRoot: {
        padding: '2rem',
        display: 'flex',
        backgroundColor: theme.palette.grey[100]
    },
    panelRoot: {
        maxHeight: '30rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        backgroundColor: '#FFF',
    },
    listRoot: { 
        minWidth: '15rem', 
        overflowY: 'auto' 
    }
}));