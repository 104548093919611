import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, putUrl,postUrl  } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, TextField, Button, Grid, Paper , MenuItem } from '@material-ui/core';

function useInput({ type, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} />
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

export default function Profile() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { role, isMerchant } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    let history =useHistory();

    // const [data, setData] = useState({});

    const [name, nameInput, setNameError, setNameValue] = useInput({ type: "text", label: `${t('profile.name')}`, val: "" });
    const [email, emailInput, setEmailError, setEmailValue] = useInput({ type: "text", label: `${t('profile.email')}`, val: "" });
    // const [mobile, mobileInput, setMobileError, setMobileValue] = useInput({ type: "text", label: `${t('profile.mobile')}`, val: "" });
    const [username, usernameInput, setUsernameError, setUsernameValue] = useInput({ type: "text", label: `${t('profile.username')}`, val: "" });
    const [old_password, oldPasswordInput, setOldPasswordError] = useInput({ type: "password", label: `${t('profile.currentPassword')}`, val: "" });
    const [password, passwordInput, setPasswordError] = useInput({ type: "password", label: `${t('profile.newPassword')}`, val: "" });
    const [password_confirmation, passwordConfirmationInput, setPasswordConfirmationError] = useInput({ type: "password", label: `${t('profile.confirmPassword')}`, val: "" });
    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    const validate = { name: setNameError, email: setEmailError, username: setUsernameError, 
        old_password: setOldPasswordError, password: setPasswordError, password_confirmation: setPasswordConfirmationError };

    const resetAllFieldError = () => {
        setNameError("");
        setEmailError("");
        // setMobileError("");
        setUsernameError("");
        setOldPasswordError("");
        setPasswordError("");
        setPasswordConfirmationError("");
        setUserMobile({ ...userMobile, error: {} });
    };
    
    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true)
        getUrl(`mobile-code-list`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setMobileCodeList({ ...mobileCodeList, country: response?.data?.country_list });
                }
            }
        }).catch(error => {
            // addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
    }, []);

    useEffect(() => {

        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList({ ...mobileCodeList, code: codeList });
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`user`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                // setData(result.data);
                setNameValue(result.data.name);
                setEmailValue(result.data.email || "");
                // setMobileValue(result.data.mobile || "");
                setUsernameValue(result.data.username || "");
                setUserMobile(prevState => ({ ...prevState, code: result.data.mobile_country_code , mobile: result.data.mobile }));
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [setNameValue, setEmailValue, setUsernameValue, setLoading, addAlert]);

    const updateProfile = () => {
        setLoading(true);
        resetAllFieldError();

        let apiData = {
            name,
            email,
            country: userMobile?.country,
            mobile_code: userMobile?.code,
            mobile: userMobile?.mobile,
            username,
        }
        putUrl(`admin/profile`, apiData)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message, error} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                }else{
                    addAlert('', message, 'error', '');
                    let err_arr = {};
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {

                            if (_.includes(['mobile', 'country', 'mobile_code'], key)) {
                                err_arr[key] = value[0];
                            } else {
                                if(validate[key]){
                                    validate[key](value[0]);
                                }
                            }
                            
                        })
                        setUserMobile({ ...userMobile, error: err_arr });
                    }
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    const updatePassword = () => {
        setLoading(true);
        resetAllFieldError();

        let apiData = {
            old_password,
            password,
            password_confirmation,
        }
        putUrl(`admin/profile/password`, apiData)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message, error} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                }else{
                    addAlert('', message, 'error', '');
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            if(validate[key])
                                validate[key](value[0]);
                        })
                    }
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    const chatWithAdmin =()=>{
        postUrl(`admin/merchantchats`).then(response=>{
            if(response.status){
                // console.log("response", response.data.id);
                history.push(`/merchanthelpdesk`);
            }
        }).catch(error=>{
            addAlert(JSON.stringify(error.message));
        })
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setUserMobile({ ...userMobile, [name]: value });
                break;
            default:
                // setUserMobile({ ...userMobile, [name]: value });
                break;
        }
    };

    return(
        <Grid container spacing={3}>            
            {/* <Grid item xs={12}>
                <Typography variant="h4" component="h1">{t('profile.account')}</Typography>
                <Divider />
            </Grid> */}
            <Grid item xs={9}>
                <Typography variant="h4" component="h1">{t('profile.account')}</Typography>
            </Grid>
            {isMerchant ?
                <Grid item xs={3}>
                    <Button type="button" variant="contained" color="primary" onClick={chatWithAdmin}>{t('button.merchantHelpdesk')}</Button>
                </Grid>
            : null }
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h1">{t('profile.profile')}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                {nameInput}
                                {usernameInput}
                                {emailInput}
                                {/* {mobileInput}*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        label={t('address.country')}
                                        value={userMobile.country}
                                        onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={userMobile?.error?.country ? true : false}
                                        disabled={_.size(mobileCodeList?.country) > 0 ? false : true}
                                        helperText={userMobile?.error?.country}
                                    >
                                        <MenuItem value="" disabled >{`${t('general.pleaseSelect')} ${t('profile.country')}`}</MenuItem>
                                        {
                                            _.size(mobileCodeList?.country) > 0 && _.map(mobileCodeList?.country, (option, key) => {
                                                return (
                                                    <MenuItem key={key} value={option.code} style={{ color: '#222' }}>
                                                        {i18n.language === 'cn' ? option.name_cn : option?.name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        label={t('profile.mobileCode')}
                                        value={userMobile.code}
                                        onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={userMobile?.error?.mobile_code ? true : false}
                                        disabled={_.size(mobileCodeList?.code) > 0 ? false : true}
                                        helperText={userMobile?.error?.mobile_code? userMobile?.error?.mobile_code : (userMobile?.code > 0 ? '':t('general.pleaseSelectCountry'))}
                                    >
                                        {
                                            _.size(mobileCodeList?.code) > 0 ?
                                                _.map(mobileCodeList?.code, (option, key) => {
                                                    return (
                                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                                            {option}
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem value="" style={{ color: '#222' }} >{``}</MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={t('profile.mobile')}
                                        value={userMobile.mobile}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="mobile"
                                        inputProps={{
                                            autoComplete: 'mobile',
                                            form: {
                                              autoComplete: 'off',
                                            },
                                        }}
                                        error={userMobile?.error?.mobile ? true : false}
                                        helperText={userMobile?.error?.mobile}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Button type="button" variant="contained" color="primary" onClick={updateProfile}>{t('button.update')}</Button>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10vh' }}>
                                <Typography variant="h5" component="h1">{t('profile.accountAndSecurity')}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                {oldPasswordInput}
                                {passwordInput}
                                {passwordConfirmationInput}
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="button" variant="contained" color="primary" onClick={updatePassword}>{t('button.update')}</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
}));