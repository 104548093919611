import React, { useState, useEffect, useRef } from 'react';
import { authSuccess, authFail } from '@actions';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

// import _ from 'lodash';
import { APP_NAME, CLIENT_SECRET, BACKEND_URL } from '@configs/Config';
import axios from 'axios';
import { useQuery } from '@utils/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Paper, FormControlLabel, Checkbox, Avatar } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ErrorIcon from '@material-ui/icons/Error';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function useMessage(initialValue){
    const [value, setValue] = useState(initialValue);
    return {
        value,
        setValue,
        reset: () => setValue(""),
    };
}

export default function LoginCatalog() {

    const { t } = useTranslation();
    const classes = useStyles();
    const query = useQuery();
    const isMountedRef = useRef(null);

    const [state, setState] = useState({
        username: '',
        password: '',
        redirectUrl: '',
        callbackUrl: '',
        sourceName: ''
    });
    const [loading, setLoading] = useState(false);

    const { value:errorGeneral, setValue:setErrorGeneral, reset:resetErrorGeneral } = useMessage('');

    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/dashboard" } };

    useEffect(() => {
        isMountedRef.current = true;

        let redirect_url = query.get("redirect_url") ? query.get("redirect_url") : '';
        let callback_url = query.get("callback_url") ? query.get("callback_url") : '';
        let source_name = query.get("source_name") ? query.get("source_name") : '';
        if(!redirect_url){
            history.replace('/login');
        }
        if(!callback_url || !source_name){
            window.location.href = redirect_url;
        }
        setState({ ...state, redirectUrl: redirect_url, callbackUrl: callback_url, sourceName: source_name });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    let loginUser = () => {
        setLoading(true);
        resetErrorGeneral();
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/api/auth/catalog`, {
            username: state.username,
            password: state.password,
            callback_url: state.callbackUrl,
        })
        .then((response) => {
            setLoading(false);
            if(response.status === 200) {
                let url = state.redirectUrl+'?store_code='+response.data.data.store_code;
                window.location.href = url;
            }
        }).catch((error) => {
            console.log(error.request);
            if (error.response && error.response.status === 400) {
                setErrorGeneral(t('error.invalidCredentials'));
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                setErrorGeneral(errorResponse.message);
            }else{
                setErrorGeneral(t('error.contactSupport'));
            }
            setLoading(false);
        });   
    };

    return (
        <Grid container component="main" className={classes.root}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item sm={4} md={7} style={{ textAlign: 'center', alignSelf: 'center', padding: '20px'}}>
                <Typography component="h1" variant="h5" style={{ marginBottom: '20px' }}>Sign in and Authorize Permission</Typography>
                <Typography variant="subtitle1">The {state.sourceName} is requesting the permission of operating the data of your shop. Signing in indicates you agree to the <a href="#">terms and conditions</a>.</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={ ev => { ev.preventDefault(); loginUser() }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={state.username}
                            onChange={({ target }) => setState({ ...state, username: target.value }) }
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            value={state.password}
                            onChange={({ target }) => setState({ ...state, password: target.value }) }
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        { errorGeneral && 
                            <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle"}}/>{errorGeneral}</Typography> 
                        }                        
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            // onClick={loginUser}
                        >
                            { t('button.login') }
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link underline='none' to={`/forgot-password`} component={RouterLink}>
                                    { t('title.forgotPassword') }
                                </Link>
                            </Grid>
                        </Grid> */}
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));