import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import { useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Divider, Checkbox, FormControlLabel } from '@material-ui/core';

export default function ProductOptionMultiPricing({ multiPricingList, multiPricingData, setMultiPricingData, wallets }) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const handleCheckboxChange = (event) => {
        let tempData = multiPricingData;
        if(event.target.checked){
            setMultiPricingData(tempData.concat(event.target.value));
        }else{
            setMultiPricingData(tempData.filter(item => item !== event.target.value));
        }
    };

    return (
        <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5" component="h1">{t('product.multiPricings')}</Typography>
                <Divider />
            </Grid>
            {
                _.size(multiPricingList) > 0
                ?
                _.map((multiPricingList), (row, rowIndex) => {
                    let fullLabel = '';
                    _.map(row.wallet_settings, (setting, key) => {
                        let label = setting.pricing_name + ' : ' + (setting.min_amount + (setting.type === 'percent' ? '%' : '') + (setting.min_amount === setting.max_amount ? '' : (' - ' + setting.max_amount + (setting.type === 'percent' ? '%' : ''))));
                        fullLabel = fullLabel ? (fullLabel + ' + [ ' + label + ' ]') : ('[ ' + label + ' ]');
                    })
                    // fullLabel = t('product.wType.'+row.type) + ': ' + fullLabel;
                    let checked = false;
                    _.map(multiPricingData, mp => {
                        if(parseInt(mp) === parseInt(row.id)){
                            checked = true;
                        }
                    })
                    return(
                        <Grid item xs={12} key={rowIndex} style={{padding: "0px 12px"}}>
                            <FormControlLabel
                                key={rowIndex}
                                label={fullLabel}
                                control={<Checkbox checked={checked} value={row.id} onChange={({ target }) => handleCheckboxChange({ target }) } />}
                            />
                        </Grid>
                    )
                })
                :
                null
            }
            </Grid>
    );
}