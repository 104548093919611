import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Link, Grid, Typography, LinearProgress, Toolbar, FormControl, Input, InputAdornment, Tooltip, IconButton, Icon } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import OrganizationChart from "@dabeng/react-orgchart";
import "@css/tree.css";

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('genealogy.searchUsername')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function GenealogyPlacement() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [data, setData] = useState({});
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [inputErrors, setInputErrors] = useState([]);
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: ''});

    function callApi() {
        setInputErrors([]);
        getUrl(`admin/genealogy/placement`, {username}).then(response => {
            let {status, message, error, data} = response;
            if (status === 1 && isMountedRef.current) {
                setData(data.tree_data);
                setTableLoading(false);
            }else if(error){
                setInputErrors(error);
                if(_.size(error) > 0){
                    _.map(error, (value, key) => {
                        message += "\n "+value[0];
                    })
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setTableLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
       callApi();

    }, [username]);

    const MyNode = ({ nodeData }) => {
        const selectNode = () => {
            setUsername(nodeData.username);
        };
        const selectUpperNode = () => {
            setUsername(nodeData.placement_username);
        };
        let rank = _.size(nodeData.user) && _.size(nodeData.user.max_rank) ? nodeData.user.max_rank.name : [];
        rank = _.split(rank, '|');
        rank = i18n.language === "cn" && rank[1] ? rank[1] : rank[0];

        return (
            <div>
                { nodeData.placement_username && nodeData.username === username && nodeData.user_id? 
                    <div onClick={selectUpperNode}>👆</div>
                    : null
                }
                { nodeData.username ?
                    // <div onClick={selectNode}>
                    //     <div className="position">{nodeData.position === 1 ? t('genealogy.left') : (nodeData.position === 2 ? t('genealogy.right'): "Top")}</div>
                    //     <div className="fullname">{nodeData.username} <br /> ID: {nodeData.user_id} <br /> {nodeData.referral_username? t('genealogy.referral')+": "+nodeData.referral_username:null}</div>
                    // </div>
                    <div onClick={selectNode}>
                        {/* <div className="position">{nodeData.position === 1 ? t('genealogy.left') : (nodeData.position === 2 ? t('genealogy.right') : "Top")}</div> */}
                        <div className="position">{nodeData.username}</div>
                        <div className="fullname">ID: {nodeData.user_id} <br /> {nodeData.referral_username? (t('genealogy.referral')+": "+nodeData.referral_username):null} {nodeData.referral_username ? <br/> : null} {`[${rank || t('genealogy.noRank')}]`} <br /> {new Date(nodeData.user.created_at).toLocaleDateString()}</div>
                        <div className="fullname">{t('genealogy.expiryDate')} <br /> {nodeData.user.expiry_date ? new Date(nodeData.user.expiry_date).toLocaleDateString() : "-"}</div>
                        <div className="groupsales">
                            <div className="">{t('genealogy.left')} <br /> {Math.trunc(nodeData.placement_group_sales1)}</div>
                            <div className="">{t('genealogy.right')} <br /> {Math.trunc(nodeData.placement_group_sales2)}</div>
                        </div>
                    </div>
                    :
                    <Link underline='none' to={{ pathname: "/members/add", search: `?placement=${nodeData.placement_username}&position=${nodeData.position}` }} component={RouterLink}>
                        <div className="position">{nodeData.position === 1 ? t('genealogy.left') : (nodeData.position === 2 ? t('genealogy.right'): "")}</div>
                        <div className="fullname" style={{padding:20}}>+</div>
                    </Link>
                }
            </div>
        );
    };

    function handleSubmit(event) {
        event.preventDefault();
        setUsername(searchValue);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('genealogy.placementGenealogy')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {searchToolbar}
            </Grid>
            <Grid item xs={12}>
                {
                    tableLoading
                        ?
                        <LinearProgress />
                        :
                         _.size(data) ? 
                        <OrganizationChart
                            datasource={data}
                            chartClass="myChart"
                            NodeTemplate={MyNode}
                            collapsible={false}
                            pan={false}
                        />
                        : null
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));