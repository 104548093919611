import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { putUrl, postUrl } from '@utils/ApiAction';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Editor } from '@tinymce/tinymce-react';
import { Typography, Button, Grid, TextField, Modal, FormControl ,FormGroup, FormControlLabel, Switch, FormHelperText , MenuItem} from '@material-ui/core';
import _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';

// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import DescriptionIcon from '@material-ui/icons/Description';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import PrintIcon from '@material-ui/icons/Print';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CompanyEditCompanyInfo({ id, companyOwner, state, setState, companyType, collaboratorList, logisticList, countryList, stateList, stateGroup, cityList, cityGroup, districtList, districtGroup, setLoading, addAlert, oldType , premiumStore, setPremiumStore , mobileCodeList }) {
    const { t, i18n } = useTranslation();
    const { role, permissions, isAdmin } = useSelector(state => state.general);
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);

    const [inputErrors, setInputErrors] = useState([]);
    // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    // const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [typeDialogOpen, setTypeDialogOpen] = useState(false);
    const [approved, setApproved] = useState(false);
    const [rejected, setRejected]= useState(false);
    const [attachments, setAttachments] = useState([]);
    const [reason, setReason]=useState('')
    const [reasonDialog, setReasonDialog]=useState(false)
    const handleReasonDialogOpen =() => {
        setReasonDialog(true)
    }
    const handleReasonDialogClose =() => {
        setReasonDialog(false)
    }
    // const [refundTncModel, setRefundTncModel] = useState({
    //     open: false,
    //     lang: '',
    //     title: '',
    //     value: ''
    // });

    useEffect(() => {
        isMountedRef.current = true;

        return () => { isMountedRef.current = false };
    }, []);

    const handleLogisticChange = (event, values) => {
        setState({ ...state, logisticPartner: values })
    }

    const checkCompanyTypeChange = () => {
        if(parseInt(oldType) === 1 && parseInt(state.type) === 0){
            setTypeDialogOpen(true);
        }else{
            form_submit();
        }
    }
    const handleTypeDialogClose = () => {
        setTypeDialogOpen(false);
    }

    // const setRefundTncOpen = (data) => {
    //     setRefundTncModel({
    //         open: true,
    //         lang: data.lang,
    //         title: data.lang==='cn'?t('seller.refundTncCn'):t('seller.refundTncEn'),
    //         value: data.value
    //     });
    // }
    // const setRefundTncClose = () => {
    //     if(refundTncModel.lang === 'cn'){
    //         setState({...state, refundTncCn: refundTncModel.value});
    //     }else{
    //         setState({...state, refundTncEn: refundTncModel.value});
    //     }
    //     setRefundTncModel({
    //         open: false,
    //         lang: '',
    //         title: '',
    //         value: ''
    //     });
    // }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const form_submit = async () => {
        setLoading(true);
        setInputErrors("");
        setTypeDialogOpen(false);

        let newAttachmentsBase64 = [];

        if(_.size(attachments) > 0){            
            for (const attachment of attachments) {
                newAttachmentsBase64.push(await toBase64(attachment));
            }
        }

        const updateData = {
            company_name: state.companyName,
            email: state.email,
            shop_name: state.shopName,
            slug: state.slug,
            mobile_code: state.mobileCode,
            mobile: state.mobile,
            register_no: state.register_no,
            type: state.type,
            pic: state.pic,
            address: state.address,
            address2: state.address2,
            city: state.city,
            state: state.state,
            zip: state.zip,
            country: state.country,
            log_partner: state.logisticPartner,
            about: state.about,
            bank_name: state.bankName,
            bank_holder_name: state.bankHolderName,
            bank_account: state.bankAccount,
            bank_swift: state.bankSwift,
            collaborator: state.collaborator,
            business_nature: state.business_nature,
            product_sell: state.product_sell,
            referral: state.referral,
            referral_commission: state.referral_commission,
            shop_commission: state.shop_commission,
            commencement_date: state.commencementDate,
            bv_threshold: state.bv_threshold,
            cv_threshold: state.cv_threshold,
            attachments: newAttachmentsBase64,
            // status: state.status?1:0,
            // refund_tnc_cn: state.refundTncCn,
            // refund_tnc_en: state.refundTncEn,
        }
        putUrl(`admin/companies/${id}`, updateData).then(response => {
            setLoading(false);
            if (response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
            } else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if (response.errors) {
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    const approvedSeller = () => {
        setLoading(true);
        postUrl(`admin/companies/${id}/approved`).then(response => {
            setLoading(false);
            if (response.status === 1) {
                setApproved(true);
                let msg = t('snackbarMsg.approvedSuccess');
                addAlert('', msg, 'success', '');
            } else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    let msg = t('snackbarMsg.approvedError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const rejectedSeller =()=>{
        setLoading(true);
        let apidata ={'reason':reason}
        postUrl(`admin/companies/${id}/rejected`,apidata).then(response =>{
            setLoading(false);
            if(response.status ===1){
                setRejected(true);
                handleReasonDialogClose();
                setInputErrors("");
                let msg = t('snackbarMsg.rejectedSuccess');
                addAlert('', msg, 'success', '');
            }else {
                if (response.data) {
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                } else {
                    setInputErrors(response.error);
                    let msg = t('snackbarMsg.rejectedError');
                    addAlert('', msg, 'error', '');
                }
            }

        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const printInfo = () => {
        var content = document.getElementById("ifmcontentstoprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const handleChangePremiumStore =()=>{
        setLoading(true);
        putUrl(`admin/companies/${id}/premium_store`,{status:!premiumStore}).then(response=>{
            setLoading(false);
            if(response.status ===1 && response.data ===true){
                setPremiumStore(response.data);
                let msg=t('snackbarMsg.premiumStore');
                addAlert('',msg,'success','');
            }else if(response.status ===1 && response.data ===false){
                setPremiumStore(response.data);
                let msg=t('snackbarMsg.notPremiumStore');
                addAlert('',msg,'error','');
            }else{
                addAlert('',response.message,'error','');
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'mobile':
            case 'zip':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, [name]: value });
                break;
            default:
                setState({ ...state, [name]: value });
        }
    };


    return (
        <Grid container spacing={3} direction="row">
            {/* {
                _.includes(permissions, "company_verify") && state.status === 0 && !approved ?
                <Grid item xs={12}>
                    <Button variant="contained" size="medium" color="primary" onClick={approvedSeller} >{t('button.approved')}</Button>
                </Grid>
                : null
            } */}
            {
                 _.includes(permissions,"company_premium_store") ? 
                <Grid item xs={6}>
                   <FormGroup row>
                       <FormControlLabel  control={<Switch checked={premiumStore? premiumStore:false} color="primary" onChange={handleChangePremiumStore}/>} label={t('seller.premiumStore.title')} color="primary" style={{color:premiumStore? theme.palette.primary.main : '#000000'}} />
                    </FormGroup> 
                </Grid>
                :null
            }
            { isAdmin ? 
                <Grid item xs={_.includes(permissions,"company_premium_store") ? 6:12}>
                    <IconButton aria-label="print" color="primary" onClick={printInfo} style={{ float: "right"}}>
                        <PrintIcon />
                    </IconButton>
                    <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position : "absolute", display: "none"}}>
                        <p>{t('seller.type')}: {companyType && companyType[state.type] ? companyType[state.type][i18n.language] : ""}</p>
                        <p>{t('seller.collaborator')}: {collaboratorList && collaboratorList[state.collaborator] ? collaboratorList[state.collaborator] : ""}</p>
                        <p>{t('seller.name')}: {state.companyName}</p>
                        <p>{t('seller.registerNo')}: {state.register_no}</p>
                        <p>{t('seller.shopName')}: {state.shopName}</p>
                        <p>{t('seller.slug')}: {state.slug}</p>
                        <p>{t('profile.email')}: {state.email}</p>
                        <p>{t('profile.mobile')}: {`${state.mobileCode} - ${state.mobile}`}</p>
                        <p>{t('seller.pic')}: {state.pic}</p>
                        <p>{t('seller.logisticPartner')}: {_.map(state.logisticPartner, 'desc').join(',')}</p>
                        <p>{t('seller.about')}: {state.about}</p>
                        <p>{t('seller.business_nature')}: {state.business_nature}</p>
                        <p>{t('seller.product_sell')}: {state.product_sell}</p>
                        <p>{t('seller.referral')}: {state.referral}</p>
                        <p>{t('seller.referral_commission')}: {state.referral_commission}</p>
                        <p>{t('seller.shop_commission')}: {state.shop_commission}</p>
                        <p>{t('seller.commencementDate')}: {state.commencementDate}</p>
                        <p>{t('seller.bv_threshold')}: {state.bv_threshold}</p>
                        <p>{t('seller.cv_threshold')}: {state.cv_threshold}</p>
                        <p>{t('address.country')}: { _.size(countryList)>0 ? _.find(countryList, { 'code': state.country })?.[(i18n.language === 'cn' ? 'name_cn' : 'name')] : ""}</p>
                        <p>{t('address.zip')}: {state.zip}</p>
                        <p>{t('address.state')}: {state.state && _.size(stateList)>0 && stateGroup.includes(state.country) && stateList.find(item => item.id == state.state) ? stateList.find(item => item.id == state.state)[(i18n.language === 'cn' ? 'name_cn' : 'name')] : state.state}</p>
                        <p>{t('address.city')}: {state.city && _.size(cityList)>0 && cityGroup.includes(state.state) && cityList.find(item => item.id == state.city) ? cityList.find(item => item.id == state.city)[(i18n.language === 'cn' ? 'name_cn' : 'name')] : state.city}</p>
                        <p>{t('address.address2')}: {state.address2 && _.size(districtList)>0 && districtGroup.includes(state.city) && districtList.find(item => item.id == state.address2) ? districtList.find(item => item.id == state.address2)[(i18n.language === 'cn' ? 'name_cn' : 'name')] : state.address2}</p>
                        <p>{t('address.address')}: {state.address}</p>
                        <p>{t('seller.bankName')}: {state.bankName}</p>
                        <p>{t('seller.bankHolderName')}: {state.bankHolderName}</p>
                        <p>{t('seller.bankAccount')}: {state.bankAccount}</p>
                        <p>{t('seller.bankSwift')}: {state.bankSwift}</p>
                    </iframe>
                </Grid>
                : null 
            }
            <Grid item xs={12} md={6}>
                <TextField
                    id="type"
                    label={t('seller.type')}
                    variant="outlined"
                    fullWidth
                    select
                    disabled={_.includes(permissions, "company_type") ? false : true}
                    value={state.type}
                    helperText={inputErrors.type ? inputErrors.type : ''}
                    error={inputErrors.type ? true : false}
                    onChange={({ target }) => setState({ ...state, type: target.value })}
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                >
                    {companyType.map((value, key) => (
                        <option key={key} value={key}>
                            {i18n.language === 'cn' ? value['cn'] : value['en']}
                        </option>
                    ))}
                </TextField>
            </Grid>
            {
            isAdmin &&
                <Grid item xs={12} md={6}>
                    <TextField
                        id="collaborator"
                        label={t('seller.collaborator')}
                        variant="outlined"
                        fullWidth
                        select
                        disabled={isAdmin ? false : true}
                        value={state.collaborator}
                        helperText={inputErrors.collaborator ? inputErrors.collaborator : ''}
                        error={inputErrors.collaborator ? true : false}
                        onChange={({ target }) => setState({ ...state, collaborator: target.value })}
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                    >
                        {Object.keys(collaboratorList).map((keyname, key) => (
                            <option key={key} value={keyname}>
                                {collaboratorList[keyname]}
                            </option>
                        ))}
                    </TextField>
                </Grid>
            }
            <Grid item xs={12} md={6}>
                <TextField
                    id="company_name"
                    label={t('seller.name')}
                    variant="outlined"
                    fullWidth
                    value={state.companyName}
                    helperText={inputErrors.company_name ? inputErrors.company_name : ''}
                    error={inputErrors.company_name ? true : false}
                    onChange={({ target }) => setState({ ...state, companyName: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="register_no"
                    label={t('seller.registerNo')}
                    variant="outlined"
                    fullWidth
                    value={state.register_no}
                    helperText={inputErrors.register_no ? inputErrors.register_no : ''}
                    error={inputErrors.register_no ? true : false}
                    onChange={({ target }) => setState({ ...state, register_no: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="shop_name"
                    label={t('seller.shopName')}
                    variant="outlined"
                    fullWidth
                    value={state.shopName}
                    helperText={inputErrors.shop_name ? inputErrors.shop_name : ''}
                    error={inputErrors.shop_name ? true : false}
                    onChange={({ target }) => {
                        const shop_name = target.value;
                        let slug = shop_name.split("|");
                        slug = slug[0].replace(/[^A-Za-z0-9| ]/g, '').replace(/ /g, '-').toLowerCase()
                        
                        setState({ ...state, shopName: shop_name, slug }) 
                    }}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="slug"
                    label={t('seller.slug')}
                    variant="outlined"
                    fullWidth
                    value={state.slug}
                    helperText={inputErrors.slug ? inputErrors.slug : t('seller.slug_helperText')}
                    error={inputErrors.slug ? true : false}
                    onChange={({ target }) => {
                        const shop_slug = target.value;
                        let slug = shop_slug.split("|");
                        slug = slug[0].replace(/[^A-Za-z0-9-]/g, '').replace(/ /g, '-').toLowerCase()
                        
                        setState({ ...state, slug }) 
                        // setState({ ...state, slug: target.value.replace(/ /g, '-') }).toLowerCase()
                    }}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="email"
                    label={t('profile.email')}
                    variant="outlined"
                    fullWidth
                    value={state.email}
                    helperText={inputErrors.email ? inputErrors.email : ''}
                    error={inputErrors.email ? true : false}
                    onChange={({ target }) => setState({ ...state, email: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="country"
                    label={t('address.country')}
                    variant="outlined"
                    fullWidth
                    disabled = {!companyOwner}
                    select
                    value={state.country}
                    helperText={inputErrors.country ? inputErrors.country : ''}
                    error={inputErrors.country ? true : false}
                    onChange={({ target }) => setState({ ...state, country: target.value, state: '', city: '', address2: '', logisticPartner: [] })}
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                >
                    <option key='' value='' disabled>
                        {t('address.pleaseSelect') + t('address.country')}
                    </option>
                    {countryList.map((option) => (
                        <option key={option.code} value={option.code}>
                            {i18n.language === 'cn' ? option.name_cn : option.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    select
                    label={t('profile.mobileCode')}
                    value={state.mobileCode}
                    onChange={({ target }) => setState({ ...state, mobileCode: target.value })}
                    variant="outlined"
                    fullWidth
                    error={inputErrors?.mobile_code ? true : false}
                    helperText={inputErrors?.mobile_code? inputErrors?.mobile_code : (state.mobileCode > 0 ? '':t('general.pleaseSelectCountry'))}
                    disabled={_.size(mobileCodeList) > 0 ? false:true}
                >
                    {
                        _.size(mobileCodeList) > 0 ?
                         _.map(mobileCodeList, (option, key) => {
                            return (
                                <MenuItem key={key} value={option}>
                                    {option}
                                </MenuItem>
                            )
                        }):
                        <MenuItem value="" disabled >{`${t('general.pleaseSelect')} ${t('profile.mobileCode')}`}</MenuItem>
                    }
                </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="mobile"
                    label={t('profile.mobile')}
                    variant="outlined"
                    fullWidth
                    value={state.mobile}
                    helperText={inputErrors.mobile ? inputErrors.mobile : ''}
                    error={inputErrors.mobile ? true : false}
                    name="mobile"
                    onChange={handleChange}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="pic"
                    label={t('seller.pic')}
                    variant="outlined"
                    fullWidth
                    value={state.pic}
                    helperText={inputErrors.pic ? inputErrors.pic : ''}
                    error={inputErrors.pic ? true : false}
                    onChange={({ target }) => setState({ ...state, pic: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Autocomplete
                    multiple
                    id="log_partner"
                    options={logisticList}
                    disableCloseOnSelect
                    filterSelectedOptions
                    onChange={handleLogisticChange}
                    disabled = {!companyOwner}
                    value={state.logisticPartner}
                    getOptionLabel={(option) => option.desc}
                    // renderOption={(option, { selected }) => (
                    //     <React.Fragment>
                    //         <Checkbox
                    //             icon={icon}
                    //             checkedIcon={checkedIcon}
                    //             style={{ marginRight: 8 }}
                    //             checked={selected}
                    //         />
                    //         {option.desc}
                    //     </React.Fragment>
                    // )}
                    renderInput={(params) => (
                        <TextField {...params}
                            variant="outlined"
                            label={t('seller.logisticPartner')}
                            fullWidth
                            helperText={(state.country?'':t('seller.selectCountryForLogistic')) + t('seller.logisticLeaveBlank')}
                        />
                    )}
                    // getOptionSelected={(option, { desc }) => {
                    //     return (option.desc === desc);
                    // }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="about"
                    label={t('seller.about')}
                    variant="outlined"
                    multiline
                    fullWidth
                    value={state.about}
                    helperText={inputErrors.about ? inputErrors.about : ''}
                    error={inputErrors.about ? true : false}
                    onChange={({ target }) => setState({ ...state, about: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="business_nature"
                    label={t('seller.business_nature') + ' *'}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={5}
                    value={state.business_nature}
                    helperText={inputErrors.business_nature ? inputErrors.business_nature : ''}
                    error={inputErrors.business_nature ? true : false}
                    onChange={({ target }) => setState({ ...state, business_nature: target.value })}
                    InputProps={{ readOnly: isAdmin ? false : true }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="product_sell"
                    label={t('seller.product_sell') + ' *'}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={5}
                    value={state.product_sell}
                    helperText={inputErrors.product_sell ? inputErrors.product_sell : ''}
                    error={inputErrors.product_sell ? true : false}
                    onChange={({ target }) => setState({ ...state, product_sell: target.value })}
                    InputProps={{ readOnly: isAdmin ? false : true }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="referral"
                    label={t('seller.referral')}
                    variant="outlined"
                    fullWidth
                    value={state.referral}
                    helperText={inputErrors.referral ? inputErrors.referral : ''}
                    error={inputErrors.referral ? true : false}
                    onChange={({ target }) => setState({ ...state, referral: target.value })}
                    InputProps={{ readOnly: isAdmin ? false : true }}
                />
            </Grid>
            { isAdmin ? 
            <Grid item xs={12} md={6}>
                <TextField
                    id="referral_commission"
                    label={t('seller.referral_commission')}
                    variant="outlined"
                    fullWidth
                    value={state.referral_commission}
                    helperText={inputErrors.referral_commission ? inputErrors.referral_commission : ''}
                    error={inputErrors.referral_commission ? true : false}
                    onChange={({ target }) => setState({ ...state, referral_commission: target.value })}
                />
            </Grid>
            : null }
            { isAdmin ? 
            <Grid item xs={12} md={6}>
                <TextField
                    id="shop_commission"
                    label={t('seller.shop_commission')}
                    variant="outlined"
                    fullWidth
                    value={state.shop_commission}
                    helperText={inputErrors.shop_commission ? inputErrors.shop_commission : ''}
                    error={inputErrors.shop_commission ? true : false}
                    onChange={({ target }) => setState({ ...state, shop_commission: target.value })}
                />
            </Grid>
            : null }
            <Grid item xs={12} md={6}>
                <TextField
                    id="commencementDate"
                    label={t('seller.commencementDate')}
                    variant="outlined"
                    fullWidth
                    type="date"
                    value={state.commencementDate}
                    helperText={inputErrors.commencement_date ? inputErrors.commencement_date : ''}
                    error={inputErrors.commencement_date ? true : false}
                    onChange={({ target }) => setState({ ...state, commencementDate: target.value })}
                    InputProps={{ readOnly: isAdmin ? false : true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            { state.deduct_bv ? 
            <Grid item xs={12} md={6}>
                <TextField
                    id="bv_threshold"
                    label={t('seller.bv_threshold')}
                    variant="outlined"
                    fullWidth
                    value={state.bv_threshold}
                    helperText={inputErrors.bv_threshold ? inputErrors.bv_threshold : ''}
                    error={inputErrors.bv_threshold ? true : false}
                    onChange={({ target }) => setState({ ...state, bv_threshold: target.value })}
                />
            </Grid>
            : null }
            { state.deduct_cv ? 
            <Grid item xs={12} md={6}>
                <TextField
                    id="cv_threshold"
                    label={t('seller.cv_threshold')}
                    variant="outlined"
                    fullWidth
                    value={state.cv_threshold}
                    helperText={inputErrors.cv_threshold ? inputErrors.cv_threshold : ''}
                    error={inputErrors.cv_threshold ? true : false}
                    onChange={({ target }) => setState({ ...state, cv_threshold: target.value })}
                />
            </Grid>
            : null }
            {/* <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setRefundTncOpen({lang: 'en', value: state.refundTncEn})}>{t('seller.refundTncEn')}</Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setRefundTncOpen({lang: 'cn', value: state.refundTncCn})}>{t('seller.refundTncCn')}</Button>
                </Grid>
            </Grid> */}
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('seller.addressDetails')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="country"
                    label={t('address.country')}
                    variant="outlined"
                    fullWidth
                    disabled = {!companyOwner}
                    select
                    value={state.country}
                    helperText={inputErrors.country ? inputErrors.country : ''}
                    error={inputErrors.country ? true : false}
                    onChange={({ target }) => setState({ ...state, country: target.value, state: '', city: '', address2: '', logisticPartner: [] })}
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                >
                    <option key='' value='' disabled>
                        {t('address.pleaseSelect') + t('address.country')}
                    </option>
                    {countryList.map((option) => (
                        <option key={option.code} value={option.code}>
                            {i18n.language === 'cn' ? option.name_cn : option.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="zip"
                    label={t('address.zip')}
                    variant="outlined"
                    fullWidth
                    value={state.zip}
                    helperText={inputErrors.zip ? inputErrors.zip : ''}
                    error={inputErrors.zip ? true : false}
                    name="zip"
                    onChange={handleChange}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6} >
                {
                    stateGroup.includes(state.country)
                        ?
                        <TextField
                            id="state"
                            label={t('address.state')}
                            variant="outlined"
                            fullWidth
                            disabled = {!companyOwner}
                            select
                            value={state.state}
                            helperText={inputErrors.state ? inputErrors.state : ''}
                            error={inputErrors.state ? true : false}
                            onChange={({ target }) => setState({ ...state, state: target.value, city: '', address2: '' })}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                            <option key='' value='' disabled>
                                {t('address.pleaseSelect') + t('address.state')}
                            </option>
                            {stateList.map((option) => (
                                (option.country_code === state.country) ?
                                    <option key={option.id} value={option.id}>
                                        {i18n.language === 'cn' ? option.name_cn : option.name}
                                    </option>
                                    : null
                            ))}
                        </TextField>
                        :
                        <TextField
                            id="state_text"
                            label={t('address.state')}
                            variant="outlined"
                            fullWidth
                            value={state.state}
                            helperText={inputErrors.state ? inputErrors.state : ''}
                            error={inputErrors.state ? true : false}
                            onChange={({ target }) => setState({ ...state, state: target.value })}
                            InputProps={{ readOnly: !companyOwner }}
                        />
                }
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    cityGroup.includes(state.state)
                        ?
                        <TextField
                            id="city"
                            label={t('address.city')}
                            variant="outlined"
                            fullWidth
                            disabled = {!companyOwner}
                            select
                            value={state.city}
                            helperText={inputErrors.city ? inputErrors.city : ''}
                            error={inputErrors.city ? true : false}
                            onChange={({ target }) => setState({ ...state, city: target.value, address2: '' })}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                            <option key='' value='' disabled>
                                {t('address.pleaseSelect') + t('address.city')}
                            </option>
                            {cityList.map((option) => (
                                (option.state_id === state.state) ?
                                    <option key={option.id} value={option.id}>
                                        {i18n.language === 'cn' ? option.name_cn : option.name}
                                    </option>
                                    : null
                            ))}
                        </TextField>
                        :
                        <TextField
                            id="city_text"
                            label={t('address.city')}
                            variant="outlined"
                            fullWidth
                            value={state.city}
                            helperText={inputErrors.city ? inputErrors.city : ''}
                            error={inputErrors.city ? true : false}
                            onChange={({ target }) => setState({ ...state, city: target.value })}
                            InputProps={{ readOnly: !companyOwner }}
                        />
                }
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    districtGroup.includes(state.city)
                        ?
                        <TextField
                            id="address2"
                            label={t('address.address2')}
                            variant="outlined"
                            fullWidth
                            disabled = {!companyOwner}
                            select
                            value={state.address2}
                            helperText={inputErrors.address2 ? inputErrors.address2 : ''}
                            error={inputErrors.address2 ? true : false}
                            onChange={({ target }) => setState({ ...state, address2: target.value })}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                        >
                            <option key='' value='' disabled>
                                {t('address.pleaseSelect') + t('address.address2')}
                            </option>
                            {districtList.map((option) => (
                                (option.city_id === state.city) ?
                                    <option key={option.id} value={option.id}>
                                        {i18n.language === 'cn' ? option.name_cn : option.name}
                                    </option>
                                    : null
                            ))}
                        </TextField>
                        :
                        <TextField
                            id="address2_text"
                            label={t('address.address2')}
                            variant="outlined"
                            fullWidth
                            value={state.address2}
                            helperText={inputErrors.address2 ? inputErrors.address2 : ''}
                            error={inputErrors.address2 ? true : false}
                            onChange={({ target }) => setState({ ...state, address2: target.value })}
                            InputProps={{ readOnly: !companyOwner }}
                        />
                }
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="address"
                    label={t('address.address')}
                    variant="outlined"
                    fullWidth
                    value={state.address}
                    helperText={inputErrors.address ? inputErrors.address : ''}
                    error={inputErrors.address ? true : false}
                    onChange={({ target }) => setState({ ...state, address: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('seller.bankDetails')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="bank_name"
                    label={t('seller.bankName')}
                    variant="outlined"
                    fullWidth
                    value={state.bankName}
                    helperText={inputErrors.bank_name ? inputErrors.bank_name : ''}
                    error={inputErrors.bank_name ? true : false}
                    onChange={({ target }) => setState({ ...state, bankName: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="bank_holder_name"
                    label={t('seller.bankHolderName')}
                    variant="outlined"
                    fullWidth
                    value={state.bankHolderName}
                    helperText={inputErrors.bank_holder_name ? inputErrors.bank_holder_name : ''}
                    error={inputErrors.bank_holder_name ? true : false}
                    onChange={({ target }) => setState({ ...state, bankHolderName: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="bank_account"
                    label={t('seller.bankAccount')}
                    variant="outlined"
                    fullWidth
                    value={state.bankAccount}
                    helperText={inputErrors.bank_account ? inputErrors.bank_account : ''}
                    error={inputErrors.bank_account ? true : false}
                    onChange={({ target }) => setState({ ...state, bankAccount: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    id="bank_swift"
                    label={t('seller.bankSwift')}
                    variant="outlined"
                    fullWidth
                    value={state.bankSwift}
                    helperText={inputErrors.bank_swift ? inputErrors.bank_swift : ''}
                    error={inputErrors.bank_swift ? true : false}
                    onChange={({ target }) => setState({ ...state, bankSwift: target.value })}
                    InputProps={{ readOnly: !companyOwner }}
                />
            </Grid>
            { isAdmin ? 
                <>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={theme.textline} component="h1">{t('register.attachments')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            _.size(state.register_attachments) ? 
                            _.map(state.register_attachments, (row, index)=> {
                                return(
                                    <Button style={{marginRight: 15}} key={index} variant="outlined" underline='none' href={row.file_name} target='_blank' rel="noreferrer" endIcon={ row.mime === "pdf" ? <PictureAsPdfIcon /> : <ImageIcon /> }>{t('seller.attachment')} {index+1}</Button>
                                );
                            })
                            : 
                            <Typography variant="body1" style={theme.textline}>-</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DropzoneArea
                            acceptedFiles={['image/*', '.pdf']}
                            dropzoneText={t('register.attachments')}
                            onChange={(files) => isMountedRef.current ? setAttachments(files) : null}
                            filesLimit={12}
                            dropzoneClass={classes.dropzone}
                            maxFileSize={2000000}
                        />
                        <Typography variant="body2" style={{ margin: 5 }}>{t('general.maxFile', { max: "∞" }) + t('general.fileSizeLimit', { limit: '2MB', fileType: 'jpeg. jpg. png. pdf. ' })}</Typography>
                        {inputErrors.attachments ?
                            <FormHelperText style={{ color: "red" }}>{inputErrors.attachments}</FormHelperText>
                            : null
                        }
                    </Grid>
                </>
                : null 
            }
            {/* {
                companyOwner ?
                <Grid item xs={12} md={6}>
                    <Button variant="contained" size="medium" color="primary" onClick={checkCompanyTypeChange} >{t('button.save')}</Button>
                </Grid>
                : null
            } */}
             <Grid container item spacing={1} xs={12} md={12}>
                {
                    // companyOwner ?
                    //     <Button variant="contained" size="medium" color="primary" onClick={checkCompanyTypeChange} style={{ marginRight: 10 }}>{t('button.save')}</Button>
                    //     : null
                }
                {
                    _.includes(permissions, "company_verify") && state.status === 0 ?
                        <>
                            {
                                !approved && !rejected ?
                                    <> <Button variant="contained" size="medium" color="primary" onClick={approvedSeller} style={{ marginRight: 10 }}>{t('button.approved')}</Button>
                                        <Button variant="contained" size="medium" color="primary" onClick={handleReasonDialogOpen} style={{ marginRight: 10 }}>{t('button.rejected')}</Button>
                                    </>
                                    :
                                    null
                            }
                        </>
                        :
                        null
                }   
            </Grid>
            <Dialog onClose={handleTypeDialogClose} open={typeDialogOpen} fullWidth>
                <DialogTitle onClose={handleTypeDialogClose}>
                    {t('seller.downgrade')}
                </DialogTitle>
                <DialogContent dividers>
                    {t('seller.downgradeReminder')}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={form_submit} color="primary">
                        {t('button.yesPlease')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={handleReasonDialogClose} open={reasonDialog} fullWidth>
                <DialogTitle onClose={handleReasonDialogClose}>
                    {t('seller.reject')}
                </DialogTitle>
                <DialogContent dividers>
                    {t('seller.rejectReminder')}
                    <Grid item xs={12} md={12}>
                        <TextField
                            id="bank_account"
                            label={t('seller.rejectReason')}
                            fullWidth
                            value={reason}
                            helperText={inputErrors.reason ? inputErrors.reason : ''}
                            error={inputErrors.reason ? true : false}
                            onChange={({ target }) => setReason(target.value)}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={rejectedSeller} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Modal
                open={refundTncModel.open ? true : false}
                onClose={() => setRefundTncClose()}
                aria-labelledby="refund tnc"
                aria-describedby="refund tnc modal"
            >
                <Grid container spacing={3} justify="center" className={classes.modal}>
                <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <Typography variant="subtitle2" component="label">{refundTncModel.title}</Typography>
                        <Editor
                            apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                            initialValue={refundTncModel.value}
                            init={{
                                height: "70vh",
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount quickbars'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                            }}
                            onEditorChange={(content, editor) => setRefundTncModel({...refundTncModel, value: content})}
                        />
                    </FormControl>
                </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button type="button" fullWidth variant="contained" color="primary" onClick={() => setRefundTncClose()}>{t('button.close')}</Button>
                    </Grid>
                </Grid>
            </Modal> */}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    dropzone: {
        outline: "none",
    },
}));