import { useLocation } from "react-router-dom";
import { useState } from 'react';

export const generateRandomString = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characterLength = characters.length;

    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characterLength));
    }
    return result;
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useMessage = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    return {
        value,
        setValue,
        reset: () => setValue(""),
    };
}

function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

export const daysBetween = (startDate, endDate) => {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

export const currencyFormat = amount => {
    if (typeof amount === 'string') {
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else if(typeof amount === 'decimal' || typeof amount === 'number') {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return amount;
}

export const formatDate =date =>{
    date = date.substring(0,10);
    let theDate = new Date(date);
    let offset = theDate.getTimezoneOffset();
    theDate = new Date(theDate.getTime() - (offset*60*1000));
    return theDate.toISOString().substring(0,10);
}