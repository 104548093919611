import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '@utils/ApiAction';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, TextField, MenuItem, Button, Grid, Paper, IconButton, Modal } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

function useInput({ type, label, val, helpText }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error ? error : helpText} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} />
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

function useSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useMultiSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12}>
            <Autocomplete
                multiple
                id="select-wallet"
                size="small"
                disableCloseOnSelect
                options={data}
                getOptionLabel={(option) => option.wallet_name}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} />
                )}
            />
            {
                error
                    ?
                        _.map(error, err => {
                            return(
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {err}
                                </Typography>
                            )
                        })
                    : null
            }
        </Grid>
    return [value, select, setErrorValue, setValue];
}

export default function ProductEditMultiPricings({ data, setLoading, addAlert, setData }) {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState([]);

    const [wallets, setWallets] = useState([]);
    const [deleteOptionIndex, setOpenDeleteOptionDialog] = useState("");
    const [selectedOptionIndex, setOptionIndex] = useState('');
    const [walletSettingDialog, setWalletSettingDialog] = useState({
        open: false,
        index: ''
    });
    
    // const walletType = data.type === 'rank' ? [{code: "starter", label: t('product.wType.starter')}, {code: "repurchase", label: t('product.wType.repurchase')}] : [{code: "normal", label: t('product.wType.normal')}];
    const walletType = [{code: data.type, label: t('product.wType.'+data.type)}];
    const amountType = [{code: "fix", label: t('product.aType.fix')}, {code: "percent", label: t('product.aType.percent')}];
    
    const [type, typeInput, setTypeError, setTypeValue] = useSelect({ data: walletType, label: t('product.wType.title'), val: data.type});
    const [wallet_settings, setWalletSettings] = useState([]);
    const [wallet_ids, walletIdsInput, setWalletIdsError, setWalletIdsValue] = useMultiSelect({ data: wallets, label: t('product.walletIds'), val: [] });
    const [pricing_name, pricingNameInput, setPricingNameError, setPricingNameValue] = useInput({ type: "text", label: t('product.pricingName'), val: '' });
    const [amount_type, amountTypeInput, setAmountTypeError, setAmountTypeValue] = useSelect({ data: amountType, label: t('product.aType.title'), val: 'fix' });
    const [min_amount, minAmountInput, setMinAmountError, setMinAmountValue] = useInput({ type: "number", label: t('product.minAmount'), val: '' });
    const [max_amount, maxAmountInput, setMaxAmountError, setMaxAmountValue] = useInput({ type: "number", label: t('product.maxAmount'), val: '' });

    const validate = {
        type: setTypeError, pricing_name: setPricingNameError, amount_type: setAmountTypeError, min_amount: setMinAmountError, max_amount: setMaxAmountError, wallet_ids: setWalletIdsError
    };

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        });
        setInputErrors("");
    };

    useEffect(() => {
        setLoading(true);
        getUrl('admin/wallets').then((response) => {
            setLoading(false);
            if (response.status === 1) {
                let wallets = response.data;
                setWallets(wallets);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
    }, []);

    useEffect(() => {
        resetAllFieldError();
        if (selectedOptionIndex !== "") {
            if (selectedOptionIndex === "new") {
                // setTypeValue(data.type == 'rank' ? 'starter' : 'normal');
                setTypeValue(data.type);
                setWalletSettings([]);
            } else {
                let walletSettings = [];
                _.map(data.multi_pricings[selectedOptionIndex].wallet_settings, (setting, key) => {
                    let _walletIds = setting.wallet_ids ? setting.wallet_ids : [];
                    let _selectedWallet = [];
                    if(_.size(_walletIds) > 0){
                        _.map(_walletIds, (wid) => {
                            _selectedWallet.push(_.find(wallets, function(o) { return o.id === wid; }));
                        })
                    }
                    walletSettings.push({wallet_ids: _selectedWallet, pricing_name: setting.pricing_name, type: setting.type, min_amount: setting.min_amount, max_amount: setting.max_amount});
                })
                setTypeValue(data.multi_pricings[selectedOptionIndex].type || "");
                setWalletSettings(walletSettings || "");
            }
        }
    }, [selectedOptionIndex, data.multi_pricings, setTypeValue, setWalletSettings]);

    const handleWalletSettingDialogOpen = (index) => {
        if(index !== 'new'){
            let setting = _.find(wallet_settings, function(data, key) { return key === index; });
            setWalletIdsValue(setting.wallet_ids);
            setPricingNameValue(setting.pricing_name);
            setAmountTypeValue(setting.type);
            setMinAmountValue(setting.min_amount);
            setMaxAmountValue(setting.max_amount);
        }else{
            setWalletIdsValue([]);
            setPricingNameValue('');
            setAmountTypeValue('');
            setMinAmountValue('');
            setMaxAmountValue('');
        }
        setWalletSettingDialog({
            open: true,
            index: index
        });
    }
    const handleWalletSettingDialogClose = () => {
        setWalletSettingDialog({
            open: false,
            index: ''
        });
    }
    const handleWalletSettingDialogSave = () => {
        let tempWalletSettings = wallet_settings;
        if(walletSettingDialog.index !== 'new'){
            // edit
            tempWalletSettings[walletSettingDialog.index] = {wallet_ids: wallet_ids, pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: max_amount};
        }else{
            // add
            tempWalletSettings.push({wallet_ids: wallet_ids, pricing_name: pricing_name, type: amount_type, min_amount: min_amount, max_amount: max_amount});
        }
        setWalletSettings(tempWalletSettings);
        handleWalletSettingDialogClose();
    }
    const handleWalletSettingDialogDelete = (index) => {
        let tempWalletSettings = wallet_settings;
        tempWalletSettings = tempWalletSettings.filter((item, key) => parseInt(key) !== parseInt(index));
        setWalletSettings(tempWalletSettings);
    }

    const saveOptions = () => {
        setLoading(true);
        resetAllFieldError();

        let wSettings = [];
        _.map(wallet_settings, (setting) => {
            let wallet_ids = [];
            _.map(setting.wallet_ids, (wallet) => {
                wallet_ids.push(wallet.id)
            })
            wSettings.push({wallet_ids: wallet_ids, pricing_name: setting.pricing_name, type: setting.type, min_amount: setting.min_amount, max_amount: setting.max_amount});
        })
        let apiData = {
            type,
            wallet_settings: wSettings
        }

        if (selectedOptionIndex !== "" && selectedOptionIndex !== "new") {
            // update multi pricing
            putUrl(`admin/products/${data.id}/multi_pricing/${data.multi_pricings[selectedOptionIndex].id}`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        setData(newData);
                        setOptionIndex("");
                    } else {
                        if (_.size(error) > 0) {
                            setInputErrors(error);
                            _.map(error, (value, key) => {
                                if (validate[key])
                                    validate[key](value[0]);
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
        } else {
            // add new multi pricing
            postUrl(`admin/products/${data.id}/multi_pricing`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        setData(newData);
                        setOptionIndex("");
                    } else {
                        if (_.size(error) > 0) {
                            setInputErrors(error);
                            _.map(error, (value, key) => {
                                if (validate[key])
                                    validate[key](value[0]);
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
        }
    };
    const deleteOption = () => {
        setLoading(true);
        let currentMultiPricings = [...data.multi_pricings];
        currentMultiPricings.splice(deleteOptionIndex, 1);
        deleteUrl(`admin/products/${data.id}/multi_pricing/${data.multi_pricings[deleteOptionIndex].id}`)
            .then((response) => {
                setLoading(false);
                let { status, message, data: newData } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    setData(newData);
                    // setData({ ...data, 'multi_pricings': [...currentMultiPricings] });
                } else {
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
        setOpenDeleteOptionDialog("");
    };

    return (
        <Grid container spacing={3} style={theme.p20}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h5" style={theme.textline} component="h1">{t('product.multiPricings')}</Typography>
                    <Button size="small" startIcon={<AddIcon />} onClick={() => setOptionIndex("new")}>{t('button.new')}</Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3} className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="table"
                    >
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>{t('product.wType.title')}</TableCell> */}
                                <TableCell>{t('product.walletSettings')}</TableCell>
                                <TableCell>{t('product.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.size(data.multi_pricings) > 0 ? _.map(data.multi_pricings, (row, rowIndex) => {
                                return (
                                    <TableRow key={rowIndex}>
                                        {/* <TableCell>{t('product.wType.' + row.type)}</TableCell> */}
                                        <TableCell>
                                            {
                                                _.size(row.wallet_settings) > 0
                                                    ?
                                                    _.map(row.wallet_settings, (setting, key) => {
                                                        return (
                                                            <Typography key={key}>
                                                                {setting.pricing_name + ' : ' + (setting.min_amount + (setting.type === 'percent' ? '%' : '') + (setting.min_amount === setting.max_amount ? '' : (' - ' + setting.max_amount + (setting.type === 'percent' ? '%' : ''))))}
                                                            </Typography>
                                                        );
                                                    })
                                                    :
                                                    null
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Grid container alignItems="center" className={classes.actionRoot}>
                                                <IconButton aria-label="edit" size="small" color="primary" onClick={() => setOptionIndex(rowIndex)}><EditIcon /></IconButton>
                                                <Divider orientation="vertical" flexItem />
                                                <IconButton aria-label="delete" size="small" color="secondary" onClick={() => setOpenDeleteOptionDialog(rowIndex)}><DeleteForeverIcon /></IconButton>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {/* delete multi pricing */}
            <Dialog
                open={deleteOptionIndex !== "" ? true : false}
                onClose={() => setOpenDeleteOptionDialog("")}
                aria-labelledby="delete-option-dialog-title"
                aria-describedby="delete-option-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('product.confirmationOnDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('product.askDeleteMultiPricing')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteOptionDialog("")} color="secondary">{t('button.nope')}!</Button>
                    <Button onClick={deleteOption} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                </DialogActions>
            </Dialog>

            {/* add/edit multi pricing */}
            <Modal
                open={selectedOptionIndex !== '' ? true : false}
                onClose={() => { setOptionIndex(""); resetAllFieldError(); }}
                disableBackdropClick
                style={{ 
                    position: 'absolute',
                    width: '95vw',
                    backgroundColor: theme.palette.background.paper,
                    border: '2px solid #000',
                    boxShadow: theme.shadows[5],
                    left: '2.5vw',
                    top: '25%',
                    margin: 'auto',
                    overflow: 'scroll',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{selectedOptionIndex === "new" ? t('product.addMultiPricing') : t('product.editMultiPricing')}</Typography>
                                    <Divider />
                                </Grid>
                                {/* {typeInput} */}
                                <Grid item xs={12}>
                                    <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                                        <Typography variant="body1">{t('product.walletSettings')}</Typography>
                                        <Button size="small" startIcon={<AddIcon />} onClick={() => handleWalletSettingDialogOpen("new")}>{t('button.new')}</Button>
                                    </Grid>
                                </Grid>
                                {
                                    inputErrors.wallet_settings
                                        ?
                                        <Grid item xs={12}>
                                            <Typography variant="caption" style={{ color: "red" }}>
                                                {inputErrors.wallet_settings}
                                            </Typography>
                                        </Grid>
                                        :
                                        null
                                }
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        aria-label="table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('product.walletIds')}</TableCell>
                                                <TableCell>{t('product.pricingName')}</TableCell>
                                                <TableCell>{t('product.aType.title')}</TableCell>
                                                <TableCell>{t('product.minAmount')}</TableCell>
                                                <TableCell>{t('product.maxAmount')}</TableCell>
                                                <TableCell>{t('product.action')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                _.size(wallet_settings) > 0
                                                    ?
                                                    _.map(wallet_settings, (setting, key) => {
                                                        let walletDisplayText = '';
                                                        let walletError = '';
                                                        _.map(setting.wallet_ids, (wallet, key2) => {
                                                            walletDisplayText = walletDisplayText ? walletDisplayText + ', ' + wallet.wallet_name : wallet.wallet_name;
                                                                if(inputErrors['wallet_settings.' + key + '.wallet_ids.' + key2]){
                                                                    walletError = walletError ? (walletError + ' ' + inputErrors['wallet_settings.' + key + '.wallet_ids.' + key2]) : inputErrors['wallet_settings.' + key + '.wallet_ids.' + key2];
                                                                }
                                                        })
                                                        return (
                                                            <TableRow key={key}>
                                                                <TableCell>
                                                                    {walletDisplayText}
                                                                    <br />
                                                                    {
                                                                        walletError
                                                                            ?
                                                                            <Typography variant="caption" style={{ color: "red" }}>
                                                                                {walletError}
                                                                            </Typography>
                                                                            :
                                                                            null
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {setting.pricing_name}
                                                                    <br />
                                                                    {
                                                                        inputErrors['wallet_settings.' + key + '.pricing_name']
                                                                            ?
                                                                            <Typography variant="caption" style={{ color: "red" }}>
                                                                                {inputErrors['wallet_settings.' + key + '.pricing_name']}
                                                                            </Typography>
                                                                            :
                                                                            null
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {setting.type?t('product.aType.' + setting.type):''}
                                                                    <br />
                                                                    {
                                                                        inputErrors['wallet_settings.' + key + '.type']
                                                                            ?
                                                                            <Typography variant="caption" style={{ color: "red" }}>
                                                                                {inputErrors['wallet_settings.' + key + '.type']}
                                                                            </Typography>
                                                                            :
                                                                            null
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {setting.min_amount}
                                                                    <br />
                                                                    {
                                                                        inputErrors['wallet_settings.' + key + '.min_amount']
                                                                            ?
                                                                            <Typography variant="caption" style={{ color: "red" }}>
                                                                                {inputErrors['wallet_settings.' + key + '.min_amount']}
                                                                            </Typography>
                                                                            :
                                                                            null
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {setting.max_amount}
                                                                    <br />
                                                                    {
                                                                        inputErrors['wallet_settings.' + key + '.max_amount']
                                                                            ?
                                                                            <Typography variant="caption" style={{ color: "red" }}>
                                                                                {inputErrors['wallet_settings.' + key + '.max_amount']}
                                                                            </Typography>
                                                                            :
                                                                            null
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button size="small" startIcon={<EditIcon />} onClick={() => handleWalletSettingDialogOpen(key)}>{t('button.edit')}</Button>
                                                                    <Button size="small" startIcon={<DeleteForeverIcon />} onClick={() => handleWalletSettingDialogDelete(key)}>{t('button.delete')}</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    :
                                                    <TableRow style={{ height: 53 }}>
                                                        <TableCell colSpan={6} align="center">
                                                            {t('product.noWalletSetting')}
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => { setOptionIndex(""); resetAllFieldError(); }}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={saveOptions}>{t('button.save')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
            {/* add/edit wallet settings */}
            <Modal
                open={walletSettingDialog.open}
                onClose={handleWalletSettingDialogClose}
                disableBackdropClick
                style={{ 
                    position: 'absolute',
                    width: '95vw',
                    backgroundColor: theme.palette.background.paper,
                    border: '2px solid #000',
                    boxShadow: theme.shadows[5],
                    left: '2.5vw',
                    top: '25%',
                    margin: 'auto',
                    overflow: 'scroll',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{walletSettingDialog.index === "new" ? t('product.addWalletSetting') : t('product.editWalletSetting')}</Typography>
                                    <Divider />
                                </Grid>
                                {walletIdsInput}
                                {pricingNameInput}
                                {amountTypeInput}
                                {minAmountInput}
                                {maxAmountInput}
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={handleWalletSettingDialogClose}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={handleWalletSettingDialogSave}>{t('button.save')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    actionRoot: {
        width: 'fit-content',
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        // height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: '2.5vw',
        // right: 0,
        top: '25%',
        // bottom: 0,
        margin: 'auto',
        overflow: 'scroll',
    },
}));