import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Editor } from '@tinymce/tinymce-react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Paper, TextField, Button, Grid, FormControl, FormHelperText, Modal, MenuItem, Radio, RadioGroup, FormControlLabel, Checkbox, Slider, Switch } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import { DropzoneArea } from 'material-ui-dropzone';
import theme from '../../Theme';

import CategoryPanel from '@pages/Category/CategoryPanel';
import ProductColorPicker from '@components/ProductColorPicker';

// import ProductPointSection from './ProductPointSection';

function useInput({ type, label, val, placeholder, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} placeholder={placeholder}/>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, input, setErrorValue];
}

function useCheckbox({ label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

function useCompanySelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {`${option.shop_name} [ID: ${option.id}]`}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useRefundSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useWalletSelect({ data, label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => {
                    let name = _.split(option.name, '|');
                    return(
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })}
            </TextField>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useEditor({ label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
                <Typography variant="subtitle2" component="label">{label}</Typography>
                <Editor
                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                    value={value}
                    init={{
                        height: "70vh",
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount quickbars'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor forecolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                    }}
                    onEditorChange={(content, editor) => setValue(content)}
                />
                {error ?
                    <FormHelperText>{error}</FormHelperText>
                    : null
                }
            </FormControl>
        </Grid>;
    return [value, input, setErrorValue, error];
}

export default function ProductAdd() {
    const classes = useStyles();

    const { t } = useTranslation();
    let history = useHistory();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const { companyId, role, permissions, isAdmin } = useSelector(state => state.general);

    const [companies, setCompanies] = useState([]);
    const [openModal, setOpenModal] = useState("");
    const [claimInfo, setClaimInfo] = useState({
        mallComm: 0,
        sst: 0,
        walletDistribute: [],
        merchantClaimWallet: [],
    });
    const [orderClaimDisplay, setOrderClaimDisplay] = useState("");
    
    // color picker
    const [backgroundColor, setBackgroundColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#ffffff');
    const [showPreview, setShowPreview] = useState(false);

    const handleTogglePreview = () => {
        setShowPreview(!showPreview);
    };
    const [ranks, setRanks] = useState([]);
    const [mranks, setMRanks] = useState([]);
    const productType = isAdmin? [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')} /* , {code: "repurchase", label: t('product.type.repurchase')} **/ ] : [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}];
    const [type, productTypeInput, setProductTypeError] = useRefundSelect({ data: productType, label: `${t('product.type.title')}*`, val: "normal" });
    const [title, titleInput, setNameError] = useInput({ type: "text", label: `${t('product.productName')}*`, val: "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sku, skuInput, setSkuError] = useInput({ type: "text", label: `${t('product.sku')}`, val: "" });
    const [goods_brand, brandInput, setBrandError] = useInput({ type: "text", label: t('product.brand'), val: "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: t('product.price'), val: "" });
    const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.costPrice'), val: "", note: t('product.claimableIncome') + ': ' + orderClaimDisplay });
    const [retail_price, retailPriceInput, setRetailPriceError] = useInput({ type: "number", label: t('product.retailPrice'), val: "" });
    const [merchant_company_id, companySelect, setCompanyError] = useCompanySelect({ data: companies, label: `${t('product.shopName')}*`, val: "" });
    const [descr_en, descrEnInput, setDescrEnError, descrEnError] = useEditor({ label: t('product.descEn'), val: "" });
    const [descr_cn, descrCnInput, setDescrCnError, descrCnError] = useEditor({ label: t('product.descCn'), val: "" });
    const [quantity, quantityInput, setQuantityError] = useInput({ type: "number", label: `${t('product.stock')}*`, val: "" });
    const [shipping_fee, shippingFeeInput, setShippingFeeError] = useInput({ type: "number", label: t('product.shippingFee'), val: "" });
    const [west_shipping_fee, westShippingFeeInput, setWestShippingFeeError] = useInput({  type: "number", label: t('product.westShippingFee'), val: "" });
    const [east_shipping_fee, eastShippingFeeInput, setEastShippingFeeError] = useInput({  type: "number", label: t('product.eastShippingFee'), val: "" });
    const [ship_weight, weightInput, setWeightError] = useInput({ type: "number", label: `${t('product.weightKg')}*`, val: "" });
    const [package_length, lengthInput, setLengthError] = useInput({ type: "number", label: t('product.lengthCm'), val: '' });
    const [package_width, widthInput, setWidthError] = useInput({ type: "number", label: t('product.widthCm'), val: '' });
    const [package_height, heightInput, setHeightError] = useInput({ type: "number", label: t('product.heightCm'), val: '' });
    const [prepare_time, prepareTimeInput, setPrepareTimeError] = useInput({ type: "number", label: `${ t('product.prepareTime')}*`, val: 2, note: t('product.prepareTimeNote') });
    const [purchase_limit, purchaseLimitInput, setPurchaseLimitError] = useInput({ type: "number", label: `${ t('product.purchaseLimit')}*`, val: 0, note: t('product.purchaseLimitNote') });
    const [order_limit, orderLimitInput, setOrderLimitError] = useInput({ type: "number", label: `${ t('product.orderLimit')}*`, val: 0, note: t('product.orderLimitNote') });
    const [bv, bvInput, setBvError] = useInput({ type: "number", label: t('product.bv'), val: "" });
    const [maxCap, maxCapInput, setMaxCapError] = useInput({ type: "number", label: t('product.maxCap'), val: "" });
    const [rank_id, rankIdInput, setRankIdError] = useWalletSelect({ data: ranks, label: `${t('product.relatedRank')}*`, val: 0, note: t('product.relatedRankNote') });
    const [mrank_id, mrankIdInput, setMRankIdError] = useWalletSelect({ data: mranks, label: `${t('product.leadershipRank')}*`, val: 0, note: t('product.leadershipRankNote') });
    const [seller_cover_shipping, sellerCoverShippingInput, setSellerCoverShippingInputError] = useCheckbox({ label: `${ t('product.sellerCoverShipping')}`, val: false });
    // const [sell_price_repurchase, sellingPriceReInput, setSellingPriceReError] = useInput({ type: "number", label: t('product.priceRepurchase'), val: "" });
    // const [bv_repurchase, bvReInput, setBvReError] = useInput({ type: "number", label: t('product.bvRepurchase'), val: "" });

    const [newImages, setNewImages] = useState([]);
    const [newImagesError, setNewImagesError] = useState("");
    const [shipping_channel, setShippingChannel] = useState('self');
    const [categoryData, setCategoryData] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [malaysiaMerchant, setMalaysiaMerchant] = useState(false); // if true, can set shipping for east and west msia

    const validate = { title: setNameError, sku: setSkuError, goods_brand: setBrandError, sell_price: setSellingPriceError, cost_price: setCostPriceError,
        retail_price: setRetailPriceError, category_id: setCategoryError, descr_en: setDescrEnError, descr_cn: setDescrCnError, quantity: setQuantityError,
        images: setNewImagesError, merchant_company_id: setCompanyError, shipping_fee: setShippingFeeError, shipping_fee2: setEastShippingFeeError, ship_weight: setWeightError, package_length: setLengthError,
        package_width: setWidthError, package_height: setHeightError, prepare_time: setPrepareTimeError, purchase_limit: setPurchaseLimitError,order_limit: setOrderLimitError,
        bv: setBvError, rank_id: setRankIdError,mrank_id: setMRankIdError,
        seller_cover_shipping: setSellerCoverShippingInputError, type: setProductTypeError,
        // sell_price_repurchase: setSellingPriceReError, bv_repurchase: setBvReError,
        maxCap : setMaxCapError };

    useEffect(() => {
        isMountedRef.current = true;
        const checkId = isAdmin ? merchant_company_id : companyId;
        if(checkId) {
            getUrl(`admin/companies/${checkId}`).then(response => {
                if(response.status && isMountedRef.current) {
                    if(response.data.country === 'MY') {
                        setMalaysiaMerchant(true);
                    } else {
                        setMalaysiaMerchant(false);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [isAdmin, merchant_company_id, companyId]);

    function callApi(){
        getUrl(`admin/categories`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setCategoryData(result.data)
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        if(isAdmin){
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }

        if(_.includes(permissions, "rank")){
            getUrl('admin/grouped_rank_list').then(response => {
                if (isMountedRef.current && response.status === 1) {
                    let tempRank = response.rank;
                    tempRank.push({id: 0, name: t('rank.noRank')});
                    setRanks(tempRank);
                    let tempMRank = response.mrank;
                    console.log("mrank",tempMRank)
                    tempMRank.push({id: 0, name: t('rank.noRank')})
                    setMRanks(tempMRank);
                    
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }else{
            let tempRank = [];
            tempRank.push({id: 0, name: t('rank.noRank')});
            setRanks(tempRank);
            setMRanks(tempRank);
        }

        getUrl(`admin/preview-order-claim`).then(response => {
            if(response.status) {
                setClaimInfo({
                    mallComm: response.mall_commission,
                    sst: response.sst,
                    walletDistribute: response.order_claim_wallet,
                    merchantClaimWallet: response.merchant_claim_wallet,
                });
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);   

    useEffect(() => {
        isMountedRef.current = true;
        const checkId = isAdmin ? merchant_company_id : companyId;
        if(checkId) {
            getUrl(`admin/companies/${checkId}`).then(response => {
                if(response.status && isMountedRef.current) {
                    if(response.data.country === 'MY') {
                        setMalaysiaMerchant(true);
                    } else {
                        setMalaysiaMerchant(false);
                    }
                    console.log("response.data", response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [isAdmin, merchant_company_id, companyId]);
    
    useEffect(() => {
        let company = _.find(companies, function (c) { return parseInt(c.id) === parseInt(merchant_company_id); });
        let comm = company?.shop_commission > 0 ? company?.shop_commission : claimInfo.mallComm;
        let profit = type === 'starter' ? (sell_price * 0.2) : (sell_price - cost_price);
        let total = parseFloat((type === 'starter' ? profit : cost_price) - (profit * comm) - (profit * claimInfo.sst));
        let text = '';
        if (type === 'starter') {
            text = (total).toFixed(2) + ' SP';
        } else {
            let claimWallet = _.find(claimInfo.merchantClaimWallet, function(w, key){ return parseInt(key) === parseInt(merchant_company_id);});
            if(!claimWallet){
                claimWallet = claimInfo.walletDistribute;
            }
            _.map(claimWallet, (wallet, percent) => {
                if (wallet.source === 'local') {
                    text += (text ? ', ' : '') + parseFloat(total * percent).toFixed(2) + ' ' + wallet.code;
                }
            })
        }
        setOrderClaimDisplay(text?text:'-');
        // eslint-disable-next-line
    }, [companies, merchant_company_id, claimInfo, type, sell_price, cost_price]);

    const changeCategory = currentId => {
        setCategoryId(currentId);
    }

    const categoryTreeSelect = () => {
        return (
        <Grid item xs={12} sm={12}>
            <CategoryPanel initialShow={true} initialData={categoryData} changeCategoryParent={changeCategory} />
            {
                categoryError
                ?
                <Typography variant="caption" style={{color: "red"}}>
                    {categoryError}
                </Typography>
                :null
            }
        </Grid>
        )
    }

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        })
    };

    let handleSubmit = async () => {
        setLoading(true);
        resetAllFieldError();
        let newImagesBase64 = [];

        if(_.size(newImages) > 0){
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }

        let apiData = {
            title,
            category_id: categoryId,
            merchant_company_id,
            goods_brand,
            sell_price,
            cost_price,
            retail_price,
            quantity,
            descr_en,
            descr_cn,
            images: newImagesBase64,
            shipping_channel,
            shipping_fee: malaysiaMerchant ? west_shipping_fee : shipping_fee,
            shipping_fee2: malaysiaMerchant ? east_shipping_fee : 0,
            ship_weight,
            package_length,
            package_width,
            package_height,
            prepare_time,
            purchase_limit,
            sku,
            bv,
            rank_id: type == 'normal' ? null : rank_id,
            mrank_id: type == 'normal' ? null : mrank_id,
            maxCap : type == 'normal' ? null : maxCap,
            seller_cover_shipping: seller_cover_shipping ? 1 : 0,
            type,
            // sell_price_repurchase,
            // bv_repurchase,
            order_limit,
            showPreview,
            background_color: backgroundColor,
            text_color: textColor,
        }
        
        postUrl(`admin/products`, apiData)
        .then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                let {status, message, error, data} = response;
                if(status === 1){
                    addAlert('', message, 'success', '');
                    history.replace(`/products/${data.id}`);
                }else{                    
                    if(_.size(error) > 0){
                        _.map(error, (value, key) => {
                            if(validate[key])
                                validate[key](value[0]);
                            else
                                message += "\n "+value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    return(
        <Grid container spacing={3}>            
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('product.addProduct')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/products`} component={RouterLink}>{t('product.products')}</Link>
                        <Typography style={{ color: 'black' }}>{t('product.addProduct')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="h1">{t('product.aboutProduct')}</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.generalInfo')}</Typography>
                            </Grid>
                            {titleInput}
                            {/* {productTypeInput} */}
                            {_.size(companies) ? companySelect : null}
                            {skuInput}
                            {brandInput}
                            {categoryTreeSelect()}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.priceAndBv')}</Typography>
                            </Grid>
                            {sellingPriceInput}
                            {costPriceInput}
                            {retailPriceInput}
                            {_.includes(permissions, "product_bonus") ? bvInput : null}
                            {_.size(ranks) > 1 ? (type == 'normal' ? null : rankIdInput) : null}
                            {_.size(mranks) > 1 ? (type == 'normal' ? null : mrankIdInput) : null}
                            {type == 'normal' ? null : maxCapInput}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.stock')}</Typography>
                            </Grid>
                            {quantityInput}
                            {purchaseLimitInput}
                            {orderLimitInput}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.weightAndDimensional')}</Typography>
                            </Grid>
                            {weightInput}
                            {lengthInput}
                            {widthInput}
                            {heightInput}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.others')}</Typography>
                            </Grid>
                            {prepareTimeInput}
                        </Grid>
                        <Grid item xs={12} style={theme.mt30}>
                            <Typography variant="h5" component="h1">{t('product.productDescription')}</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_en")}>{t('product.english')}</Button>
                                {descrEnError ?
                                    <FormHelperText style={{ color: "red" }}>{descrEnError}</FormHelperText>
                                    : null
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_cn")}>{t('product.mandarin')}</Button>
                                {descrCnError ?
                                    <FormHelperText style={{ color: "red" }}>{descrCnError}</FormHelperText>
                                    : null
                                }
                            </Grid>
                        </Grid>
                        <Modal
                            open={openModal ? true : false}
                            onClose={() => setOpenModal("")}
                            aria-labelledby="product description"
                            aria-describedby="product description modal"
                        >
                            <Grid container spacing={3} justify="center" className={classes.modal}>
                                {openModal === "descr_cn" ? descrCnInput : descrEnInput}
                                <Grid item xs={12} sm={6}>
                                    <Button type="button" fullWidth variant="contained" color="primary" onClick={() => setOpenModal("")}>{t('button.close')}</Button>
                                </Grid>
                            </Grid>
                        </Modal>
                        <Grid item xs={12} style={theme.mt30}>
                            <Typography variant="h5" component="h1">{t('product.shippingChannel')}</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="shipping channel" name="shippingChannel" value={shipping_channel} onChange={(event) => { setShippingChannel(event.target.value) }}>
                                        <FormControlLabel value="self" control={<Radio />} label={t('product.selfShipping')} />
                                        <FormControlLabel value="mall" control={<Radio />} label={t('product.mallShipping')} />
                                    </RadioGroup>
                                    <FormHelperText style={{paddingBottom: 5}}>{t('product.shippingWeightNote')}</FormHelperText>
                                </FormControl>
                                { shipping_channel === "self" ? 
                                    <>
                                        { malaysiaMerchant ? 
                                            <>
                                                { westShippingFeeInput }
                                                <div style={{ marginTop: 10 }}>
                                                { eastShippingFeeInput }
                                                </div>
                                            </>
                                        : shippingFeeInput }
                                    </>
                                : null }
                                {shipping_channel === "mall" ? sellerCoverShippingInput : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={theme.mt30}>
                            <Typography variant="h5" component="h1">{t('product.uploadImages')}</Typography>
                            {/* <Divider /> */}
                        </Grid>
                        <Grid item xs={12} container className={classes.imageRoot}>
                            <DropzoneArea
                                acceptedFiles={['image/*']}
                                dropzoneText={t('product.dragDropImageHere')}
                                onChange={(files) => setNewImages(files)}
                                filesLimit={10}
                                dropzoneClass={classes.dropzone}
                                maxFileSize={2000000}
                            />
                            <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10}) + t('general.productfileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                            {newImagesError ?
                                <FormHelperText style={{ color: "red" }}>{newImagesError}</FormHelperText>
                                : null
                            }
                        </Grid>
                        {/* color picker */}
                        {/* <Grid item xs={12} sm={12}>
                            <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.colorPicker')}</Typography>
                        </Grid> */}
                        {/* switch button */}
                        {/* <Grid item xs={12} sm={12}>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>{t('button.off')}</Grid>
                                    <Grid item>
                                        <Switch checked={showPreview} onChange={handleTogglePreview} />
                                    </Grid>
                                    <Grid item>{t('button.on')}</Grid>
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ProductColorPicker showPreview={showPreview} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} textColor={textColor} setTextColor={setTextColor} newImages={newImages} />
                        </Grid> */}
                        {/* {
                            _.size(pointList) > 0
                            ?
                            <ProductPointSection pointList={pointList} pointData={pointData} setPointData={setPointData}/>
                            :
                            null
                        } */}
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={8} sm={6} md={4} style={{marginTop: 30}}>
                                <Button type="button" fullWidth variant="contained" color="primary" onClick={handleSubmit}>{t('button.proceed')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },    
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    }
}));