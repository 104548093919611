import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useNotificationLoading from '@utils/useNotificationLoading';

import _ from 'lodash';
import { getUrl } from '@utils/ApiAction';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, Paper, LinearProgress, TextField, IconButton, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';

export default function OrderApi() {
    const classes = useStyles();
    const theme = useTheme();
    const { t,i18n } = useTranslation();
    const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);
    const { addAlert } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sources, setSources] = useState([]);
    const [status, setStatus] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [selectedSource, setSelectedSource] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const orderType = window.location.pathname.includes('order-claim') ? 'order-claim' : 'order-refund';
    const title = orderType === 'order-claim' 
        ? t('title.thirdPartyOrderClaim') 
        : t('title.thirdPartyOrderRefund');

    useEffect(() => {
        setTableLoading(true);
        const apiUrl = `admin/order/${orderType}`;
        getUrl(apiUrl).then(result => {
            setTableLoading(false);
            if(result.status == 1){
                setTableData(result.data);
                setFilteredData(result.data);
                setSources([...new Set(result.data.map(item => item.source))]);
                setStatus([...new Set(result.data.map(item => item.localized_status[i18n.language]))]);
            }
        }).catch(error => {
            setTableLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    
    }, [i18n.language]);        

    useEffect(() => {
        setFilteredData(
            tableData.filter(row => 
                row.username.toLowerCase().includes(search.toLowerCase()) &&
                (selectedSource ? row.source === selectedSource : true) &&
                (selectedStatus ? row.localized_status[i18n.language] === selectedStatus : true)
            )
        );
    }, [search, selectedSource, selectedStatus, tableData, i18n.language]);       

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleSourceChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSource(selectedValue);
    };

    const handleStatusChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedStatus(selectedValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const formatRequestAmount = (amount) => {
        if (!amount || typeof amount !== 'object') return 'Unknown';
        return Object.entries(amount).map(([key, value]) => `${key}: ${value}`).join(', ');
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{title}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} container justify="flex-start" spacing={2}>
                    <Grid item>
                        <FormControl variant="outlined" size="small" className={classes.formControl}>
                            <InputLabel>{t('orderApi.source')}</InputLabel>
                            <Select
                                value={selectedSource}
                                onChange={handleSourceChange}
                                label={t('orderApi.source')}
                            >
                                <MenuItem value="">
                                    <em>{t('table.all')}</em>
                                </MenuItem>
                                {sources.map((source) => (
                                    <MenuItem key={source} value={source}>
                                        {source}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item>
                        <FormControl variant="outlined" size="small" className={classes.formControl}>
                            <InputLabel>{t('orderApi.status')}</InputLabel>
                            <Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                label={t('orderApi.status')}
                            >
                                <MenuItem value="">
                                    <em>{t('table.all')}</em>
                                </MenuItem>
                                {status.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    <Grid container spacing={2} alignItems="center" style={{ justifyContent: 'flex-end' }}>
                        <Grid item>
                            <TextField
                                label={t('search')}
                                variant="outlined"
                                size="small"
                                value={search}
                                onChange={handleSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    {/* {_.includes(permissions, "order_api_edit") ? */}
                                    <TableCell>{t('orderApi.action')}</TableCell>
                                    {/* :null} */}
                                    <TableCell>{t('orderApi.orderId')}</TableCell>
                                    <TableCell>{orderType === 'order-claim' ? t('orderApi.shopName') : t('orderApi.username')}</TableCell>
                                    <TableCell>{t('orderApi.source')}</TableCell>
                                    <TableCell>{t('orderApi.amount')}</TableCell>
                                    <TableCell>{t('orderApi.status')}</TableCell>
                                    <TableCell>{t('orderApi.createdAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(
                                    rowsPerPage > 0
                                        ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filteredData
                                    ).map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <Link underline='none' to={`/third-party/${row.type}/${row.id}`} component={RouterLink}>
                                                    <EditIcon />
                                                </Link>
                                            </TableCell>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>
                                                {orderType === 'order-claim' 
                                                    ? <Typography> 
                                                        <Link underline='none' to={`/sellers/${row.ref_id}`} component={RouterLink}>
                                                            {row.username}
                                                        </Link>
                                                    </Typography>
                                                    : <Link underline='none' to={`/members/${row.ref_id}`} component={RouterLink}>
                                                        {row.username}
                                                    </Link>
                                                }
                                            </TableCell>
                                            <TableCell>{row.source}</TableCell>
                                            <TableCell>{formatRequestAmount(row.request_amount)}</TableCell>
                                            <TableCell>{row.localized_status[i18n.language]}</TableCell>
                                            <TableCell>{row.created_at}</TableCell>
                                        </TableRow>
                                    ))}
                                {filteredData.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={7} align="center">
                                            { tableLoading ? <LinearProgress /> : t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    formControl: {
        minWidth: 120,
        width: '200px',
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));