import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, Box, AppBar, Tab, Tabs } from '@material-ui/core';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import PersonIcon from '@material-ui/icons/Person';
import StorefrontIcon from '@material-ui/icons/Storefront';
// import StoreIcon from '@material-ui/icons/Store';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

export default function WalletAddDeduct() {
    const classes = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const action = [{code: "add", label: t('wallet.add')}, {code: "deduct", label: t('wallet.deduct')}];
    const [inputErrors, setInputErrors] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [label, setLabel] = useState({
        source: '',
        loginMethod: t('company.slug'),
    });
    const [state, setState] = useState({
        source: '',
        loginMethod: '',
        walletId: '',
        walletAction: '',
        amount: '',
        remark: '',
    });
    const [walletList, setWalletList] = useState([]);
    const [sourceList, setSourceList] = useState([]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/wallets`).then(result => {
            if(isMountedRef.current && result.status===1) {
                setWalletList(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
        getUrl(`admin/wallets/source_list`).then(result => {
            if(isMountedRef.current && result.status===1) {
                setSourceList(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        switch(newValue){
            case(0):    
                setLabel({
                    source: '',
                    loginMethod: t('company.slug'),
                }); 
                break;

            case(1):    
                setLabel({
                    source: t('wallet.source'),
                    loginMethod: t('profile.username'),
                    // loginMethod: t('profile.username')+"/"+t('profile.email')+"/"+t('profile.mobile'),
                }); 
                break;

            case(2):    
                setLabel({
                    source: t('wallet.platform'),
                    loginMethod: t('wallet.storeName'),
                }); 
                break;
                
            default:    
                setLabel({
                    source: '',
                    loginMethod: '',
                }); 
                break;
        }
        setState({
            source: '',
            loginMethod: '',
            walletId: '',
            walletAction: '',
            amount: '',
            remark: '',
        });
        setInputErrors("");
    };

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        let type = '';
        if(tabValue === 0){
            type = 'company';
        }else if(tabValue === 1){
            type = 'user';
        }
        // }else if(tabValue === 2){
        //     type = 'store';
        // }
        const updateData = {
            source: state.source,
            login_method: state.loginMethod,
            wallet_id: state.walletId,
            wallet_action: state.walletAction,
            amount: state.amount,
            ref_type: type,
            remark: state.remark
        }
        postUrl(`admin/wallets/add_deduct`, updateData).then(response => {
            setLoading(false);
            if(response.status) {
                setState({
                    source: '',
                    loginMethod: '',
                    walletId: '',
                    walletAction: '',
                    amount: '',
                    remark: '',
                });
                if(response.data){
                    addAlert(response.data, '', 'success');
                }else{
                    addAlert(t('snackbarMsg.updateSuccess'), '', 'success');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.updateError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('wallet.walletAddDeduct')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Typography style={{ color: 'gray' }}>{t('wallet.wallets')}</Typography>
                        <Typography style={{ color: 'black' }}>{t('wallet.walletAddDeduct')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:theme.palette.primary.main,WebkitBoxShadow:'none'}} >
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="on" >
                    <Tab icon={<StorefrontIcon />} label={t('wallet.company')} {...a11yProps(0)} />
                    <Tab icon={<PersonIcon />} label={t('wallet.user')} {...a11yProps(1)} />
                    {/* <Tab icon={<StoreIcon />} label={t('wallet.store')} {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        {
                            tabValue !== 0 // not company
                            ?
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="source" 
                                    label={label.source}
                                    variant="outlined" 
                                    fullWidth 
                                    select
                                    value={state.source}
                                    helperText={inputErrors.source ? inputErrors.source :''}
                                    error={inputErrors.source ? true : false}
                                    onChange={({ target }) => setState({ ...state, source: target.value }) }
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                <option key='' value='' disabled>
                                    {t('general.pleaseSelect')}
                                </option>
                                {sourceList.map((value, key) => {
                                    let name = _.split(value.name, '|');
                                    return(
                                        <option key={key} value={value.code}>
                                            { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                        </option>
                                    )
                                })}
                                </TextField>
                            </Grid>
                            :
                            null
                        }
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="login_method" 
                                label={label.loginMethod}
                                variant="outlined" 
                                fullWidth 
                                value={state.loginMethod}
                                helperText={inputErrors.login_method ? inputErrors.login_method :''}
                                error={inputErrors.login_method ? true : false}
                                onChange={({ target }) => setState({ ...state, loginMethod: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="wallet_id" 
                                label={t('wallet.walletType')}
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.walletId}
                                helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                                error={inputErrors.wallet_id ? true : false}
                                onChange={({ target }) => setState({ ...state, walletId: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {walletList.map((value, key) => {
                                let name = _.split(value.name, '|');
                                return(
                                    <option key={key} value={value.id}>
                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                    </option>
                                )
                            })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="wallet_action" 
                                label={t('wallet.action')}
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.walletAction}
                                helperText={inputErrors.wallet_action ? inputErrors.wallet_action :''}
                                error={inputErrors.wallet_action ? true : false}
                                onChange={({ target }) => setState({ ...state, walletAction: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {action.map((value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="amount" 
                                label={t('wallet.amount')}
                                variant="outlined" 
                                fullWidth 
                                value={state.amount}
                                helperText={inputErrors.amount ? inputErrors.amount :''}
                                error={inputErrors.amount ? true : false}
                                onChange={({ target }) => setState({ ...state, amount: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="remark" 
                                label={t('wallet.remark')}
                                variant="outlined" 
                                fullWidth 
                                value={state.remark}
                                helperText={inputErrors.remark ? inputErrors.remark :''}
                                error={inputErrors.remark ? true : false}
                                onChange={({ target }) => setState({ ...state, remark: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="primary" onClick={form_submit}>{t('button.confirm')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));