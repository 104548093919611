import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, putUrl, deleteUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { formatDate, useQuery } from '@utils/Tools';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, Paper, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, FormControlLabel, Switch, TextField , MenuItem, Box, Chip } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import CategoryPanel from '@pages/Category/CategoryPanel';


function TableToolbar(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

function useCompanySelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <TextField
                    select
                    label={label}
                    value={value}
                    size="small"
                    onChange={({ target }) => setValue(target.value)}
                    variant="outlined"
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                >
                    {data.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {`${option.shop_name} [ID: ${option.id}]`}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    return [value, select, setErrorValue, setValue];
}

function useSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <TextField
                    select
                    label={label}
                    value={value}
                    size="small"
                    onChange={({ target }) => setValue(target.value)}
                    variant="outlined"
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                >
                    {data.map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    return [value, select, setErrorValue, setValue];
}


export default function Products() {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions, role, isAdmin, isMerchant } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [refreshData, setRefreshData] = useState(false);
    const [duplicateDialog, setDuplicateDialog] = useState({
        open: false,
        pid: '',
        action: ''
    });
    const [companies, setCompanies] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [merchant_company_id, companySelect, setCompanyError, setMerchantCompanyId] = useCompanySelect({ data: companies, label: `${t('product.shopName')}*`, val: "" });
    const [filterDialog, setFilterDialog]=useState({
        open:false,
        filter:false
    });
    const statusData =[{value: 0, label: t("product.filterStatus.no")}, {value: 1, label: t("product.filterStatus.published")}, {value: 2, label: t("product.filterStatus.unpublished")},{value: 3, label: t("product.filterStatus.duplicating")},{value: 4, label: t("product.filterStatus.pending")},{value: 5, label: t("product.filterStatus.deleted")}];
    const [status, statusInput, statusValidate, setStatus] = useSelect({ data: statusData, label: t('product.status'), val: 0 });
    const validate={merchant_company_id: setCompanyError, status:statusValidate}

    const [categoryError, setCategoryError] = useState("");
    const [categoryIdList, setCategoryIdList]=useState([]);
    const [categoryId, setCategoryId]=useState("");

    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/products${searchString !== '' ? '?'+searchString : ''}`);
    }


    function callApi(){
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order};
        setTableLoading(true);
        getUrl('admin/products', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        if(filterDialog.filter){
            handleChangeFilter('filter');
        }else{
            callApi();
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, querySearch, orderBy, order, refreshData,filterDialog.filter]);

    useEffect(()=>{
        isMountedRef.current =true;
        getUrl(`admin/categories`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setCategoryData(result.data)
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        // if(role !== "super-merchant" && role !=="merchant"){
        if(isAdmin){
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    },[]);

    const changeStatus = (event, pid) => {
        setLoading(true);

        let updateStatus = event.target.checked ? 1 : 0;
        let theIndex = listing.findIndex(item => item.id === pid);
        putUrl(`admin/products/${pid}/status`, {status: updateStatus })
        .then((response) => {
            setLoading(false);
            let {status, message, data} = response;
            if(status === 1){
                let newListing = [...listing];
                newListing[theIndex] = data;
                setListing([...newListing]);

                addAlert('', message, 'success', '');
            }else{
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };

    const handleDuplicateDialogOpen = (pid, action) => {
        setDuplicateDialog({
            open: true,
            pid: pid,
            action: action
        });
    }
    const handleDuplicateDialogClose = () => {
        setDuplicateDialog({
            open: false,
            pid: '',
            action: ''
        });
    }

    const handleFilterDialogOpen =()=>{
        setFilterDialog({...filterDialog, open:true});
    }

    const handleFilterDialogClose =()=>{
        setFilterDialog({...filterDialog, open:false});
    }

    const deleteDuplicating = (pid) => {
        setLoading(true);
        handleDuplicateDialogClose();
        deleteUrl(`admin/products/${pid}/duplicate`).then((response) => {
            setLoading(false);
            if(response.status === 1){
                addAlert('', t('snackbarMsg.deleteSuccess'), 'success', '');
                setRefreshData(!refreshData);
            }else{
                if(response.data){
                    addAlert('', response.data, 'error', '');
                }else{
                    addAlert('', t('snackbarMsg.deleteError'), 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    const deleteProduct = (pid) => {
        setLoading(true);
        handleDuplicateDialogClose();
        let theIndex = listing.findIndex(item => item.id === pid);
        putUrl(`admin/products/${pid}/status`, {status: 3 })
        .then((response) => {
            setLoading(false);
            let {status, message, data} = response;
            if(status === 1){
                let newListing = [...listing];
                newListing[theIndex] = data;
                setListing([...newListing]);
                addAlert('', message, 'success', '');
            }else{
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };


    const handleChangeFilter = (action) => {
        
        setLoading(true);
        let statusParams = '';
        if (action === 'reset') {
            setFilterDialog({...filterDialog, open:false,filter:false});
            setMerchantCompanyId("");
            setStatus(0);
            setCategoryIdList([]);
            setCategoryId("")
            setLoading(false);
        }else if(action ==='filter'){
            setFilterDialog({...filterDialog,open:false,filter:true});
            if (status === 1) {
                statusParams = 'Published';
            } else if (status === 2) {
                statusParams = 'Unpublished';
            } else if (status === 3) {
                statusParams = 'Duplicating';
            } else if (status === 4) {
                statusParams = 'Pending';
            } else if (status === 5) {
                statusParams = 'Deleted';
            }
        }

        let filterData = {
            merchant_company_id,
            status: statusParams,
            category_list:categoryIdList,
            page:page,
            per_page:rowsPerPage,
            q:querySearch,
            order_by:orderBy,
            order:order
        }

        getUrl('admin/products_filter', filterData).then(result => {
            setLoading(false);
            if (result.status === 1) {
                setListing(result.data.data);
                setTotalCount(result.data.total);
            }
        }).catch((error) => {
            setLoading(false);
            setListing([]);
            setTotalCount(0);
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });

    }

    const changeCategory = currentId => {
        setCategoryId(currentId);
    }

    const categoryTreeSelect = () => {
        return (
        <Grid item xs={12} sm={12}>
            <CategoryPanel initialShow={true} initialData={categoryData} changeCategoryParent={changeCategory} />
            {
                categoryError
                ?
                <Typography variant="caption" style={{color: "red"}}>
                    {categoryError}
                </Typography>
                :null
            }
        </Grid>
        )
    }

    const handleAddCategoryList =(value)=>{
        if(categoryIdList.includes(value)){
            addAlert('',t('product.filterCatAddError'), 'error', '');
        }else if(value){
            setCategoryIdList(arr=>[...arr,value]);
        }
    }

    const handleDeleteCategoryList =(value)=>{
        setCategoryIdList((categoryIdList)=>categoryIdList.filter((item)=> parseInt(item) !== parseInt(value)));
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Grid item>
                        <Typography variant="h4" component="h1">{t('product.products')}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-end" direction="row" justify="space-between" >
                            <Button size="medium" variant="outlined" color="inherit" onClick={() => handleFilterDialogOpen()} startIcon={<FilterListIcon />}>{t('button.filter')}</Button>
                            {_.includes(permissions, "product_add") ?
                                <Link underline='none' color="inherit" to="/products/add" component={RouterLink} style={{paddingLeft:10}} >
                                    <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                                </Link>
                                : null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.id')} cellId={'id'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.productName')} cellId={'title'} />
                                    <TableCell>{t('product.category')}</TableCell>
                                    {isMerchant ? null : <TableCell>{t('product.shopName')}</TableCell> }
                                    {/* <TableCell>{t('product.source')}</TableCell> */}
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('table.createDate')} cellId={'created_at'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('table.ldate')} cellId={'updated_at'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('product.status')} cellId={'status'} />
                                    {isAdmin ? <TableCell>{t('product.deleteProduct')}</TableCell> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    let title_translate = _.split(row.title, '|');
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell component="th" scope="row"><Link  style={{color:"#929191"}} underline='none' to={`/products/${row.id}`} component={RouterLink}>{ i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }</Link></TableCell>
                                            <TableCell>{ row[`category_${i18n.language}`]}</TableCell>
                                            {isMerchant ? null : <TableCell>{row.shop_name}</TableCell> }
                                            {/* <TableCell>{row.source}</TableCell> */}
                                            <TableCell>{formatDate(row.created_at)}</TableCell>
                                            <TableCell>{row.updated_at}</TableCell>
                                            <TableCell>
                                                {
                                                    row.status !== -1 && row.status !== 3
                                                    ?
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={isMerchant ? (row.status ? true : false) : (row.status === 0 || row.status === 2 ? false : true)}
                                                                onChange={(event) => changeStatus(event, row.id)}
                                                                name="status"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                    :
                                                    row.status === -1
                                                    ?
                                                    <IconButton color="primary" onClick={() => handleDuplicateDialogOpen(row.id, 'deleteDuplicate')}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    : null
                                                }
                                                <Typography>{ row[`status_display_${i18n.language}`] }</Typography>
                                            </TableCell>
                                            {
                                                isAdmin
                                                ?
                                                <TableCell>
                                                {
                                                    row.status !== -1 && row.status !== 3
                                                    ?
                                                    <Tooltip title={t('product.deleteProduct')}>
                                                        <IconButton variant="outlined" color="primary" onClick={() => handleDuplicateDialogOpen(row.id, 'deleteProduct')}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null
                                                }
                                                </TableCell>
                                                : null
                                            }
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={8} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
                <Dialog
                    open={duplicateDialog.open}
                    onClose={() => handleDuplicateDialogClose()}
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle>{t('dialog.reminderTitle')}</DialogTitle>
                    <DialogContent>
                        {
                            duplicateDialog.action === 'deleteDuplicate'
                            ?
                            <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.deleteDuplicate') })}</DialogContentText>
                            :
                            duplicateDialog.action === 'deleteProduct'
                            ?
                            <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.deleteProduct') })}</DialogContentText>
                            : null
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDuplicateDialogClose()} color="secondary">{t('button.nope')}!</Button>
                        {
                            duplicateDialog.action === 'deleteDuplicate'
                            ?
                            <Button onClick={() => deleteDuplicating(duplicateDialog.pid)} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                            :
                            duplicateDialog.action === 'deleteProduct'
                            ?
                            <Button onClick={() => deleteProduct(duplicateDialog.pid)} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                            : null
                        }
                    </DialogActions>
                </Dialog>
                <Dialog
                open={filterDialog.open}
                onClose={()=>handleFilterDialogClose()}
                fullWidth
                maxWidth="lg"
                >
                    <DialogContent>
                        <Grid container spacing={2} direction="row" alignItems="center" justify="flex-start">
                            {
                                isAdmin ?
                                    <Grid item xs={12} style={{ padding: 15 }}>
                                        {companySelect}
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12} style={{padding:15}}>
                                {statusInput}
                            </Grid>
                            <Grid item xs={12} style={{padding:15}}>
                                <Grid container spacing={2} direction="row" alignItems="center" justify="space-between">
                                <Typography>{t('product.category')}</Typography>
                                <Button color="primary" onClick={() => handleAddCategoryList(categoryId)} variant="contained">{t('button.add')}</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} direction="row" alignItems="center" justify="space-around">
                                    <Grid item xs={8}>
                                        {categoryTreeSelect()}
                                    </Grid>
                                    <Grid item xs={4} style={{border:'solid 2px #000000'}}>
                                        <Box style={{height:'85vh'}}>
                                            <Typography variant="caption">{t('product.filterCatInfo')}</Typography>
                                            <br/>
                                            {
                                                _.size(categoryIdList) > 0 ?
                                                    <>
                                                        {
                                                            _.map(categoryIdList, item => {
                                                                let listItem = _.find(categoryData, { 'id': item });
                                                                return (
                                                                    <Chip label={listItem[`name_${i18n.language}`]} onDelete={() => handleDeleteCategoryList(item)} variant="outlined" color="primary" key={item} style={{ margin: 5 }} size="small" deleteIcon={<ClearIcon color="primary" />} />
                                                                )

                                                            })
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2} direction="row" alignItems="center" justify="space-around" style={{padding:10}}>
                            <Button color="secondary" onClick={()=>handleFilterDialogClose()}>{t('button.nope')}!</Button>
                            <Button color="primary" onClick={()=>handleChangeFilter('reset')}>{t('button.reset')}</Button>
                            <Button color="primary" onClick={()=>handleChangeFilter('filter')} disabled={!merchant_company_id && !status && !categoryIdList.length? true:false}>{t('button.filter')}</Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));