import {
    ADD_ERROR, CHANGE_LANGUAGE, STORE_UUID, REMOVE_UUID, CART_TOTAL, CODE_STATE, RESET_CODE_STATE, AUTH_SUCCESS, AUTH_FAIL, ORDER_COUNT, ANNOUNCE_UNREAD_COUNT
} from '@types';

export const addError = data => dispatch => {
    dispatch({ type: ADD_ERROR, payload: data });
}

export const changeLanguage = lang => dispatch => {
    dispatch({ type: CHANGE_LANGUAGE, payload: lang });
}

export const storeUuid = uuid => dispatch => {
    dispatch({ type: STORE_UUID, payload: uuid });
}

export const removeUuid = () => dispatch => {
    dispatch({ type: REMOVE_UUID });
}

export const updateCartTotal = total => dispatch => {
    dispatch({ type: CART_TOTAL, payload: total });
}

export const storeCodeState = (code, state) => dispatch => {
    dispatch({ type: CODE_STATE, payload: {code, state} });
}

export const resetCodeState = () => dispatch => {
    dispatch({ type: RESET_CODE_STATE });
}

export const authSuccess = authData => dispatch => {
    dispatch({ type: AUTH_SUCCESS, payload: authData });
}

export const authFail = () => dispatch => {
    dispatch({ type: AUTH_FAIL });
}

export const getOrderCount = total => dispatch => {
    dispatch({ type: ORDER_COUNT, payload: total });
}

export const getAnnouncementUnreadCount = total => dispatch => {
    dispatch({ type: ANNOUNCE_UNREAD_COUNT, payload: total });
}