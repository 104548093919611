import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { useTheme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Typography, Link, Grid, TextField, Box, LinearProgress, Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';

export default function DailySales() {
    const [tableData, setTableData] = useState([]);
    const [wallet, setWallet] = useState([]);
    const [merchantLists, setMerchantLists] = useState({});
    const [statusDisplayList, setStatusDisplayList] = useState({});
    const [statusLists, setStatusLists] = useState({});
    const [dateFilter, setDateFilter] = useState({ sdate:'', edate:''});
    const [tableLoading, setTableLoading] = useState(false);
    const [searchTrigger, setSearchTrigger] = useState(1);

    const theme = useTheme();
    const { t,i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { isAdmin } = useSelector(state => state.general);

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [searchTrigger]);
   
    function callApi() {
        getUrl(`admin/get_daily_sales?merchantId=mall&status=all&sdate=${dateFilter.sdate}&edate=${dateFilter.edate}`).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data);
                setWallet(response.wallet);
                setStatusDisplayList(response.order_status);
                let statusLookup = {};
                _.map(response.order_status, (status,index) => {
                    statusLookup[status] = status;
                })
                console.log("statusLookup", statusLookup);
                setStatusLists(response.order_status);

                if(isAdmin){
                    let merchantLookup = {};
                    _.map(response.merchants, merchant => {
                        merchantLookup[merchant.shop_name] = `${merchant.shop_name} [ID: ${merchant.id}]`;
                    })
                    console.log("response.order_status", response.order_status);
                    console.log("merchantLookup", merchantLookup);
                    setMerchantLists(merchantLookup);
                }
            }
        }).catch((error) => {
            setTableLoading(false);
            if (isMountedRef.current) {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    const handleDateChange = event => {
        const { name, value } = event.target;
        setDateFilter(dateFilter => ({ ...dateFilter, [name]: value }));
    }
    let column_detail = [
            { title: t('order.orderNo'), filtering: false, field: 'order_no', exportField: true, render: rowData => <Link underline='none' to={`/orders/${rowData.order_no}`} component={RouterLink} style={{ color: theme.palette.primary.main }}>{rowData.order_no}</Link> },
            { title: t('reports.dailysales.buyerUsername'), filtering: false, field: 'buyer_username', exportField: true },
            { title: t('reports.dailysales.buyer'), filtering: false, field: 'buyer_name', exportField: true },
            { title: t('reports.dailysales.recipient'), filtering: false, field: 'recipient', hidden: true, exportField: true },
            { title: 'BV '+t('reports.bvOrder.recipientUsername'), filtering: false, field: 'bv_recipient_username', hidden: true, exportField: true },
            { title: t('reports.dailysales.mobile'), hidden: true, exportField: true, field: 'mobile', render: rowData => rowData.mobile ? rowData.mobile : '-' },
            { title: t('reports.dailysales.address'), hidden: true, exportField: true, field: 'address' },
            { title: t('reports.dailysales.zip'), hidden: true, exportField: true, field: 'zip' },
            { title: t('reports.dailysales.city'), hidden: true, exportField: true, render: rowData => rowData[`city_name_${i18n.language}`] === null ? rowData.city : rowData[`city_name_${i18n.language}`] },
            { title: t('reports.dailysales.state'), hidden: true, exportField: true, field: `state_name_${i18n.language}` },
            { title: t('reports.dailysales.country'), hidden: true, exportField: true, field: `country_name_${i18n.language}` },
            {
                title: t('reports.dailysales.productList'), filtering: false, field: `product_title_${i18n.language}`, exportField: true, sorting: false, render: rowData => {
                    if (_.size(rowData[`product_title_${i18n.language}`]) > 0) {
                            let product_list = rowData[`product_title_${i18n.language}`].split('&');
                                return (
                                    <>
                                         {
                                            _.map(product_list, (products, index) => {
                                                return <Typography noWrap key={index} style={{ width: '7vw' }}>-{products}</Typography >
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                    return '-'
                                    }
                },
                { title: t('reports.dailysales.productParentSku'), hidden: true, field: 'product_parent_sku', exportField: true, sorting: false,  render: rowData => rowData.product_detail ? JSON.parse(rowData.product_detail).product_type : '-'},
                { title: t('reports.dailysales.productSku'), hidden: true, field: 'product_sku', exportField: true, sorting: false },
                { title: t('reports.dailysales.variation'), hidden: true, field: 'variation', exportField: true, sorting: false },
                { title: t('reports.dailysales.productPrice'), hidden: true, field: 'product_price', exportField: true, sorting: false },
                { title: t('reports.dailysales.quantity'), hidden: true, field: 'product_quantity_order', exportField: true, sorting: false },
                { title: t('reports.dailysales.totalProductInOrder'), hidden: true, exportField: true, field: 'total_product' },
                { title: t('reports.dailysales.totalShippingWeight'), hidden: true, exportField: true, field: 'total_ship_weight' },
                { title: t('reports.dailysales.remark'), hidden: true, exportField: true, field: 'remark', render: rowData => rowData.remark ? rowData.remark : '-' },
                { title: t('reports.dailysales.trackingNo'), hidden: true, exportField: true, field: 'tracking_no', render: rowData => rowData.tracking_no ? rowData.tracking_no : '-' },
                { title: t('reports.dailysales.order_amount'), filtering: false, exportField: true, field: 'order_amount' },
                { title: t('reports.dailysales.shippingChannel'), hidden: true, exportField: true, field: 'channel', render: rowData => rowData.channel ? rowData.channel : '-' },
                { title: t('reports.dailysales.shippingCourier'), hidden: true, exportField: true, field: 'courier', render: rowData => rowData.courier ? rowData.courier : '-' },
                { title: t('reports.dailysales.originalShippingFee'), filtering: false, exportField: true, field: 'original_fee', render: rowData => rowData.original_fee ? rowData.original_fee : '-' },
                { title: t('reports.dailysales.shipping_fee'), hidden: true, exportField: true, field: 'shipping_fee' },
                { title: t('reports.dailysales.total'), filtering: false, exportField: true, field: 'order_total' },
                { title: t('reports.dailysales.serviceFee'), hidden: true, exportField: true, field: 'service_fee', render: rowData => <NumberFormat value={rowData.claim_amount != 0 ? rowData.order_total - rowData.claim_amount : 0} decimalScale={2} displayType={'text'} thousandSeparator={true} /> },
                { title: t('reports.dailysales.nett'), hidden: true,filtering: false, exportField: true, field: 'nett' },
                { title: t('reports.dailysales.orderClaim'), filtering: false, exportField: true, field: 'claim_amount' },
                { title: t('reports.dailysales.orderReward'), hidden: true, exportField: true, field: 'reward_amount' },
                { title: t('reports.dailysales.seller'), filtering: true, field: 'shop_name', lookup: merchantLists, hidden: isAdmin ? false : true },
                { title: t('reports.dailysales.status'), filtering: true, exportField: true, field: 'status', render: rowData => statusDisplayList[rowData.status], lookup: statusLists},
                {
                    title: t('reports.dailysales.returnStatus'), hidden: true, field: 'return_status', exportField: true, render: rowData => {
                        if (rowData.status_date) {
                             const statusObj = JSON.parse(rowData.status_date);
                                return <p>{rowData.status === '100' ? format(new Date(statusObj.Refunded), 'yyyy-MM-dd')
                                    : rowData.status === '10' ? format(new Date(statusObj.Returned), 'yyyy-MM-dd') : '-'
                                }</p>
                                }
                                return '-'
                                }
                            },
                { title: t('reports.dailysales.create_date'), filtering: false, exportField: true, field: 'created_at' },
                { title: t('reports.dailysales.update_date'), filtering: false, exportField: true, field: 'updated_at' },
    ]
    _.map(wallet,(wallet,index)=>{
        let wallet_name = wallet.name.split('|');
        let wallet_code = wallet.code.toLowerCase();
        if(wallet.code == 'VP'){
            column_detail.splice(29,0, { title: i18n.language =='cn'?  wallet_name[1]:wallet_name[0], hidden: true, exportField: true, field: wallet_code, render: rowData => <NumberFormat value={rowData[`${t('wallet_code')}`] != 0 ?rowData[`${t('wallet_code')}`] : 0} decimalScale={2} displayType={'text'} thousandSeparator={true} />});
        }
        
    });
    return (
        <Grid container spacing={3} style={{marginTop:12}}>
            {/* <ThemeProvider theme={tableStyle} > */}
                <Grid item xs={12}>
                    <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                        <Typography variant="h4" component="h1">{t('reports.dailysales.title')}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    { tableLoading ? <LinearProgress/>
                    : <ThemeProvider theme={tableStyle} >
                        <MaterialTable
                            title=""
                            columns={
                                column_detail
                            }
                            data={tableData}
                            options={{
                                filtering: true,
                                pageSize: 10,
                                exportButton: {
                                    csv: true,
                                    pdf: false
                                },
                                exportFileName: `DailySalesReport_${new Date().toISOString().slice(0, 10)}`,
                                exportCsv: (columns, data) => {
                                    const exportColumns = _.filter(columns, { exportField: true });
                                    const columnTitles = exportColumns.map(columnDef => columnDef.title);
                                    const csvData = data.map(rowData =>
                                        // exportColumns.map(columnDef => rowData[columnDef.field]),
                                        exportColumns.map(columnDef => {
                                            if (columnDef.field === 'status') { return statusDisplayList[rowData.status]; }
                                            if (columnDef.field === 'return_status') {
                                                const statusObj = JSON.parse(rowData.status_date);
                                                return rowData.status === 100 ? format(new Date(statusObj.Refunded), 'yyyy-MM-dd')
                                                    : rowData.status === 10 ? format(new Date(statusObj.Returned), 'yyyy-MM-dd') : '-';

                                            }
                                            if (columnDef.field === 'service_fee') {
                                                return rowData.claim_amount != 0 ? rowData.order_total - rowData.claim_amount : 0;
                                            }
                                            if (columnDef.field === 'claim_amount') {
                                                return rowData.claim_amount != 0 ? (rowData.vp != null ? rowData.claim_amount - rowData.vp - rowData.vpt- rowData.ap- rowData.mp- rowData.cp: rowData.claim_amount ) : 0;
                                            }
                                            if (columnDef.field === 'vp') {
                                                return rowData.vp != null ? rowData.vp  : 0;
                                            }
                                            if (columnDef.field === 'vpt') {
                                                return rowData.vpt != null ? rowData.vpt  : 0;
                                            }
                                            if (columnDef.field === 'mp') {
                                                return rowData.mp != null ? rowData.mp  : 0;
                                            }
                                            if (columnDef.field === 'ap') {
                                                return rowData.ap != null ? rowData.ap  : 0;
                                            }
                                            if (columnDef.field === 'sp') {
                                                return rowData.sp != null ? rowData.sp  : 0;
                                            }
                                            if (columnDef.field === 'cp') {
                                                return rowData.cp != null ? rowData.cp  : 0;
                                            }
                                            if (columnDef.field === 'nett') {
                                                return rowData.claim_amount != 0 ? rowData.shipping_fee?rowData.claim_amount-rowData.shipping_fee:rowData.claim_amount: 0;
                                            }
                                            if (columnDef.field === 'product_parent_sku') {
                                                return rowData.product_detail ? JSON.parse(rowData.product_detail).product_type : '-';
                                            }
                                            // return rowData[columnDef.field]
                                            return columnDef.field ? rowData[columnDef.field] : columnDef.render(rowData);
                                        }),
                                    );

                                    const builder = new CsvBuilder(`DailySalesReport_${new Date().toISOString().slice(0, 10)}.csv`)
                                        .setColumns(columnTitles)
                                        .addRows(csvData)
                                        .exportFile();
                                    return builder;
                                },
                                headerStyle: {
                                    backgroundColor: theme.palette.primary.main,
                                    color: '#fff',
                                },

                            }}
                            style={{ ...theme.box1, backgroundColor: 'transparent', boxShadow: 'none' }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: t('table.labelDisplayedRows'),
                                    labelRowsSelect: t('table.rows'),
                                    firstTooltip: t('table.firstPage'),
                                    previousTooltip: t('table.previousPage'),
                                    nextTooltip: t('table.nextPage'),
                                    lastTooltip: t('table.lastPage'),
                                },
                                toolbar: {
                                    searchTooltip: t('table.search'),
                                    searchPlaceholder: t('table.search'),
                                    exportTitle: t('table.export'),
                                    // exportPDFName: t('table.exportPDF'),
                                    exportCSVName: t('table.exportCSV')
                                    // nRowsSelected: t('table.rowsSelected')
                                },
                                body: {
                                    emptyDataSourceMessage: t('table.noRecord'),
                                }
                            }}
                            components={{
                                Toolbar: props => (
                                    <Box padding={2}>
                                        <MTableToolbar {...props} />
                                        <Grid container>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <TextField
                                                    id="datetime-local"
                                                    label={t('reports.dailysales.from')}
                                                    type="date"
                                                    value={dateFilter.sdate}
                                                    name="sdate"
                                                    onChange={handleDateChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <TextField
                                                    id="datetime-local"
                                                    label={t('reports.dailysales.to')}
                                                    type="date"
                                                    value={dateFilter.edate}
                                                    name="edate"
                                                    onChange={handleDateChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <Box display="flex" height="100%" paddingY={1} justifyContent="center" alignItems="center">
                                                    <Button variant="contained" style={{ backgroundColor: theme.palette.primary.main, color: '#fff' }} onClick={() => setSearchTrigger(searchTrigger + 1)}>
                                                        Filter Date
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ),
                            }}
                        /></ThemeProvider>
                    }
                </Grid>
            {/* </ThemeProvider> */}
        </Grid>
    );
}

const tableStyle = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiTableCell: {
        // Name of the rule
        // head: {
        //     color:'#fff',
        //     backgroundColor:'#18267C !important',
            
        //     },
        root: {
            borderBottom:'none'
        }
        },
        MuiTableHead:{
            root:{
                boxShadow:'2px 3px 9px 0 #a4abd0',
            }
        },
        MuiTable:{
            root:{
                backgroundColor:'#fff'
            }
        },
        MuiInput:{
            underline:{
                '&:after': {
                    borderBottom: "2px solid #18267C",
                  },
            }
        }
      },
});



// import React, { useEffect, useState, useRef } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';

// import _ from 'lodash';
// import MaterialTable, { MTableToolbar } from 'material-table';
// import { getUrl, putUrl, postUrl } from '@utils/ApiAction';
// import useNotificationLoading from '@utils/useNotificationLoading';

// import { useTheme, makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
// import { Typography, Link, Grid, Button, TextField, Hidden, List, ListItem, ListItemText, RootRef, Box, InputLabel, Select, MenuItem, LinearProgress, FormControl } from '@material-ui/core';
// import { format } from 'date-fns';
// import { CsvBuilder } from 'filefy';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import NumberFormat from 'react-number-format';
// import { color } from 'd3';

// export default function DailySales() {
//     const classes = useStyles();
//     const theme = useTheme();

//     const { t,i18n } = useTranslation();
//     const isMountedRef = useRef(null);
//     const { addAlert, setLoading } = useNotificationLoading();
//     const [tableData, setTableData] = useState([]);
//     const { permissions, role, isAdmin } = useSelector(state => state.general);
//     const [merchantList, setMerchantList] = useState([]);
//     const [statusList, setStatusList] = useState([]);
//     const [merchantFilter, setMerchantFilter] = useState('mall');
//     const [statusFilter, setStatusFilter] = useState('25');
//     const [dateFilter, setDateFilter] = useState({ sdate:'', edate:''});
//     const [tableLoading, setTableLoading] = useState(false);
//     const [currency, setCurrency]=useState();

//     function callApi(){
//         getUrl(`admin/get_daily_sales?merchantId=${merchantFilter}&sdate=${dateFilter.sdate}&edate=${dateFilter.edate}&status=${statusFilter}`).then(response => {
//             setTableLoading(false);
//             if (response.status === 1 && isMountedRef.current) {
//                 setTableData(response.data);
//                 setStatusList(response.order_status);
//                 setCurrency(response.currency_data);
//                 if(isAdmin){
//                     setMerchantList(response.merchants);
//                 }
//             }
//         }).catch((error) => {
//             setTableLoading(false);
//             if (isMountedRef.current) {
//                 let msg = error + "\n" + t('error.contactSupport');
//                 addAlert('', msg, 'error', '');
//             }
//         })
//     }

//     useEffect(() => {
//         isMountedRef.current = true;
//         setTableLoading(true);
//         callApi();
//         return () => { isMountedRef.current = false };
//         // eslint-disable-next-line
//     }, [merchantFilter, dateFilter, statusFilter]);

//     // const downloadExcel=()=>{
//     //     const worksheet=XLSX.utils.json_to_sheet(tableData)
//     //     const workBook=XLSX.utils.book_new()
//     //     XLSX.utils.book_append_sheet(workBook,worksheet,"report")
//     //     let buf=XLSX.write(workBook,{bookType:"xlsx",type:"buffer"})
//     //     XLSX.writeFile(workBook,"report.xlsx")
//     // }

//     const handleChangeSDateRange=(data)=>{
//         setDateFilter({...dateFilter,sdate:data});
//     }

//     const handleChangeEDateRange=(value)=>{
//         if(dateFilter.sdate){
//             setDateFilter({...dateFilter,edate:value});
//         }else{
//             addAlert('',t('reports.dailysales.insertEdateErr'), 'error', '');
//         }
//     }

//     return (
//         <Grid container spacing={3} style={{marginTop:12}}>
//             <ThemeProvider theme={tableStyle} >
//             <Grid item xs={12}>
//                 <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
//                     <Typography variant="h4" component="h1">{t('reports.dailysales.title')}</Typography>
//                 </Grid>
//                 {/* <Divider /> */}
                
//             </Grid>
//             <Grid item xs={12} >
//                 {
//                 tableLoading
//                 ?
//                 <LinearProgress />
//                 :
//                 <MaterialTable
//                     title=""
//                     columns={[
//                         { title: t('order.orderNo'), field: 'order_no',exportField: true, render: rowData => <Link underline='none' to={`/orders/${rowData.order_no}`} component={RouterLink}>{rowData.order_no}</Link> },
//                         { title: t('reports.dailysales.buyer'), field: 'buyer_name',exportField: true },
//                         { title: t('reports.dailysales.recipient'), field: 'recipient',hidden:true,exportField: true },
//                         { title: t('reports.dailysales.mobile'),hidden:true,exportField: true,field:'mobile', render:rowData =>rowData.mobile? rowData.mobile :'-' },
//                         { title: t('reports.dailysales.address'),hidden:true,exportField: true, field: 'address' },
//                         { title: t('reports.dailysales.zip'),hidden:true, exportField: true,field: 'zip' },
//                         { title: t('reports.dailysales.city'),hidden:true,exportField: true,render:rowData =>rowData[`city_name_${i18n.language}`] ===null? rowData.city :rowData[`city_name_${i18n.language}`]},
//                         { title: t('reports.dailysales.state'),hidden:true,exportField: true, field:`state_name_${i18n.language}` },
//                         { title: t('reports.dailysales.country'), hidden:true,exportField: true,field:`country_name_${i18n.language}`},
//                         { title:t('reports.dailysales.productList'),field:`product_title_${i18n.language}`,exportField:true,sorting:false,render:rowData=>{
//                             if(_.size(rowData[`product_title_${i18n.language}`])>0){
//                                 let product_list=rowData[`product_title_${i18n.language}`].split('&');
//                                 return(
//                                     <>
//                                         {
//                                             _.map(product_list,(products,index)=>{
//                                                 return <Typography noWrap key={index} style={{width:'7vw'}}>-{products}</Typography >
//                                             })
//                                         }
//                                     </>
//                                 )
//                             }
//                             return '-'
//                         }},
//                         { title:t('reports.dailysales.productParentSku'),hidden:true,field:'product_parent_sku',exportField:true,sorting:false},
//                         { title:t('reports.dailysales.productSku'),hidden:true,field:'product_sku',exportField:true,sorting:false},
//                         { title:t('reports.dailysales.variation'),hidden:true,field:'variation',exportField:true,sorting:false},
//                         { title:t('reports.dailysales.cashBack'),hidden:true,field:'total_cashback',exportField:true},
//                         { title:t('reports.dailysales.productPrice'),hidden:true,field:'product_price',exportField:true,sorting:false},
//                         { title:t('reports.dailysales.quantity'),hidden:true,field:'product_quantity_order',exportField:true,sorting:false},
//                         { title: t('reports.dailysales.totalProductInOrder'), hidden:true, exportField: true,field: 'total_product' },
//                         { title: t('reports.dailysales.totalShippingWeight'), hidden:true, exportField: true,field: 'total_ship_weight' },
//                         { title: t('reports.dailysales.remark'),hidden:true, exportField: true,field:'remark',render:rowData => rowData.remark? rowData.remark:'-' },
//                         { title: t('reports.dailysales.trackingNo'),hidden:true,exportField: true, field:'tracking_no',render:rowData => rowData.tracking_no? rowData.tracking_no:'-' },
//                         { title: t('reports.dailysales.order_amount'),exportField: true, field: 'order_amount' },
//                         { title: t('reports.dailysales.shippingChannel'),hidden:true,exportField: true, field:'channel', render:rowData => rowData.channel? rowData.channel:'-' },
//                         { title: t('reports.dailysales.shippingCourier'),hidden:true,exportField: true, field:'courier', render:rowData => rowData.courier? rowData.courier:'-' },
//                         { title: t('reports.dailysales.originalShippingFee'),exportField: true, field:'original_fee',render:rowData => rowData.original_fee? rowData.original_fee:'-' },
//                         { title: t('reports.dailysales.shipping_fee'),hidden:true,exportField: true, field: 'shipping_fee' },
//                         { title: t('reports.dailysales.total'),exportField: true, field: 'order_total' },
//                         { title: t('reports.dailysales.serviceFee'),hidden:true,exportField: true, field: 'service_fee',render:rowData=><NumberFormat value={rowData.claim_amount !=0?rowData.order_total-rowData.claim_amount:0} decimalScale={2} displayType={'text'} thousandSeparator={true} /> },
//                         { title: t('reports.dailysales.orderClaim'), exportField: true,field: 'claim_amount' },
//                         { title: t('reports.dailysales.orderReward'),hidden:true,exportField: true,field: 'reward_amount' },
//                         { title: t('reports.dailysales.seller'), field: 'shop_name' },
//                         { title: t('reports.dailysales.status'),exportField: true,field:'status', render: rowData => statusList[rowData.status] },
//                         { title: t('reports.dailysales.returnStatus'),hidden:true,field:'return_status',exportField: true, render:rowData=>{
//                             if(rowData.status_date){
//                                 const statusObj=JSON.parse(rowData.status_date);
//                                 return <p>{rowData.status === '100' ? format(new Date(statusObj.Refunded),'yyyy-MM-dd')
//                                 :rowData.status === '10' ? format(new Date(statusObj.Returned),'yyyy-MM-dd') :'-'
//                                 }</p>
//                             }
//                             return '-'
//                         } },
//                         { title: t('reports.dailysales.create_date'), exportField: true,field: 'created_at' },
//                         { title: t('reports.dailysales.update_date'), exportField: true,field: 'updated_at' }
//                     ]}
//                     data={tableData}
//                     options={{
//                         pageSize: 10,
//                         exportButton: {
//                             csv: true,
//                             pdf: false
//                         },
//                         exportFileName: `DailySalesReport_${new Date().toISOString().slice(0, 10)}`,
//                         exportCsv: (columns, data) => {
//                             const exportColumns = _.filter(columns, { exportField: true });
//                             const columnTitles = exportColumns.map(columnDef => columnDef.title);
//                             const csvData = data.map(rowData =>
//                                 // exportColumns.map(columnDef => rowData[columnDef.field]),
//                                 exportColumns.map(columnDef=>{
//                                     if(columnDef.field === 'status')
//                                     { return statusList[rowData.status]; }
//                                     if(columnDef.field === 'return_status')
//                                     { 
//                                         const statusObj=JSON.parse(rowData.status_date);
//                                         return rowData.status === 100 ? format(new Date(statusObj.Refunded),'yyyy-MM-dd')
//                                         :rowData.status === 10 ? format(new Date(statusObj.Returned),'yyyy-MM-dd') :'-';
                                        
//                                     }
//                                     if(columnDef.field ==='service_fee'){
//                                         return rowData.claim_amount !=0?rowData.order_total-rowData.claim_amount:0;
//                                     }
//                                     // return rowData[columnDef.field]
//                                     return columnDef.field ? rowData[columnDef.field] : columnDef.render(rowData);
//                                 }),
//                             );
                          
//                             const builder = new CsvBuilder(`DailySalesReport_${new Date().toISOString().slice(0, 10)}.csv`)
//                             .setColumns(columnTitles)
//                             .addRows(csvData)
//                             .exportFile();
//                             return builder;
//                         },
//                         // selection: _.includes(permissions, "collection_delete") ? true : false,
//                     }}
//                     /* actions={_.includes(permissions, "collection_delete") ? [
//                         {
//                             tooltip: t('collection.activateSelectedCollection'),
//                             icon: () => <RotateLeftIcon />,
//                             onClick: (evt, data) => alertChangeStatus(evt, data, 'active')
//                         },
//                         {
//                             tooltip: t('collection.inactiveSelectedCollection'),
//                             icon: 'block',
//                             onClick: (evt, data) => alertChangeStatus(evt, data, 'inactive')
//                         },
//                         {
//                             tooltip: t('collection.deleteSelectedCollection'),
//                             icon: 'delete',
//                             onClick: (evt, data) => alertChangeStatus(evt, data, 'delete')
//                         }
//                     ] : null} */
//                     // actions={[
//                     //     {
//                     //         icon:()=><SaveAltIcon />,
//                     //         isFreeAction:true,
//                     //         tooltip:t('table.exportExcel'),
//                     //         onClick:()=>downloadExcel(),
//                     //     }
//                     // ]}
//                     style={{ ...theme.box1, backgroundColor:'transparent', boxShadow:'none' }}
//                     localization={{
//                         pagination: {
//                             labelDisplayedRows: t('table.labelDisplayedRows'),
//                             labelRowsSelect: t('table.rows'),
//                             firstTooltip: t('table.firstPage'),
//                             previousTooltip: t('table.previousPage'),
//                             nextTooltip: t('table.nextPage'),
//                             lastTooltip: t('table.lastPage'),
//                         },
//                         toolbar: {
//                             searchTooltip: t('table.search'),
//                             searchPlaceholder: t('table.search'),
//                             exportTitle: t('table.export'),
//                             // exportPDFName: t('table.exportPDF'),
//                             exportCSVName: t('table.exportCSV')
//                             // nRowsSelected: t('table.rowsSelected')
//                         },
//                         body: {
//                             emptyDataSourceMessage: t('table.noRecord'),
//                         }
//                     }}
//                     components={{
//                         Toolbar: props => (
//                             <div style={{ width:'100%', backgroundColor:'#fff', boxShadow:'2px 3px 20px 0 #dee0ec', borderRadius:18, padding:'5px 20px',margin:'30px 0'}} >
//                                 <Hidden smUp>
                                
//                                 <MTableToolbar {...props} />
//                                 </Hidden>
//                                 <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                                
//                                 <Grid container spacing={3}>
//                                     <Grid item xs={12} sm={4} md={2}>
//                                     {   
//                                         <TextField
//                                             id="datetime-local"
//                                             label={t('reports.dailysales.from')}
//                                             type="date"
//                                             // defaultValue="2020-01-01T00:00"
//                                             // value={ state.sdate ? state.sdate : "2020-01-01T00:00" }
//                                             value={dateFilter.sdate}
//                                             onChange={ ({target}) => handleChangeSDateRange(target.value) }
//                                             // onChange={({ target }) => setState({ ...state, sdate: target.value })}
//                                             InputLabelProps={{ shrink: true }}
//                                             style={{padding:5}}
//                                         />
//                                     }
//                                     {   
//                                         <TextField
//                                             id="datetime-local"
//                                             label={t('reports.dailysales.to')}
//                                             type="date"
//                                             fullWidth
//                                             // defaultValue="2020-01-01T00:00"
//                                             // value={ state.sdate ? state.sdate : "2020-01-01T00:00" }
//                                             value={dateFilter.edate}
//                                             onChange={  ({target}) => handleChangeEDateRange(target.value) }
//                                             // onChange={({ target }) => setState({ ...state, sdate: target.value })}
//                                             InputLabelProps={{ shrink: true }}
//                                             style={{marginRight:25}}
//                                         />
//                                     }
//                                     </Grid>
//                                     <Grid item xs={12} sm={4} md={2}>
//                                     {
//                                         isAdmin ?
//                                                 <FormControl fullWidth>
//                                                 <InputLabel>{t('reports.dailysales.seller')}</InputLabel>
//                                                 <Select                                            
//                                                     value={merchantFilter}
//                                                     onChange={ ({target}) => setMerchantFilter(target.value) }
//                                                     style={{ minWidth: 100, textAlign: 'center' }}
//                                                 >
//                                                     <MenuItem value={'mall'}>{t('reports.dailysales.all')}</MenuItem>
//                                                     {
//                                                         _.map(merchantList, merchantItem => {
//                                                             return <MenuItem key={merchantItem.id} value={merchantItem.id}>{` ${merchantItem.shop_name} [ID: ${merchantItem.id}] `}</MenuItem>
//                                                         })
//                                                     }
//                                                 </Select>
//                                                 </FormControl>
//                                         : null
//                                     }
//                                     </Grid>
//                                     <Grid item xs={12} sm={4} md={2}>
//                                     {
//                                         <FormControl fullWidth>
//                                         <InputLabel>{t('reports.dailysales.status')}</InputLabel>
//                                         <Select
//                                             value={statusFilter}
//                                             onChange={ ({target}) => setStatusFilter(target.value) }
//                                             style={{ minWidth: 100, textAlign: 'center' }}
//                                         >
//                                             <MenuItem value='all'>{t('reports.dailysales.all')}</MenuItem>
//                                             {
//                                                 Object.keys(statusList).map((keyname, key) => {
//                                                     return <MenuItem key={keyname} value={keyname}>{` ${statusList[keyname]}`}</MenuItem>
//                                                 })
//                                             }
//                                         </Select>
//                                         </FormControl>
//                                     }
//                                     </Grid>
//                                 </Grid>
//                                 <Hidden only={'xs'}>
//                                 <MTableToolbar {...props} />
//                                 </Hidden>
//                                 </Box>
//                             </div>
//                         ),
//                     }}
//                 />
//                 }
//             </Grid>
//             </ThemeProvider>
//         </Grid>
//     );
// }

// const useStyles = makeStyles((theme) => ({
//     modal: {
//         // position: 'absolute',
//         // width: 'fit-content',
//         height: '95vh',
//         backgroundColor: theme.palette.background.paper,
//         border: '2px solid #000',
//         boxShadow: theme.shadows[5],
//         // left: 0,
//         // right: 0,
//         // top: 0,
//         // bottom: 0,
//         marginTop: 'auto',
//         overflow: 'auto',
//     },
// }));

// const tableStyle = createMuiTheme({
//     overrides: {
//       // Style sheet name ⚛️
//       MuiTableCell: {
//         // Name of the rule
//         head: {
//             color:'#fff',
//             backgroundColor:'#332a7c!important',
            
//             },
//         root: {
//             borderBottom:'none'
//         }
//         },
//         MuiTableHead:{
//             root:{
//                 boxShadow:'2px 3px 9px 0 #a4abd0',
//             }
//         },
//         MuiTable:{
//             root:{
//                 backgroundColor:'#fff'
//             }
//         }
//       },
//   });
