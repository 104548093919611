import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { CardHeader, CardContent, TextField, InputAdornment,Hidden, IconButton, Button, Typography, Container, Grid, Link, Box, Divider, Card } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { BACKEND_URL, CLIENT_SECRET, EXTERNAL_LOGIN } from '@configs/Config';
import { authSuccess, authFail, storeCodeState } from '@actions';
import { generateRandomString } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import i18next from 'i18next';

export default function Login() {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();

    let history = useHistory();

    const styles = useStyles();
    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });

    useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]);

    const loginUser = () => {
        setLoading(true);
        console.log(state);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        console.log("loginstate", loginState);
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            // setLoading(false);
            setLoading(false);
            console.log("result", response);
            if(response.status === 200) {
                dispatch(authSuccess(response.data));
                history.goBack();
            }            
        }).catch((error) => {
            console.log("error", error);
            setLoading(false);
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
                // console.log('The username or password is incorrect');
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
                // console.log('Please try again later or contact Technical Support if problem persist.');
            }            
            dispatch(authFail());
            // setLoading(false);            
        }); 
    }

    const loginUserThirdParty = platform => {
        const generatedLoginState = generateRandomString(40);
        const generatedCodeVerifier = generateRandomString(128);

        dispatch(storeCodeState(generatedCodeVerifier, generatedLoginState));
    }

    return (
        <Box style={{height:'100%', backgroundColor:'#fff' }}>
            <Box>
                <Box style={{height:'100vh', display:'flex', justifyContent:'space-around', alignItems:'center',padding:' 0 6%'}}>
                    <Box style={{width:600, maxWidth:'100%'}}>
                        <Box style={{padding:3% 0}}>
                            <Hidden mdUp>
                                <img src="/images/logo.png" style={{ width: '130px', padding: '5px 0px 20px', }} containerStyle={{ margin: '3% 0' }} alt="logo" />
                                <center><img src="/images/maintenance.png" style={{ width: '170px', maxWidth: '100%', }} alt="maintenance" /></center>
                                <Typography style={{ fontSize: 30, textAlign:'center', lineHeight:1.0, paddingTop:30 }}><b>MAINTENANCE</b></Typography>
                                <Typography style={{ fontSize: 30, textAlign:'center' }}>IN PROGRESS</Typography>
                                <Typography style={{ fontSize: 14, width:'400px', textAlign:'center', maxWidth:'100%', margin:'0 auto'  }}>Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.</Typography>
                            </Hidden>
                            <Hidden smDown>
                                <img src="/images/logo.png" style={{ width: '196px', padding: '5px 0px 20px' }} containerStyle={{ margin: '3% 0' }} alt="logo" />
                                
                                <Typography style={{ fontSize: 66 }}><b>MAINTENANCE</b></Typography>
                                <Typography style={{ fontSize: 66 }}>IN PROGRESS</Typography>
                                <Typography style={{ fontSize: 21 }}>Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.</Typography>
                            </Hidden>
                           
                        </Box>
                        
                    </Box>
                    <Hidden smDown>
                        <img src="/images/maintenance.png" style={{ width: '500px', maxWidth: '100%', paddingRight:'6%' }} alt="maintenance" />
                    </Hidden>
                </Box>
            </Box>
        </Box>

    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    }
}));