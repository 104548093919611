import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams} from 'react-router-dom';

import _ from 'lodash';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, useTheme, Divider, Checkbox, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Box, RadioGroup, Radio } from '@material-ui/core';
import { List, ListItem, ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import HomeIcon from '@material-ui/icons/Home';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function OrderEdit() {
    const classes = useStyles();
    const theme = useTheme();

    let newDate = new Date();
    let today = newDate.getFullYear() + '-' + ((newDate.getMonth()+1)<10?'0'+(newDate.getMonth()+1):(newDate.getMonth()+1)) + '-' + (newDate.getDate()<10?'0'+newDate.getDate():newDate.getDate());

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [trackingOpen, setTrackingOpen] = useState(false);
    const [editTrackingOpen, setEditTrackingOpen] = useState(false);
    const [reminderOpen, setReminderOpen] = useState(false);
    const [epOpen, setEpOpen] = useState(false);
    const [trackingDetailsOpen, setTrackingDetailsOpen] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [pickupOpen, setPickupOpen] = useState(false);

    const [state, setState] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [trackingDetails, setTrackingDetails] = useState([]);
    const [cancelDetails, setCancelDetails] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState({
        'self': [],
        'mall': [],
        'source': [],
        'selfPickup': [],
    });
    const [logistic, setLogistic] = useState('');
    const [trackingNo, setTrackingNo] = useState('');
    const [trackingStatus, setTrackingStatus] = useState([]);
    const [trackingInfo, setTrackingInfo] = useState({
        trackingID: '',
        trackingNo: '',
        trackingStatus: '',
    });
    const [rate, setRate] = useState({
        pickupDate: today,
        courierName: '',
        deliveryTime:'',
        deliveryFee: '',
        serviceName: '',
        serviceId: '',
        // balance:'',
        showDetails: false,
    });
    const [reminder, setReminder] = useState({
        type: '',
        trackingID: '',
    });
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });
    const [refundDialog, setRefundDialog] = useState({
        open: false,
        refundType: '',
        interestType: '',
        interestValue: '',
        walletId: ''
    });
    const refundType = [{code: "normal", label: t('order.refundType.normal')}, {code: "special", label: t('order.refundType.special')}];
    const interestType = [{code: "percent", label: t('order.interestType.percent')}, {code: "value", label: t('order.interestType.value')}];
    const [wallets, setWallets] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [newOrderStatus, setNewOrderStatus] = useState();
    const { permissions } = useSelector(state => state.general);
    let { id } = useParams();
    const [shippingPrefix, setShippingPrefix] = useState('');
    const [orderClaimList, setOrderClaimList] = useState([]);
    
    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/logistic/update_tracking_payment_parcel_status/${id}`).then(response => {
            if(!(response.status === 1 && isMountedRef.current)) {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/orders/${id}`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setState(response.data);
                setOrderDetails(response.order_details_list.logistic?response.order_details_list.logistic:'');
                setTrackingDetails(response.order_details_list.tracking?response.order_details_list.tracking:'');
                setCancelDetails(response.order_details_list.cancel?response.order_details_list.cancel:'');
                setTrackingStatus(response.tracking_status);
                setCurrencyData(response.currency_data);
                setOrderStatus(response.order_status);
                setNewOrderStatus(response.data.status);
                setShippingPrefix(response.shipping_prefix);
                setOrderClaimList(response.order_claim_list);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl('admin/wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert, refreshData]);

    const handleCheckboxChange = (event) => {
        let temporaryDetails = [];
        if(event.target.name === 'self'){
            temporaryDetails = selectedOrderDetails.self;
        }else if(event.target.name === 'mall'){
            temporaryDetails = selectedOrderDetails.mall;
        }else if(event.target.name === 'source'){
            temporaryDetails = selectedOrderDetails.source;
        }else if(event.target.name === 'selfPickup'){
            temporaryDetails = selectedOrderDetails.selfPickup;
        }
        if(event.target.checked){
            let filteredArray = temporaryDetails.concat(event.target.value);
            setSelectedOrderDetails({...selectedOrderDetails, [event.target.name]: filteredArray});
        }else{
            let filteredArray = temporaryDetails.filter(item => item !== event.target.value);
            setSelectedOrderDetails({...selectedOrderDetails, [event.target.name]: filteredArray});
        }
    };
    const handleTrackingPopupOpen = (event) => {
        setTrackingOpen(true);
        setLogistic(event.name);
    };
    const handleTrackingPopupClose = () => {
        setTrackingNo('');
        setTrackingOpen(false);
        setLogistic('');
    };
    const handleEditTrackingPopupOpen = (event) => {
        setEditTrackingOpen(true);
        setTrackingInfo({
            trackingID: event.trackingID,
            trackingNo: event.trackingNo,
            trackingStatus: event.trackingStatus,
        });
    };
    const handleEditTrackingPopupClose = () => {
        setEditTrackingOpen(false);
        setTrackingInfo({
            trackingID: '',
            trackingNo: '',
            trackingStatus: 'none',
        });
    };
    const handleReminderPopupOpen = (event) => {
        setReminderOpen(true);
        setReminder({type: event.type, trackingID: event.trackingID});
        setLogistic(event.name);
    };
    const handleReminderPopupClose = () => {
        setReminderOpen(false);
        setReminder({type: '', trackingID: ''});
        setLogistic('');
        setCancelOrderDialog({ ...cancelOrderDialog , remark: '' })
    };
    const handleEpPopupOpen = (event) => {
        setEpOpen(true);
        setLogistic(event.name);
    };
    const handleEpPopupClose = () => {
        setEpOpen(false);
        setLogistic('');
        setRate({
            pickupDate: today,
            courierName: '',
            deliveryTime:'',
            deliveryFee: '',
            serviceName: '',
            serviceId: '',
            // balance: '',
            showDetails: false,
        })
    };
    const handleTrackingDetailsClick = (trackingId) => {
        setTrackingDetailsOpen({ ...trackingDetailsOpen, [trackingId]: !trackingDetailsOpen[trackingId]});
    }
    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }
    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }
    const handleRefundDialogOpen = () => {
        setRefundDialog({
            open: true,
            refundType: 'normal',
            interestType: '',
            interestValue: '',
            walletId: ''
        });
    }
    const handleRefundDialogClose = () => {
        setRefundDialog({
            open: false,
            refundType: '',
            interestType: '',
            interestValue: '',
            walletId: ''
        });
    }

    const printInfo = () => {
        var content = document.getElementById("ifmcontentstoprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const calculateTotalQuantity =(orders, trackings)=>{
        let total_quantity =0;

        if(_.size(orders)>0){
            _.map(orders,source_list=>{
                _.map(source_list.data_list,listing=>{
                    total_quantity +=listing.quantity;
                })
            })
            
        }
        
        if(_.size(trackings)>0){
            _.map(trackings, list=>{
                _.map(list.data_list,list_data=>{
                    total_quantity +=list_data.quantity;
                })
            })
        }
        return total_quantity? total_quantity:0;
    }

    const calculateTotalAmount =(orders, trackings)=>{
        let total_amount = 0;

        if(_.size(orders)>0){
            
            _.map(orders,source_list=>{
                total_amount +=source_list.total_amount;
            })
        }
        
        if(_.size(trackings)>0){
            _.map(trackings, list=>{
                total_amount +=list.total_amount;
            })
        }

        let total_amount_display =`${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en}`+` ${parseFloat(total_amount).toFixed(2)}`;
        return total_amount_display;
    }

    const addressDisplay =(orders,trackings)=>{
        let show_selfPickup =false;
        if(orders.selfPickup){
           show_selfPickup =true;
        }
        if(_.size(trackings)>0){
            _.map(trackings, list=>{
                _.map(list.data_list,list_data=>{
                    if(list_data.shipping_channel == 'selfPickup'){
                        show_selfPickup =true;
                    }
                })
            })
        }

        if(show_selfPickup){
            return <b>{t('logistic.selfPickup')}</b>;
        }else{
            return state.full_address;
        }
    }



    // switch return function
    const get_order_action_button = (shipping_channel) => {
        switch(shipping_channel){
            case 'self' : return <Grid item xs={12} style={{ marginTop: 10}}>
                                    <Button size="medium" variant="outlined" onClick={ ({ event }) => handleTrackingPopupOpen({ name: shipping_channel }) }>
                                        {t('order.addTrackingNo')}
                                    </Button>
                                </Grid>;
            case 'mall' : return <Grid item xs={12} style={{ marginTop: 10}}>
                                    <Button size="medium" variant="outlined" onClick={ ({ event }) => handleEpPopupOpen({ name: shipping_channel }) }>
                                        {t('order.readyToShip')}
                                    </Button>
                                </Grid>;
            case 'selfPickup' : return <Grid item xs={12} style={{ marginTop: 10}}>
                                    <Button size="medium" variant="outlined" onClick={ ({ event }) => handlePickupOpen({ name: shipping_channel }) }>
                                        {t('order.confirmPickup')}
                                    </Button>
                                </Grid>;
            default :  return null;
        }
    }
    const get_logistic_order_status_button = (logistic_order_status, logistic_parcel_status, trackingID) => {
        switch(logistic_order_status.replace(/\s+/g, '').toLowerCase()){
            case 'waitingpayment' :
            case 'partialpayment' : return <Grid item xs={12} style={{ marginTop: 10}}>
                                                <Button size="medium" variant="outlined" onClick={ ({ event }) => handleReminderPopupOpen({ name: '', type: 'remakePayment', trackingID: trackingID }) } >
                                                    {t('order.remakeOrderPayment')}
                                                </Button>
                                            </Grid>;
            case 'cancel' : return <Grid item xs={12} style={{ marginTop: 10}}>
                                        <Button size="medium" variant="outlined" onClick={ ({ event }) => handleReminderPopupOpen({ name: '', type: 'deleteTracking', trackingID: trackingID }) }>
                                            {t('order.deleteTracking')}
                                        </Button>
                                    </Grid>;
            case 'paid' : return (logistic_parcel_status!=null)?get_logistic_parcel_status_button(logistic_parcel_status, trackingID):null;
            default :  return null;
        }
    }
    const get_logistic_parcel_status_button = (logistic_parcel_status, trackingID) => {
        switch(logistic_parcel_status.replace(/\s+/g, '').toLowerCase()){
            case 'cancel' : 
            case 'returned' : 
            case 'expiredunpaid' :
            case 'cancelbyadmin' : return <Grid item xs={12} style={{ marginTop: 10}}>
                                                <Button size="medium" variant="outlined" onClick={ ({ event }) => handleReminderPopupOpen({ name: '', type: 'deleteTracking', trackingID: trackingID }) }>
                                                    {t('order.deleteTracking')}
                                                </Button>
                                            </Grid>;
            default : return null;
        }
    }
    const reminderDialogContent = () => {
        switch(reminder.type){
            // case 'cancel' : return <DialogContent dividers>
            //                             <Grid container spacing={1} justify="center" >
            //                                 <Grid item xs={12}>
            //                                     {t('dialog.reminderContent', {action: t('order.cancelProduct')})}
            //                                 </Grid>
            //                                 <Grid item xs={12}>
            //                                     <TextField
            //                                         fullWidth={true} 
            //                                         variant="outlined" 
            //                                         label={t('order.cancelOrderRemark')}
            //                                         multiline
            //                                         value={ cancelOrderDialog.remark }
            //                                         onChange={ (event) => { setCancelOrderDialog({ ...cancelOrderDialog , remark: event.target.value }) }}
            //                                     />
            //                                 </Grid>
            //                             </Grid>
            //                         </DialogContent>;
            case 'remakePayment' : return <DialogContent dividers>
                                                {t('dialog.reminderContent', {action: t('order.remakeOrderPayment')})}
                                            </DialogContent>;
            case 'deleteTracking' : return <DialogContent dividers>
                                                {t('dialog.reminderContent', {action: t('order.deleteTracking')})}
                                            </DialogContent>;
            default : return null;
        }
    }
    const reminderDialogActions = () => {
        switch(reminder.type){
            // case 'cancel' : return <DialogActions>
            //                             <Button autoFocus onClick={cancel_product} color="primary">
            //                                 {t('button.yesPlease')}
            //                             </Button>
            //                         </DialogActions>;
            case 'remakePayment' : return <DialogActions>
                                                <Button autoFocus color="primary" onClick={ ({ event }) => remake_order_payment({ trackingID: reminder.trackingID }) }>
                                                    {t('order.remakeOrderPayment')}
                                                </Button>
                                            </DialogActions>;
            case 'deleteTracking' : return <DialogActions>
                                                <Button autoFocus color="primary" onClick={ ({ event }) => deleteTracking({ trackingID: reminder.trackingID }) }>
                                                    {t('order.deleteTracking')}
                                                </Button>
                                            </DialogActions>;
            default : return null;
        }
    }

    const add_tracking = () => {
        setLoading(true);
        setTrackingOpen(false);
        let temporaryDetails = [];
        if(logistic === 'self'){
            temporaryDetails = selectedOrderDetails.self;
        }else if(logistic === 'mall'){
            temporaryDetails = selectedOrderDetails.mall;
        }else if(logistic === 'source'){
            temporaryDetails = selectedOrderDetails.source;
        }
        const updateData = {
            order_details_list: temporaryDetails,
            tracking_no: trackingNo,
            channel: logistic,
        };
        postUrl(`admin/orders/${id}/tracking`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                setTrackingNo('');
                setRefreshData(!refreshData);
                setSelectedOrderDetails({...selectedOrderDetails, [logistic]: []});
            }else{
                setTrackingOpen(true);
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            setTrackingOpen(true);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };
    const edit_tracking = () => {
        setLoading(true);
        setEditTrackingOpen(false);
        const updateData = {
            tracking_id: trackingInfo.trackingID,
            // tracking_no: trackingInfo.trackingNo,
            tracking_status: trackingInfo.trackingStatus,
        };
        putUrl(`admin/orders/${id}/tracking`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                setRefreshData(!refreshData);
            }else{
                setEditTrackingOpen(true);
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            setEditTrackingOpen(true);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };
    const cancel_order = () => {
        setLoading(true);
        setCancelOrderDialog({ ...cancelOrderDialog, open: false });
        const updateData = {
            remark: cancelOrderDialog.remark
        };
        postUrl(`admin/orders/${cancelOrderDialog.order_no}/cancel_order`, updateData)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert('', t('order.cancelSuccess'), 'success', '');
                setRefreshData(!refreshData);
                handleCancelOrderClose();
            }else{
                setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch(error => {
            setCancelOrderDialog({ ...cancelOrderDialog, open: true });
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    // const cancel_product = () => {
    //     setLoading(true);
    //     setReminderOpen(false);
    //     let temporaryDetails = [];
    //     if(logistic === 'self'){
    //         temporaryDetails = selectedOrderDetails.self;
    //     }else if(logistic === 'mall'){
    //         temporaryDetails = selectedOrderDetails.mall;
    //     }else if(logistic === 'source'){
    //         temporaryDetails = selectedOrderDetails.source;
    //     }else if(logistic === 'selfPickup'){
    //         temporaryDetails = selectedOrderDetails.selfPickup;
    //     }
    //     const updateData = {
    //         cancel_list: temporaryDetails,
    //         remark: cancelOrderDialog.remark,
    //     };
    //     setCancelOrderDialog({ ...cancelOrderDialog , remark: '' })
    //     putUrl(`admin/orders/${id}/delete_order_details`, updateData).then(response => {
    //         setLoading(false);
    //         if(response.status === 1) {
    //             let msg = t('snackbarMsg.updateSuccess');
    //             addAlert('', msg, 'success', '');
    //             setRefreshData(!refreshData);
    //             setSelectedOrderDetails({...selectedOrderDetails, [logistic]: []});
    //         }else{
    //             if(response.data){
    //                 let msg = response.data;
    //                 addAlert('', msg, 'error', '');
    //             }else{
    //                 let msg = t('snackbarMsg.updateError');
    //                 addAlert('', msg, 'error', '');
    //             }
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         let msg = error+"\n"+t('error.contactSupport');
    //         addAlert('', msg, 'error', '');
    //     });
    // };
    const deleteTracking = (event) => {
        setLoading(true);
        setInputErrors('');
        setReminderOpen(false);
        const updateData = {
            tracking_id: event.trackingID,
        };
        putUrl(`admin/orders/${id}/tracking/delete`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
            setRefreshData(!refreshData);
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    // Logistic
    const pre_make_order = () => {
        setLoading(true);
        setInputErrors('');
        setEpOpen(false);
        let temporaryDetails = [];
        if(logistic === 'self'){
            temporaryDetails = selectedOrderDetails.self;
        }else if(logistic === 'mall'){
            temporaryDetails = selectedOrderDetails.mall;
        }else if(logistic === 'source'){
            temporaryDetails = selectedOrderDetails.source;
        };
        const updateData = {
            pickup_date: rate.pickupDate,
            order_details_list: temporaryDetails,
        };
        postUrl(`admin/logistic/pre_make_order/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                setEpOpen(true);
                setRate({
                    pickupDate: response.rate.pickup_date,
                    courierName: response.rate.courier_name,
                    deliveryTime: response.rate.delivery,
                    deliveryFee: response.rate.price,
                    serviceName: response.rate.service_name,
                    serviceId: response.rate.service_id,
                    showDetails: true,
                    // balance: response.merchant_balance,
                    orderInfo: response.order_info,
                });
            }else{
                setEpOpen(true);
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    const make_order = () => {
        setLoading(true);
        setInputErrors('');
        setEpOpen(false);
        let temporaryDetails = [];
        if(logistic === 'self'){
            temporaryDetails = selectedOrderDetails.self;
        }else if(logistic === 'mall'){
            temporaryDetails = selectedOrderDetails.mall;
        }else if(logistic === 'source'){
            temporaryDetails = selectedOrderDetails.source;
        };
        const updateData = {
            service_id: rate.serviceId,
            pickup_date: rate.pickupDate,
            order_info: rate.orderInfo,
            order_details_list: temporaryDetails,
        };
        setRate({
            pickupDate: today,
            courierName: '',
            deliveryTime: '',
            deliveryFee: '',
            serviceName: '',
            serviceId: '',
            showDetails: false,
        });
        postUrl(`admin/logistic/make_order/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                setSelectedOrderDetails({...selectedOrderDetails, [logistic]: []});
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
            setRefreshData(!refreshData);
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }
    const remake_order_payment = (event) => {
        setLoading(true);
        setInputErrors('');
        setReminderOpen(false);
        const updateData = {
            order_tracking_id: event.trackingID,
        };
        postUrl(`admin/logistic/remake_order_payment/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
            setRefreshData(!refreshData);
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const multiPricingDisplay = (list_row) => {
        if(_.size(list_row.payment) > 0){
            return (
            <TableCell>
            {
                _.map(list_row.payment, (paymentItem, paymentIndex) => {
                    if(parseFloat(paymentItem) > 0){
                        let prefix = '';
                        let prefix2 = '';
                        let walletUsed = '';
                        let decimal = 2;
                        if(paymentIndex === 'multi_wallet'){
                            JSON.parse(list_row.multi_pricing)
                            let multiP = JSON.parse(list_row.multi_pricing);
                            let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                            prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];

                            if(multiP.multi_wallet_detail.wallet_used){
                                _.map(multiP.multi_wallet_detail.wallet_used, (amount, wid) => {
                                    _.map(wallets, wallet => {
                                        if(parseInt(wallet.id) === parseInt(wid)){
                                            let name = _.split(wallet.name, '|');
                                            prefix2 = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                            walletUsed += (walletUsed ? (' + '+prefix2+" "+parseFloat(amount).toFixed(wallet.decimal)) : (prefix2+" "+parseFloat(amount).toFixed(wallet.decimal)));
                                            prefix2 = '';
                                        }
                                    })
                                })
                            }
                        }else if(paymentIndex === 'cash'){
                            prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                        }else{
                            _.map(wallets, wallet => {
                                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                                    let name = _.split(wallet.name, '|');
                                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                    decimal = wallet.decimal;
                                }
                            })
                        }
                        return (
                            <Box key={paymentIndex}>
                                <Typography variant="body2">
                                    {prefix? (prefix + ' ' + parseFloat(paymentItem).toFixed(decimal)) : parseFloat(paymentItem).toFixed(decimal)}
                                </Typography>
                                {
                                    walletUsed ?
                                    <Typography variant="body2">
                                        {'( '+walletUsed+' )'}
                                    </Typography>
                                    : null
                                }
                            </Box>
                        )
                        
                    }
                })
            }
            {/* {
                list_row.multi_pricing ?
                <Box style={{border:"1px solid #808080", borderRadius:"5px", padding:"5px"}}>
                    <Typography variant="caption">
                        {t('product.price') + ': '}
                        {i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en}
                        { ' ' + ((list_row.price_mod > 0) ? list_row.quantity*(list_row.price_mod) : list_row.quantity*(list_row.price)) }
                    </Typography>
                </Box>
                : null
            } */}
            </TableCell>
            )
        }else{
            return (
                <TableCell>-</TableCell>
            )
        }
    }
    const multiPricingSubAmount = (subtotalPrice, totalAmount) => {
        if(_.size(subtotalPrice) > 0){
            let multi = false;
            return (
                <TableCell>
                {
                    _.map(subtotalPrice, (paymentItem, paymentIndex) => {
                        if(parseInt(paymentItem) > 0){
                            let prefix = '';
                            let decimal = 2;
                            if(paymentIndex === 'cash'){
                                prefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
                            }else if(!isNaN(paymentIndex)){
                                multi = true;
                                _.map(wallets, wallet => {
                                    if(parseInt(wallet.id) === parseInt(paymentIndex)){
                                        let name = _.split(wallet.name, '|');
                                        prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                        decimal = wallet.decimal;
                                    }
                                })
                            }else{
                                prefix = paymentIndex;
                            }
                            return (
                                <Box key={paymentIndex}>
                                    <Typography variant="body2">
                                        {prefix? (prefix + ' ' + parseFloat(paymentItem).toFixed(decimal)) : parseFloat(paymentItem).toFixed(decimal)}
                                    </Typography>
                                </Box>
                            )
                        }
                    })
                }
                {/* {
                    multi ?
                    <Box style={{border:"1px solid #808080", borderRadius:"5px", padding:"5px"}}>
                        <Typography variant="caption">
                            {t('product.price') + ': '}
                            {i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en}
                            {' ' + totalAmount}
                        </Typography>
                    </Box>
                    : null
                } */}
                </TableCell>
            )
        }else{
            return (
                <TableCell>{parseFloat(totalAmount).toFixed(2)}</TableCell>
            )
        }
    }
    const orderDetailsTable = (type, row, key='') => {
        let subtotalPrice = {};
        return(
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                type === 'logistic'
                                ?
                                <TableCell></TableCell>
                                :
                                null
                            }
                            <TableCell>{t('product.productName')}</TableCell>
                            <TableCell>{t('product.sku')}</TableCell>
                            <TableCell>{t('product.options')}</TableCell>
                            <TableCell>{t('product.quantity')}</TableCell>
                            {/* <TableCell>{t('product.price')}</TableCell> */}
                            <TableCell>{t('order.sellPrice')}</TableCell>
                            <TableCell>{t('order.userPayment')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            _.map(row.data_list, (list_row) => {
                            let title_translate = _.split(list_row.title, '|');
                            if(_.size(list_row.payment) > 0) {
                                _.map(list_row.payment, (paymentItem, paymentIndex) => {
                                    if(paymentIndex === "multi_wallet"){
                                        let multiP = JSON.parse(list_row.multi_pricing);
                                        if(multiP.multi_wallet_detail.wallet_used){
                                            _.map(multiP.multi_wallet_detail.wallet_used, (amount, wid) => {
                                                if(_.isUndefined(subtotalPrice[wid])) {
                                                    subtotalPrice[wid] = 0;
                                                }
                                                subtotalPrice[wid] += parseFloat(amount);
                                            })
                                        }else{
                                            let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                                            paymentIndex = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                            if(_.isUndefined(subtotalPrice[paymentIndex])) {
                                                subtotalPrice[paymentIndex] = 0;
                                            }
                                            subtotalPrice[paymentIndex] += parseFloat(paymentItem);
                                        }
                                    }else{
                                        if(_.isUndefined(subtotalPrice[paymentIndex])) {
                                            subtotalPrice[paymentIndex] = 0;
                                        }
                                        subtotalPrice[paymentIndex] += parseFloat(paymentItem);
                                    }
                                })
                            }
                            return(
                                <TableRow key={list_row.id}>
                                    {
                                        type === 'logistic'
                                        ?
                                        <TableCell component="th" scope="row">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        key={list_row.id}
                                                        value={list_row.id}
                                                        onChange={({ target }) => handleCheckboxChange({ target }) }
                                                        name={key}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                    <TableCell>{ i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }</TableCell>
                                    <TableCell>{ list_row.sku }</TableCell>
                                    <TableCell>
                                        { list_row.product_attribute_display.color } 
                                        { (list_row.product_attribute_display.color&&list_row.product_attribute_display.size)?',':'' } 
                                        { list_row.product_attribute_display.size } 
                                        { ((list_row.product_attribute_display.color||list_row.product_attribute_display.size)&&list_row.product_attribute_display.attribute_value)?',':'' }
                                        { list_row.product_attribute_display.attribute_value }
                                    </TableCell>
                                    <TableCell>{ list_row.quantity }</TableCell>
                                    <TableCell>{ (i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) + ' ' + ((list_row.price_mod > 0) ? parseFloat(list_row.quantity*(list_row.price_mod)).toFixed(2) : parseFloat(list_row.quantity*(list_row.price)).toFixed(2)) }</TableCell>
                                    {multiPricingDisplay(list_row)}
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            {
                                type === 'logistic'
                                ?
                                <TableCell></TableCell>
                                :
                                null
                            }
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            {/* <TableCell></TableCell> */}
                            <TableCell>{t('order.orderTotal')}</TableCell>
                            <TableCell>{(i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) + ' ' + parseFloat(row.total_amount).toFixed(2)}</TableCell>
                            {multiPricingSubAmount(subtotalPrice, row.total_amount)}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    const displayShippingFee = () => {
        let name = _.split(shippingPrefix, '|');
        let prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
        return (
            _.map(state.order_shippings, (sdetails, key) => {
                if(sdetails.original_fee > 0){
                    return (
                        <>
                        <Grid item xs={6} md={3}>
                            <Typography> {t('logistic.' + sdetails.channel)}: </Typography>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <Typography>{prefix + ' ' + sdetails.original_fee}</Typography>
                        </Grid>
                        </>
                    )
                }
            })
        )
    }

    const updateOrderStatus = () => {
        let updateData = {
            status: newOrderStatus
        };
        putUrl(`admin/orders/${id}/status`, updateData).then(response => {
            if (response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                setRefreshData(!refreshData);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
    } 
    const refund_order = () => {
        setInputErrors('');
        let updateData = {
            refund_type: refundDialog.refundType,
            interest_type: refundDialog.interestType,
            interest_value: refundDialog.interestValue,
            wallet_id: refundDialog.walletId,
        };
        putUrl(`admin/orders/${id}/refund`, updateData).then(response => {
            if (response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                setRefreshData(!refreshData);
                handleRefundDialogClose();
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
    }

    // selfPickup
    const handlePickupOpen = (event) => {
        setPickupOpen(true);
        setLogistic(event.name);
    }
    const handlePickupClose = () => {
        setPickupOpen(false);
        setLogistic('');
    }
    const pickup_order = () => {
        setLoading(true);
        let temporaryDetails = [];
        if(logistic === 'selfPickup'){
            temporaryDetails = selectedOrderDetails.selfPickup;
        }
        const updateData = {
            order_details_list: temporaryDetails,
        };
        putUrl(`admin/orders/${id}/self_pickup`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                let msg = t('snackbarMsg.updateSuccess');
                addAlert('', msg, 'success', '');
                setRefreshData(!refreshData);
                setSelectedOrderDetails({...selectedOrderDetails, [logistic]: []});
                handlePickupClose();
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('order.editOrder')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/orders`} component={RouterLink}>{t('order.orders')}</Link>
                        <Typography style={{ color: 'black' }}>{t('order.editOrder')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <Grid container direction="row" style={theme.p20}>
                <Grid item xs={12} md={8} style={{margin: 5 }}>
                    <Grid item>
                        <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                            <Grid container direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                     {
                                        (state.status === 20 || state.status === 23 || state.status === 25) && (!trackingDetails)
                                        ?
                                        <Grid container direction="row" spacing={1}  justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="h5"> {t('order.orderNo')}: {state.order_no} </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Button variant="outlined" onClick={ () => handleCancelOrderOpen(state.order_no) }>
                                                    <Typography variant="caption">
                                                        { t('order.cancelOrder') }
                                                    </Typography>
                                                </Button>
                                                <IconButton aria-label="print" color="primary" onClick={printInfo}>
                                                    <PrintIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container direction="row" spacing={1}  justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="h5"> {t('order.orderNo')}: {state.order_no} </Typography>
                                            </Grid>
                                            {
                                                _.size(orderDetails) == 0 && _.size(trackingDetails) == 0 && _.size(cancelDetails) > 0 ?
                                                null :
                                                 <Grid item>
                                                    <IconButton aria-label="print" color="primary" onClick={printInfo}>
                                                        <PrintIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                                    <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute", display: "none" }}>
                                        <div style={{margin:20}}>
                                            <section style={{ display: 'grid' }}>
                                                <div style={{ gridColumn: 1, margin:10 }}>
                                                    <div>
                                                        <h3>{t('title.title')}</h3>
                                                        <p>{t('address.projectAddress')}</p>
                                                    </div>
                                                </div>
                                                <div style={{ gridColumn: 2, margin:10, padding:'30px 0' }}>
                                                    <div style={{ border: '2px solid #000000' }}>
                                                        <h4 style={{ margin: 20,fontSize:24 }}>{t('order.deliveryOrder')}</h4>
                                                    </div>
                                                </div>
                                            </section>
                                            <br />
                                            <section style={{ display: 'grid' }}>
                                                <div style={{ gridColumn: 1 }}>
                                                    <div style={{ border: '2px solid #000000' }}>
                                                        <div style={{ margin: 10 }}>
                                                            <p style={{margin:0,padding:0}}> {t('order.recipientName')}: <b>{state.recipient}</b> </p>
                                                            <p style={{margin:0,padding:0}}> {t('order.recipientMobile')}: {state.mobile ? state.mobile : '-'} </p>
                                                            <p style={{margin:0,padding:0}}> {t('order.recipientEmail')}: {state.recipient_email ? state.recipient_email : '-'} </p>
                                                            <p style={{margin:0,padding:0}}> {t('order.recipientAddress')}: {addressDisplay(orderDetails,trackingDetails)} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ gridColumn: 2, margin:10, paddingLeft:20 }}>
                                                    <div>
                                                        <p style={{margin:0,padding:0}}> {t('order.orderNo')}: {state.order_no} </p>
                                                        <p style={{margin:0,padding:0}}> {t('order.createdAt')}: {state.created_at} </p>
                                                    </div>
                                                </div>
                                            </section>
                                            <br/><br/>
                                            <table style={{ width: '100%', border: '1px solid #000000', tableLayout:'auto',borderCollapse:'collapse' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{border: '1px solid #000000',padding:10}}>{t('product.productName')}</th>
                                                        <th style={{border: '1px solid #000000',padding:10}}>{t('product.options')}</th>
                                                        <th style={{border: '1px solid #000000',padding:10}}>{t('product.quantity')}</th>
                                                        <th style={{border: '1px solid #000000',width:100,padding:10}}>{t('order.sellPrice')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ textAlign: 'center' }}>
                                                    {
                                                        _.size(orderDetails) > 0 ?
                                                            <>
                                                                {
                                                                    _.map(orderDetails, source => {
                                                                        return (
                                                                            _.map(source.data_list, (row, key) => {
                                                                                let title_translate = _.split(row.title, '|');
                                                                                return (
                                                                                    <tr key={key}>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>{i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}</td>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>
                                                                                            {row.product_attribute_display.color}
                                                                                            {(row.product_attribute_display.color && row.product_attribute_display.size) ? ',' : ''}
                                                                                            {row.product_attribute_display.size}
                                                                                            {((row.product_attribute_display.color || row.product_attribute_display.size) && row.product_attribute_display.attribute_value) ? ',' : ''}
                                                                                            {row.product_attribute_display.attribute_value}
                                                                                        </td>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>{row.quantity}</td>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>{(i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) + ' ' + ((row.price_mod > 0) ? parseFloat(row.quantity * (row.price_mod)).toFixed(2) : parseFloat(row.quantity * (row.price)).toFixed(2))}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        )
                                                                    })
                                                                }
                                                            </> : null
                                                    }
                                                    {
                                                        _.size(trackingDetails) > 0 ?
                                                            <>
                                                                {
                                                                    _.map(trackingDetails, (detail) => {
                                                                        return(
                                                                            _.map(detail.data_list, (row, key) => {
                                                                                let title_translate = _.split(row.title, '|');
                                                                                return (
                                                                                    <tr key={row.id}>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>{i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}</td>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>
                                                                                            {row.product_attribute_display.color}
                                                                                            {(row.product_attribute_display.color && row.product_attribute_display.size) ? ',' : ''}
                                                                                            {row.product_attribute_display.size}
                                                                                            {((row.product_attribute_display.color || row.product_attribute_display.size) && row.product_attribute_display.attribute_value) ? ',' : ''}
                                                                                            {row.product_attribute_display.attribute_value}
                                                                                        </td>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>{row.quantity}</td>
                                                                                        <td style={{ border: '1px solid #000000', padding: 10 }}>{(i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en) + ' ' + ((row.price_mod > 0) ? parseFloat(row.quantity * (row.price_mod)).toFixed(2) : parseFloat(row.quantity * (row.price)).toFixed(2))}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        )
                                                                    })
                                                                }
                                                            </> : null
                                                    }
                                                    {
                                                        _.size(orderDetails) == 0 && _.size(trackingDetails) == 0?
                                                        <tr><td colSpan={4} style={{border: '1px solid #000000',padding:10}}>{t('table.noRecord')}</td></tr>: null
                                                    }

                                                </tbody>
                                                <tfoot style={{ textAlign: 'center' }}>
                                                    <tr>
                                                        <td style={{border: '1px solid #000000', textAlign:'right',padding:10}} colSpan={2}>{t('order.orderTotal')}</td>
                                                        <td style={{border: '1px solid #000000',padding:10}}>{calculateTotalQuantity(orderDetails,trackingDetails)}</td>
                                                        <td style={{border: '1px solid #000000',padding:10}}>{calculateTotalAmount(orderDetails,trackingDetails)}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            <br/><br />
                                            <div style={{ float: 'right' }}>
                                                <div>
                                                    <p>{t('order.signatureDesc')}</p>
                                                    <br />
                                                    <p>_________________________</p>
                                                    <p>{t('order.name')} : </p>
                                                    <p>{t('order.date')} : </p>
                                                </div>
                                            </div>
                                        </div>
                                    </iframe>
                                <Grid item xs={12}>
                                    <Divider style={{margin: "10px 0px" }} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography> {t('order.status')}: </Typography>
                                </Grid>
                                { 
                                    _.includes(permissions, "order_status")
                                    ?
                                    <Grid item xs={6} md={9}>
                                        <TextField 
                                            variant="outlined" 
                                            fullWidth 
                                            select
                                            value={newOrderStatus}
                                            helperText={inputErrors.status?inputErrors.status:''}
                                            error={inputErrors.status?true:false}
                                            onChange={({ target }) => setNewOrderStatus(target.value)}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                        >
                                        {orderStatus.map((value, key) => {
                                            let name = _.split(value.desc, '|');
                                            return (
                                                <option key={key} value={value.value}>
                                                    {i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}
                                                </option>
                                            )
                                        })}
                                        </TextField>
                                        <Typography variant="caption" style={{color: "#808080", fontStyle: "italic"}}>{t('order.changeStatusReminder')}</Typography>
                                    </Grid>
                                    :
                                    <Grid item xs={6} md={9}>
                                        <Typography> {state.status_display} </Typography>
                                    </Grid>
                                }
                                <Grid item xs={6} md={3}>
                                    <Typography> {t('order.createdAt')}: </Typography>
                                </Grid>
                                <Grid item xs={6} md={9}>
                                    <Typography> {state.created_at} </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography> {t('order.remark')}: </Typography>
                                </Grid>
                                <Grid item xs={6} md={9} style={{wordBreak: 'break-word'}}>
                                    <Typography> {state.remark?state.remark:'-'} </Typography>
                                </Grid>
                                {displayShippingFee()}
                                {
                                    _.includes(permissions, "order_status")
                                    ?
                                    <Grid item xs={12} style={{ marginTop: 10}}>
                                        <Button variant="outlined" onClick={() => updateOrderStatus()}>{t('button.update')+' '+t('order.status')}</Button>
                                    </Grid>
                                    : null
                                }
                                {
                                    _.includes(permissions, "order_status") && state.status >= 25 && state.status < 50
                                    ?
                                    <Grid item xs={12} style={{ marginTop: 10}}>
                                        <Button variant="outlined" onClick={() => handleRefundDialogOpen()}>{t('order.refund')}</Button>
                                    </Grid>
                                    : null
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                    {/* logistic */}
                    {
                        orderDetails
                        ?
                        <Grid item>
                            {
                                _.map(orderDetails, (row, key) => {
                                return(
                                <Grid item key={key}>
                                    <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                                        <Grid container direction="row" style={theme.p20} >
                                            <Grid item xs={12}>
                                                <Typography variant="h5">{t('logistic.'+key)}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider style={{margin: "10px 0px" }} />
                                            </Grid>
                                                {
                                                     _.size(orderDetails.selfPickup) > 0 && key == "selfPickup" ?
                                                        _.map(orderDetails.selfPickup.data_list, (pickupItem, pickupIndex) => {
                                                            return (
                                                                <Box key={pickupIndex} style={{width:'100%', padding:5, border:'1px dashed black' , margin:10}}>
                                                                    <Grid item xs={12} style={{ margin: "2px" }}>
                                                                        <Typography  style={{ verticalAlign: 'middle' }} variant="button">{t('order.outletDetail',{'product':pickupItem.title})}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ margin: "2px" }}>
                                                                        <Typography style={{ verticalAlign: 'middle' }} > <PersonIcon fontSize="small" style={{ verticalAlign: 'middle' }} /> {pickupItem.outlet.pic_name} </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ margin: "2px" }}>
                                                                        <Typography style={{ verticalAlign: 'middle' }} > <PhoneIcon fontSize="small" style={{ verticalAlign: 'middle' }} /> {pickupItem.outlet.pic_mobile} </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ margin: "2px" }}>
                                                                        <Typography style={{ verticalAlign: 'middle' }} > <HomeIcon fontSize="small" style={{ verticalAlign: 'middle' }} /> {pickupItem.outlet.outlet_name} </Typography>
                                                                        <Typography style={{ verticalAlign: 'middle' }} > {pickupItem.outlet.address} </Typography>
                                                                    </Grid>
                                                                </Box>
                                                            )
                                                        })
                                                        : null
                                                }
                                            <Grid item xs={12} style={{ marginBottom: 10}}>
                                                {orderDetailsTable('logistic', row, key)}
                                            </Grid>
                                            {
                                                (state.status === 25 || state.status === 30)
                                                ?
                                                get_order_action_button(key)
                                                :
                                                null
                                            }
                                            {/* {
                                                (state.status < 50)
                                                ?
                                                <Grid item xs={12} style={{ marginTop: 10}}>
                                                    <Button size="medium" variant="outlined" onClick={ ({ event }) => handleReminderPopupOpen({ name: key, type: 'cancel', trackingID: '' }) } >{t('order.cancelProduct')}</Button>
                                                </Grid>
                                                :
                                                null
                                            } */}
                                        </Grid>
                                    </Paper>
                                </Grid>
                                );
                            })}
                        </Grid>
                        :
                        null
                    }
                    {/* tracking */}
                    {
                        trackingDetails
                        ?
                        <Grid item>
                            <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                                <Grid container direction="row" style={{padding: "20px 20px 0px 20px"}}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t('order.shippingDetail')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{margin: "10px 0px" }} />
                                    </Grid>
                                </Grid>
                                {
                                    _.map(trackingDetails, (row) => {
                                    return(
                                        <Grid container direction="row" style={theme.p20} key={row.tracking_data.id}>
                                            <Grid item sm={3} xs={12}>
                                                <Typography>{t('order.trackingNo')}:</Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12}>
                                                <Typography>{row.tracking_data.tracking_no}</Typography>
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                <Typography>{t('order.trackingStatus')}:</Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12}>
                                                <Typography>{row.tracking_data.status_display}</Typography>
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                <Typography>{t('order.trackingDetails')}:</Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12}>
                                                {
                                                    row.tracking_data.logistic_parcel_status
                                                    ?
                                                    <List
                                                        component="nav"
                                                        className={classes.root}
                                                        disablePadding
                                                    >
                                                        <ListItem disableGutters button onClick={() => handleTrackingDetailsClick(row.tracking_data.id)}>
                                                            <ListItemText primary={row.tracking_data.logistic_parcel_status} />
                                                            {trackingDetailsOpen[row.tracking_data.id] ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItem>
                                                        <Collapse in={trackingDetailsOpen[row.tracking_data.id]} timeout="auto" unmountOnExit>
                                                            <List component="div" disablePadding>
                                                                {
                                                                    _.map(row.tracking_data.tracking_details, (row, key) => {
                                                                    return (
                                                                        <ListItem key={key} className={classes.nested}>
                                                                            <ListItemIcon>
                                                                                <LocalShippingIcon fontSize="small" />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={row.status} secondary={row.event_date + ' ' + row.event_time} />
                                                                        </ListItem>
                                                                    )
                                                                })}
                                                            </List>
                                                        </Collapse>
                                                    </List>
                                                    :
                                                    <Typography>-</Typography>
                                                }
                                            </Grid>
                                            {/* <Grid item sm={9}>
                                                <Typography>{row.tracking_data.logistic_parcel_status?row.tracking_data.logistic_parcel_status:'-'}</Typography>
                                            </Grid> */}
                                            <Grid item sm={3} xs={12}>
                                                <Typography>{t('order.updatedAt')}:</Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12}>
                                                <Typography>{row.tracking_data.updated_at}</Typography>
                                            </Grid>
                                            {
                                                row.tracking_data.channel === 'mall'
                                                ?
                                                <Grid container direction="row">
                                                    <Grid item sm={3} xs={12}>
                                                        <Typography>{t('order.epOrderNo')}:</Typography>
                                                    </Grid>
                                                    <Grid item sm={9} xs={12}>
                                                        <Typography>{row.tracking_data.logistic_order_no?row.tracking_data.logistic_order_no:'-'}</Typography>
                                                    </Grid>
                                                    <Grid item sm={3} xs={12}>
                                                        <Typography>{t('order.epOrderStatus')}:</Typography>
                                                    </Grid>
                                                    <Grid item sm={9} xs={12}>
                                                        <Typography>{row.tracking_data.logistic_order_status?row.tracking_data.logistic_order_status:'-'}</Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                null
                                            }
                                            <Grid item xs={12} style={{ marginTop: 20}}>
                                                {orderDetailsTable('tracking', row)}
                                            </Grid>
                                            {
                                                row.tracking_data.channel === 'self'
                                                ?
                                                <Grid item xs={12} style={{ marginTop: 10}}>
                                                    <Button size="medium" variant="outlined" onClick={ ({ event }) => handleEditTrackingPopupOpen({ trackingID: row.tracking_data.id, trackingNo: row.tracking_data.tracking_no, trackingStatus: row.tracking_data.tracking_status }) }>{t('button.edit')}</Button>
                                                </Grid>
                                                :
                                                null
                                            }
                                            {
                                                row.tracking_data.logistic_order_status !== null
                                                ?
                                                get_logistic_order_status_button(row.tracking_data.logistic_order_status,row.tracking_data.logistic_parcel_status, row.tracking_data.id)
                                                :
                                                null
                                            }
                                            {
                                                row.tracking_data.awb_link !== null && row.tracking_data.awb_link !== ''
                                                ?
                                                <Grid item xs={12} style={{ marginTop: 10}}>
                                                    <Link href={row.tracking_data.awb_link} underline='none' target="_blank" rel="noopener">
                                                        <Button size="medium" variant="outlined"><GetAppIcon />{t('order.downloadAwb')}</Button>
                                                    </Link>
                                                </Grid>
                                                :
                                                null
                                            }
                                        </Grid>
                                    );
                                })}
                            </Paper>                                
                        </Grid>
                        :
                        null
                    }
                    {/* cancel */}
                    {
                        cancelDetails
                        ?
                        <Grid item>
                            <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                                <Grid container direction="row" style={theme.p20} >
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t('order.deletedProduct')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{margin: "10px 0px" }} />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: 10}}>
                                        {orderDetailsTable('cancel', cancelDetails)}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        :
                        null
                    }
                    <Dialog onClose={handleTrackingPopupClose} open={trackingOpen} fullWidth>
                        <DialogTitle onClose={handleTrackingPopupClose}>
                            {t('order.trackingNo')}
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField 
                                id="tracking_no" 
                                variant="outlined" 
                                fullWidth
                                value={trackingNo?trackingNo:''}
                                // helperText={inputErrors.state?inputErrors.state:''}
                                // error={inputErrors.state?true:false}
                                onChange={({ target }) => setTrackingNo(target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={add_tracking} color="primary">
                                {t('button.save')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={handleEditTrackingPopupClose} open={editTrackingOpen} fullWidth>
                        <DialogTitle onClose={handleEditTrackingPopupClose}>
                            {t('order.editTracking')}
                        </DialogTitle>
                        <DialogContent dividers>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={12}>
                                <TextField 
                                    id="tracking_no" 
                                    variant="outlined" 
                                    fullWidth
                                    label={t('order.trackingNo')}
                                    value={trackingInfo.trackingNo?trackingInfo.trackingNo:''}
                                    onChange={({ target }) => setTrackingInfo({ ...trackingInfo, trackingNo: target.value})}
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    id="tracking_status" 
                                    label={t('order.trackingStatus')}
                                    variant="outlined" 
                                    fullWidth 
                                    select
                                    value={trackingInfo.trackingStatus?trackingInfo.trackingStatus:''}
                                    onChange={({ target }) => setTrackingInfo({ ...trackingInfo, trackingStatus: target.value})}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                {trackingStatus.map((data, key) => {
                                    let status_translate = _.split(data.desc, '|');
                                    return(
                                        <option key={key} value={data.value}>
                                            { i18n.language === 'cn' ? (status_translate[1] ? status_translate[1] : status_translate[0]) : status_translate[0] }
                                        </option>
                                    );
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={edit_tracking} color="primary">
                                {t('button.save')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={handleReminderPopupClose} open={reminderOpen} fullWidth>
                        <DialogTitle onClose={handleReminderPopupClose}>
                            {t('dialog.reminderTitle')}
                        </DialogTitle>
                        {reminderDialogContent()}
                        {reminderDialogActions()}
                    </Dialog>
                    <Dialog onClose={handleEpPopupClose} open={epOpen} fullWidth>
                        <DialogTitle onClose={handleEpPopupClose}>
                            {t('logistic.mall')}
                        </DialogTitle>
                        <DialogContent dividers>
                        <Grid container spacing={3} direction="column">
                            {
                                rate.showDetails
                                ?
                                <Grid item xs={12}>
                                    <Typography>{t('easyParcel.courierName')}: {rate.courierName}</Typography>
                                    <Typography>{t('easyParcel.serviceName')}: {rate.serviceName}</Typography>
                                    <Typography>{t('easyParcel.pickupDate')}: {rate.pickupDate}</Typography>
                                    <Typography>{t('easyParcel.deliveryTime')}: {rate.deliveryTime}</Typography>
                                    <Typography>{t('easyParcel.deliveryFee')}: {rate.deliveryFee}</Typography>
                                    {/* <Typography>{t('easyParcel.accountBalance')}: {rate.balance}</Typography> */}
                                    {/* <Typography variant="subtitle1" color="primary">{t('order.shippingWarning')}</Typography> */}
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <TextField 
                                        id="ep_pick_date" 
                                        variant="outlined" 
                                        fullWidth
                                        type="date"
                                        label={t('order.pickupDate')}
                                        value={rate.pickupDate?rate.pickupDate:''}
                                        helperText={inputErrors.pickup_date?inputErrors.pickup_date:''}
                                        error={inputErrors.pickup_date?true:false}
                                        onChange={({ target }) => setRate({ ...rate, pickupDate: target.value})}
                                    />
                                </Grid>
                            }
                        </Grid>
                        </DialogContent>
                        <DialogActions>
                            {
                                rate.showDetails
                                ?
                                <Button autoFocus onClick={make_order} color="primary">
                                    {t('button.yesPlease')}
                                </Button>
                                :
                                <Button autoFocus onClick={pre_make_order} color="primary">
                                    {t('order.readyToShip')}
                                </Button>
                            }
                            
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={handleCancelOrderClose} open={ cancelOrderDialog.open } fullWidth>
                        <DialogTitle onClose={ handleCancelOrderClose }>
                            { t('order.cancelOrder') }
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={1} justify="center" >
                                <Grid item xs={12}>
                                    { t('order.cancelOrderReminder') }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true} 
                                        variant="outlined" 
                                        label={t('order.cancelOrderRemark')}
                                        multiline
                                        value={ cancelOrderDialog.remark }
                                        onChange={ (event) => { setCancelOrderDialog({ ...cancelOrderDialog , remark: event.target.value }) }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" className={ styles.buttonStyle } onClick={ () => cancel_order()} >
                                <Typography variant="overline">{ t('order.confirmCancelOrder') }</Typography>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={handleRefundDialogClose} open={ refundDialog.open } fullWidth>
                        <DialogTitle onClose={ handleRefundDialogClose }>
                            { t('order.refund') }
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography variant="h6">{t('order.refundNote')}</Typography>
                            {/* <Grid container spacing={1} justify="center">
                                <Grid item xs={12}>
                                    <Typography>{ t('order.refundType.title') }</Typography>
                                    {
                                        inputErrors.refund_type ?
                                        <Typography variant='caption' style={{color:"red"}}>
                                            {inputErrors.refund_type}
                                        </Typography>
                                        : null
                                    }
                                    <RadioGroup aria-label="refund type" name="refundType" value={refundDialog.refundType} onChange={(event) => { setRefundDialog({ ...refundDialog, refundType: event.target.value}) }}>
                                        {
                                            _.map(refundType, (value, key) => {
                                                return(
                                                    <FormControlLabel key={key} value={value.code} control={<Radio />} label={value.label} />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </Grid>
                                {
                                    refundDialog.refundType === 'special'
                                    ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography>{ t('order.interestType.title') }</Typography>
                                            {
                                                inputErrors.interest_type ?
                                                <Typography variant='caption' style={{color:"red"}}>
                                                    {inputErrors.interest_type}
                                                </Typography>
                                                : null
                                            }
                                            <RadioGroup aria-label="interest type" name="interestType" value={refundDialog.interestType} onChange={(event) => { setRefundDialog({ ...refundDialog, interestType: event.target.value}) }}>
                                                {
                                                    _.map(interestType, (value, key) => {
                                                        return(
                                                            <FormControlLabel key={key} value={value.code} control={<Radio />} label={value.label} />
                                                        )
                                                    })
                                                }
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                id="interestValue" 
                                                label={refundDialog.interestType==='percent'?t('order.interestRate'):t('order.interestValue')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={refundDialog.interestValue}
                                                helperText={inputErrors.interest_value ? inputErrors.interest_value :''}
                                                error={inputErrors.interest_value ? true : false}
                                                onChange={(event) => { setRefundDialog({ ...refundDialog, interestValue: event.target.value}) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                id="walletId" 
                                                label={t('wallet.walletType')}
                                                variant="outlined" 
                                                fullWidth 
                                                select
                                                value={refundDialog.walletId}
                                                helperText={inputErrors.wallet_id ? inputErrors.wallet_id :''}
                                                error={inputErrors.wallet_id ? true : false}
                                                onChange={(event) => { setRefundDialog({ ...refundDialog, walletId: event.target.value}) }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            <option key='' value='' disabled>
                                                {t('general.pleaseSelect')}
                                            </option>
                                            {wallets.map((value, key) => {
                                                let name = _.split(value.name, '|');
                                                return(
                                                    <option key={key} value={value.id}>
                                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                                    </option>
                                                )
                                            })}
                                            </TextField>
                                        </Grid>
                                    </>
                                    :
                                    null
                                }
                            </Grid> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" className={ styles.buttonStyle } onClick={ () => refund_order()} >
                                <Typography variant="overline">{ t('order.confirmRefund') }</Typography>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={handlePickupClose} open={ pickupOpen } fullWidth>
                        <DialogTitle onClose={ handlePickupClose }>
                            { t('logistic.selfPickup') }
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={1} justify="center">
                                <Grid item xs={12}>
                                    <Typography>{ t('order.confirmPickupNote') }</Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" className={ styles.buttonStyle } onClick={ () => pickup_order()} >
                                <Typography variant="overline">{ t('order.confirmPickup') }</Typography>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={12} md={3} style={{margin: 5 }}>
                    {
                        _.size(orderClaimList) > 0
                        ?
                        <Grid item>
                            <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                                <Grid container direction="row" style={theme.p20}>
                                    {
                                        _.map(orderClaimList, detail => {
                                            return (
                                                <>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5"> {detail.type_display[i18n.language]} </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{margin: "10px 0px" }} />
                                                </Grid>
                                                {
                                                    _.map(detail.transaction_details, tdetail => {
                                                        let name = _.split(tdetail.wallet_name, '|');
                                                        let prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                                        return (
                                                            <Grid item xs={12} style={{margin: "2px" }}>
                                                                <Typography>{prefix+' '+parseFloat(tdetail.amount).toFixed(2)}</Typography>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                </>
                                            )
                                        })
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        : null
                    }
                    <Grid item>
                        <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                            <Grid container direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant="h5"> {t('order.buyer')} </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider style={{margin: "10px 0px" }} />
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <PersonIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.buyer_name} </Typography>
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <PhoneIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.buyer_mobile} </Typography>
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <EmailOutlinedIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.buyer_email} </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paper} style={{...theme.box1, marginTop: 20}}>
                            <Grid container direction="row" style={theme.p20}>
                                <Grid item xs={12}>
                                    <Typography variant="h5"> {t('order.deliveryAddress')} </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider style={{margin: "10px 0px" }} />
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <PersonIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.recipient} </Typography>
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <PhoneIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.mobile} </Typography>
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <EmailOutlinedIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.recipient_email?state.recipient_email:'-'} </Typography>
                                </Grid>
                                <Grid item xs={12} style={{margin: "2px" }}>
                                    <Typography style={{verticalAlign: 'middle'}} > <HomeIcon fontSize="small" style={{verticalAlign: 'middle'}} /> {state.full_address} </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));