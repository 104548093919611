import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from '@utils/Tools';

import _ from 'lodash';
import { formatDate } from '@utils/Tools';
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles'
import { Typography, Link, Grid, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Checkbox, FormControlLabel, InputAdornment, Tooltip, Icon, Toolbar, FormControl, Input, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import MaterialTable from 'material-table';
// import AddIcon from '@material-ui/icons/Add';
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';

export const formatDateTime = (date) => {
    return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
};

function TableToolbar(props) {
    const classes = useStyles();
    const { t  } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function Members() {
    // const classes = useStyles();
    const theme = useTheme();
    const query = useQuery();
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableData, setTableData] = useState([]);
    // const { permissions } = useSelector(state => state.general);
    const [tableLoading, setTableLoading] = useState(false);

    const history = useHistory();
    const rowsPerPageOptiosArray = [10, 20, 30];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 20;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [totalCount, setTotalCount] = useState(0);

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/third-party/account-link${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order };
        getUrl(`admin/account_link`, params).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setTableData(response.data.data);
                // setOriginalData(response.data.data);
                setTotalCount(response.data.total);
                // setUserId(response.own_id);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        })
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, page, rowsPerPage, querySearch, orderBy, order]);

    useEffect(() => {
        setPage(1);
        setUrl(1, rowsPerPage, querySearch, orderBy, order);
    }, []);

    const handlePageChange = (page) => {
        setPage(page+1);
        setUrl(page+1, rowsPerPage, querySearch, orderBy, order);
    }
    const handleRowsPerPageChange = (pageSize) => {
        setRowsPerPage(pageSize);
        setPage(1);
        setUrl(1, pageSize, querySearch, orderBy, order);
    }
    const handleOrderChange = (orderedColumnId, orderDirection) => {
        let tempOrderColumn = '';
        let tempOrder = 'asc';
        switch(orderedColumnId){
            case 0: tempOrderColumn = 'id'; break;
            case 1: tempOrderColumn = 'linkable_id'; break;
            case 2: tempOrderColumn = 'linkable_type'; break;
            case 3: tempOrderColumn = 'source'; break;
            case 4: tempOrderColumn = 'source_user_id'; break;
            case 5: tempOrderColumn = 'created_at'; break;
            default: tempOrderColumn = '';
        }
        if(tempOrderColumn === orderBy){
            tempOrder = order === "asc" ? "desc" : "asc";
        }else{
            tempOrder = orderDirection;
        }
        setOrderBy(tempOrderColumn);
        setOrder(tempOrder);
        setPage(1);
        setUrl(1, rowsPerPage, querySearch, tempOrderColumn, tempOrder);
    }
    const handleSearchChange = (value) => {
        setQueryValue(value);
        setPage(1);
        setUrl(1, rowsPerPage, value, orderBy, order);
    }
    function handleSubmit(event) {
        event.preventDefault();        
        setUrl( searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.accountLink.title')}</Typography>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                { searchToolbar }
            </Grid>
            <Grid item xs={12}>
                {
                tableLoading
                ?
                <LinearProgress />
                :
                <MaterialTable
                    title="account_link"
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('reports.accountLink.userId'), field: 'linkable_id' },
                        { title: t('reports.accountLink.linkableType'), field: 'linkable_type' },
                        { title: t('reports.accountLink.source'), field: 'source' },
                        { title: t('reports.accountLink.sourceUserId'), field: 'source_user_id' },
                        { title: t('reports.accountLink.createdAt'), field: 'created_at', render: rowData => <p>{formatDateTime(rowData.created_at)}</p> }

                    ]}
                    data={tableData}
                    options={{
                        pageSize: rowsPerPage,
                        // selection: _.includes(permissions, "member_delete") ? true : false,
                        pageSizeOption  : rowsPerPageOptiosArray,
                        debounceInterval: 1000,
                        searchText: querySearch,
                        toolbar: false,
                        search: false,
                        selection: false,

                    }}
                    page={page-1}
                    totalCount={totalCount}
                    // actions={_.includes(permissions, "member_delete") ? [
                    //     {
                    //         tooltip: t('member.activateSelectedMember'),
                    //         icon: () => <RotateLeftIcon />,
                    //         onClick: (evt, data) => alertChangeStatus(evt, data, 'activate')
                    //     },
                    //     {
                    //         tooltip: t('member.suspendSelectedMember'),
                    //         icon: 'block',
                    //         onClick: (evt, data) => alertChangeStatus(evt, data, 'suspend')
                    //     },
                    //     {
                    //         tooltip: t('member.terminateSelectedMember'),
                    //         icon: 'delete',
                    //         onClick: (evt, data) => alertChangeStatus(evt, data, 'terminate')
                    //     }
                    // ] : null}
                    style={{ ...theme.box1, ...theme.p20 }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                            nRowsSelected: t('table.rowsSelected')
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                    onChangePage = {(page) => handlePageChange(page)}
                    onChangeRowsPerPage = {(pageSize) => handleRowsPerPageChange(pageSize)}
                    onOrderChange = {(orderedColumnId, orderDirection) => handleOrderChange(orderedColumnId, orderDirection)}
                    onSearchChange = {(value) => handleSearchChange(value)}
                />
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        // flexDirection: 'row-reverse'
    },
}));

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//     },
// }));