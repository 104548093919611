import _ from 'lodash';
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';

import { Box, Container, Link, Typography, makeStyles } from '@material-ui/core';

import { APP_NAME, PROJECT_TYPE } from '@configs/Config';
import HeaderSidebar from '@layouts/HeaderSidebar';
import NotificationLoading from '@layouts/NotificationLoading';
import NotificationLoadingProvider from '@providers/NotificationLoadingProvider';
import ScrollToTop from '@utils/ScrollToTop';

// ICONS
import { AccountBalanceWallet, AccountTree, Announcement, Assignment, AttachMoney, Beenhere, CardMembership, Category, Collections, Dashboard, Forum, Help, HowToReg, LibraryBooks, LocalAtm, MonetizationOn, Notifications, People, Person, PhotoLibrary, QuestionAnswer, RateReview, Settings, ShoppingCart, Sms, Storefront } from '@material-ui/icons';

// BASIC
import Profile from '@pages/Account/Profile';
import Login from '@pages/Auth/Login';
import LoginCatalog from '@pages/Auth/LoginCatalog';
import PasswordForgot from '@pages/Auth/PasswordForgot';
import PasswordReset from '@pages/Auth/PasswordReset';
import DashboardPage from '@pages/Dashboard/Dashboard';
import Maintenance from '@pages/Maintenance/Maintenance';

// ADMIN
import AdminAdd from '@pages/Admin/AdminAdd';
import AdminEdit from '@pages/Admin/AdminEdit';
import Admins from '@pages/Admin/Admins';
import Announcements from "@pages/Announcement/Announcements";
import AdminChat from "@pages/Chat/AdminChat";
import SellerAdd from '@pages/Company/SellerAdd';
import SellerEdit from '@pages/Company/SellerEdit';
import SellerRegister from '@pages/Company/SellerRegister';
import SellerTnc from '@pages/Company/SellerTnc';
import Sellers from '@pages/Company/Sellers';
import FaqAdd from '@pages/Faq/FaqAdd';
import FaqEdit from '@pages/Faq/FaqEdit';
import Faqs from '@pages/Faq/Faqs';
import LandingBanner from "@pages/LandingBanner/LandingBanner";
import MerchantHelpdesk from "@pages/Chat/MerchantHelpdesk";
import NotificationAdd from "@pages/Notification/NotificationAdd";
import NotificationEdit from "@pages/Notification/NotificationEdit";
import NotificationsList from "@pages/Notification/Notifications";
import WalletAddDeduct from "@pages/Wallet/WalletAddDeduct";
import Withdrawals from '@pages/Withdrawal/Withdrawals';
import WithdrawalsMember from "@pages/Withdrawal/WithdrawalsMember";

// SELLER
import Chat from '@pages/Chat/Chat';
import CollectionEdit from '@pages/Collection/CollectionEdit';
import CollectionsList from '@pages/Collection/Collections';
import MerchantAdd from '@pages/Merchant/MerchantAdd';
import MerchantEdit from '@pages/Merchant/MerchantEdit';
import Merchants from '@pages/Merchant/Merchants';
import OrderAdd from '@pages/Order/OrderAdd';
import OrderEdit from '@pages/Order/OrderEdit';
import Orders from '@pages/Order/Orders';
import OrdersAll from '@pages/Order/OrdersAll';
import ProductAdd from '@pages/Product/ProductAdd';
import ProductEdit from '@pages/Product/ProductEdit';
import ProductQuestions from '@pages/Product/ProductQuestions';
import Products from '@pages/Product/Products';
import ProductReviews from '@pages/Review/ProductReviews';
import ShopReviews from '@pages/Review/ShopReviews';

// MEMBER
import GenealogyPlacement from "@pages/Genealogy/GenealogyPlacement";
import GenealogyPlacement2 from "@pages/Genealogy/GenealogyPlacement2";
import SponsorGenealogy from "@pages/Genealogy/SponsorGenealogy";
import Uplines from "@pages/Genealogy/Uplines";
import MemberAdd from '@pages/Member/MemberAdd';
import MemberEdit from '@pages/Member/MemberEdit';
import Members from '@pages/Member/Members';

// SETTING
import BonusAdd from '@pages/Bonus/BonusAdd';
import BonusEdit from '@pages/Bonus/BonusEdit';
import Bonuses from '@pages/Bonus/Bonuses';
import Categories from '@pages/Category/Categories';
import CategoryAdd from '@pages/Category/CategoryAdd';
import CategoryEdit from '@pages/Category/CategoryEdit';
import Currency from '@pages/Currency/Currency';
import CurrencyAdd from '@pages/Currency/CurrencyAdd';
import CurrencyEdit from '@pages/Currency/CurrencyEdit';
import ParameterAdd from '@pages/Parameter/ParameterAdd';
import ParameterEdit from '@pages/Parameter/ParameterEdit';
import Parameters from '@pages/Parameter/Parameters';
import PermissionAdd from "@pages/Permission/PermissionAdd";
import PermissionEdit from "@pages/Permission/PermissionEdit";
import Permissions from "@pages/Permission/Permissions";
import RankAdd from "@pages/Rank/RankAdd";
import RankEdit from "@pages/Rank/RankEdit";
import Ranks from "@pages/Rank/Ranks";
import RoleAdd from "@pages/Role/RoleAdd";
import RoleEdit from "@pages/Role/RoleEdit";
import Roles from "@pages/Role/Roles";
import WalletAdd from "@pages/Wallet/WalletAdd";
import WalletEdit from "@pages/Wallet/WalletEdit";
import Wallets from "@pages/Wallet/Wallets";

// REPORT
import DailySales from "./Report/DailySales";
import MonthlySales from "./Report/MonthlySales";
import ReportOrdersBV from "./Report/ReportBVOrders";
// import ReportBonusDetails from "./Report/ReportBonusDetails";
// import ReportBonusSummary from "./Report/ReportBonusSummary";
import ReportSalesFor2Month from "./Report/ReportSalesFor2Month";
import ReportSimplifiedSale from "./Report/ReportSimplifiedSale";
import AccountLink from "./ThirdParty/AccountLink";
// import ReportWalletBalance from "./Report/ReportWalletBalance";
// import ReportWalletTransaction from "./Report/ReportWalletTransaction";
import ReportPaymentTransaction from "./Report/ReportPaymentTransaction";
import PaymentTransactionDetail from "./Report/PaymentTransactionDetail";

// THIRDPARTY
import OrderClaim from "./ThirdParty/OrderAPI";
import OrderRefund from "./ThirdParty/OrderAPI";
import OrderApiDetails from './ThirdParty/OrderApiDetails';

const basicRoutes = [
    {
        path: "/dashboard",
        component: <DashboardPage />,
        exact: true,
        permission: [],
        sidebar: true,
        icon: <Dashboard />,
        title: 'title.dashboard',
        badgeFor: 'dashboard',
    },
    {
        path: "/profile",
        component: <Profile />,
        exact: true,
        permission: [],
        sidebar: false,
        icon: null,
        title: '',
    },
];

const reportRoutes = [
    // {
    //     path: "/reports",
    //     component: <Reports />,
    //     exact: true,
    //     permission: ['report'],
    //     sidebar: true,
    //     icon: <BarChartIcon />,
    //     title: 'title.reports',
    // },
    // {
    //     path: "/reports/wallet-transaction",
    //     component: <ReportWalletTransaction />,
    //     exact: true,
    //     permission: ['report_member_wallet_history', 'report_seller_wallet_history'],
    //     permissionCheckType: 'OR',
    //     sidebar: true,
    //     icon: <Assignment />,
    //     title: 'title.reportWalletTransaction',
    //     category: 'report',
    // },
    // {
    //     path: "/reports/wallet-balance",
    //     component: <ReportWalletBalance />,
    //     exact: true,
    //     permission: ['report_seller_wallet_balance', 'report_member_wallet_balance'],
    //     permissionCheckType: 'OR',
    //     sidebar: true,
    //     icon: <Assignment />,
    //     title: 'title.reportWalletBalance',
    //     category: 'report',
    // },
    {
        path: "/reports/daily-sales",
        component: <DailySales />,
        exact: true,
        permission: ['report_sales'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportDailySales',
        category: 'report',
    },
    {
        path: "/reports/monthly-sales",
        component: <MonthlySales />,
        exact: true,
        permission: ['report_sales'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportMonthlySales',
        category: 'report',
    },
    // {
    //     path: "/reports/bonus-details",
    //     component: <ReportBonusDetails />,
    //     exact: true,
    //     permission: ['report_bonus_details'],
    //     sidebar: true,
    //     icon: <Assignment />,
    //     title: 'title.reportBonusDetails',
    //     category: 'report',
    // },
    // {
    //     path: "/reports/bonus-summary",
    //     component: <ReportBonusSummary />,
    //     exact: true,
    //     permission: ['report_bonus_details'],
    //     sidebar: true,
    //     icon: <Assignment />,
    //     title: 'title.reportBonusSummary',
    //     category: 'report',
    // },
    {
        path: "/reports/sales-user",
        component: <ReportSalesFor2Month />,
        exact: true,
        permission: ['report_bonus_details'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportSalesUserIn2Month',
        category: 'report',
    },
    {
        path: "/reports/bv-order",
        component: <ReportOrdersBV />,
        exact: true,
        permission: ['report_bv_order'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportBVOrder',
        category: 'report',
    },
    {
        path: "/reports/simplified-sale",
        component: <ReportSimplifiedSale />,
        exact: true,
        permission: ['report_bv_order'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportSimplifiedSale',
        category: 'report',
    },
    // {
    //     path: "/reports/account-link",
    //     component: <ReportAccountLink />,
    //     exact: true,
    //     permission: ['report_account_link'],
    //     sidebar: true,
    //     icon: <Assignment />,
    //     title: 'title.reportAccountLink',
    //     category: 'report',
    // },
    {
        path: "/reports/payment-transaction",
        component: <ReportPaymentTransaction />,
        exact: true,
        permission: ['report_payment_transaction'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportPaymentTransaction',
        category: 'report',
    },
    {
        path: "/payment-transaction/:id",
        component: <PaymentTransactionDetail />,
        exact: true,
        permission: ['report_payment_transaction'],
        sidebar: false,
        icon: null,
        title: '',
        category: 'report',
    },
]

const adminRoutes = [
    // CUSTOMER SERVICE
    {
        path: "/servicecenter",
        component: <AdminChat />,
        exact: true,
        permission: ['chat_response'],
        sidebar: true,
        icon: <Forum />,
        title: 'title.serviceCenter',
        category: 'admin',
    },
    // MERCHANT CHAT WITH ADMIN
    {
        path: "/merchanthelpdesk",
        component: <MerchantHelpdesk />,
        exact: true,
        permission: [],
        sidebar: false,
        icon: null,
        title: 'title.merchantHelpdesk',
        category: 'admin',
    },
    // ADMIN
    {
        path: "/admins",
        component: <Admins />,
        exact: true,
        permission: ['admin'],
        sidebar: true,
        icon: <Person />,
        title: 'title.admins',
        category: 'admin',
    },
    {
        path: "/admins/add",
        component: <AdminAdd />,
        exact: true,
        permission: ['admin_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/admins/:id",
        component: <AdminEdit />,
        exact: true,
        permission: ['admin_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // SELLER
    {
        path: "/sellers",
        component: <Sellers />,
        exact: true,
        permission: ['company'],
        sidebar: true,
        icon: <Storefront />,
        title: 'title.sellers',
        category: 'admin',
    },
    // {
    //     path: "/sellers/add",
    //     component: <SellerAdd />,
    //     exact: true,
    //     permission: ['company_add'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    {
        path: "/sellers/:id",
        component: <SellerEdit />,
        exact: true,
        permission: ['company_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // ADJUST WALLET BALANACE
    // {
    //     path: "/wallets/add-deduct",
    //     component: <WalletAddDeduct />,
    //     exact: true,
    //     permission: ['wallet_adjust'],
    //     sidebar: true,
    //     icon: <AccountBalanceWallet />,
    //     title: 'title.walletAdjust',
    //     category: 'admin',
    // },
    // WITHDRAWAL
    // {
    //     path: "/withdrawals",
    //     component: <Withdrawals />,
    //     exact: true,
    //     permission: ['withdrawal_seller'],
    //     sidebar: true,
    //     icon: <LocalAtm />,
    //     title: 'title.withdrawals',
    //     category: 'admin',
    // },   
    // {
    //     path: "/withdrawals-member",
    //     component: <WithdrawalsMember />,
    //     exact: true,
    //     permission: ['withdrawal_member'],
    //     sidebar: true,
    //     icon: <LocalAtm />,
    //     title: 'title.withdrawalsMember',
    //     category: 'admin',
    // },
    // FAQ
    {
        path: "/faqs",
        component: <Faqs />,
        exact: true,
        permission: ['faq'],
        sidebar: true,
        icon: <Help />,
        title: 'title.faqs',
        category: 'admin',
    },
    {
        path: "/faqs/add",
        component: <FaqAdd />,
        exact: true,
        permission: ['faq_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/faqs/:id",
        component: <FaqEdit />,
        exact: false,
        permission: ['faq_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // BANNER
    {
        path: "/landingbanner",
        component: <LandingBanner />,
        exact: true,
        permission: ['banner_landing_edit'],
        sidebar: true,
        icon: <PhotoLibrary />,
        title: 'banner.landingBanner',
        category: 'admin',
    },
    // NOTIFICATION
    {
        path: "/notifications",
        component: <NotificationsList />,
        exact: true,
        permission: ['notification'],
        sidebar: true,
        icon: <Notifications />,
        title: 'title.notifications',
        category: 'admin',
    },
    {
        path: "/notifications/add",
        component: <NotificationAdd />,
        exact: true,
        permission: ['notification_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/notifications/:id",
        component: <NotificationEdit />,
        exact: true,
        permission: ['notification_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // ANNOUNCEMENT
    {
        path: "/announcement",
        component: <Announcements />,
        exact: true,
        permission: ['announcement'],
        sidebar: true,
        icon: <Announcement />,
        title: 'title.announcements',
        category: 'admin',
    },
    // KYC
    // {
    //     path: "/kyc",
    //     component: <Kyc />,
    //     exact: true,
    //     permission: ['member'],
    //     sidebar: true,
    //     icon: <EmojiPeopleIcon />,
    //     title: 'title.kyc',
    //     category: 'admin',
    // },
    // POST (COURSE)
    // {
    //     path: "/courses",
    //     component: <Posts />,
    //     exact: true,
    //     permission: ['post'],
    //     sidebar: true,
    //     icon: <CollectionsIcon />,
    //     title: 'title.post',
    //     category: 'admin',
    // },
    // {
    //     path: "/course/add",
    //     component: <PostAdd />,
    //     exact: true,
    //     permission: ['post_add'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // {
    //     path: "/course/:id",
    //     component: <PostEdit />,
    //     exact: true,
    //     permission: ['post'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
]

const sellerRoutes = [
    {
        path: "/chat",
        component: <Chat />,
        exact: true,
        permission: ['chat_merchant_member'],
        sidebar: true,
        icon: <Sms />,
        title: 'title.chat',
        category: 'seller',
    },
    // MERCHANT
    {
        path: "/merchants",
        component: <Merchants />,
        exact: true,
        permission: ['merchant'],
        sidebar: true,
        icon: <HowToReg />,
        title: 'title.merchants',
        category: 'seller',
    },
    {
        path: "/merchants/add",
        component: <MerchantAdd />,
        exact: true,
        permission: ['merchant_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/merchants/add/:comId",
        component: <MerchantAdd />,
        exact: true,
        permission: ['merchant_add', 'company_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/merchants/:id",
        component: <MerchantEdit />,
        exact: true,
        permission: ['merchant_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // COLLECTION
    {
        path: "/collections",
        component: <CollectionsList />,
        exact: true,
        permission: ['collection'],
        sidebar: true,
        icon: <Collections />,
        title: 'title.collections',
        category: 'seller',
    },
    {
        path: "/collections/:id",
        component: <CollectionEdit />,
        exact: true,
        permission: ['collection_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // PRODUCT
    {
        path: "/products",
        component: <Products />,
        exact: true,
        permission: ['product'],
        sidebar: true,
        icon: <ShoppingCart />,
        title: 'title.products',
        category: 'seller',
    },
    // {
    //     path: "/products/bulk-upload",
    //     component: <ProductBulkUpload />,
    //     exact: true,
    //     permission: ['product_add'],
    //     sidebar: true,
    //     icon: <ShoppingCartIcon />,
    //     title: 'product.bulkUploadProduct',
    //     category: 'seller',
    // },
    // {
    //     path: "/import",
    //     component: <ImportProduct />,
    //     exact: true,
    //     permission: ['product_import'],
    //     sidebar: true,
    //     icon: <GetAppIcon />,
    //     title: 'title.importProducts',
    //     category: 'seller',
    // },
    {
        path: "/products/add",
        component: <ProductAdd />,
        exact: true,
        permission: ['product_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/products/:id",
        component: <ProductEdit />,
        exact: false,
        permission: ['product_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // VOUCHER
    // {
    //     path: "/vouchers",
    //     component: <Vouchers />,
    //     exact: true,
    //     permission: ['voucher'],
    //     sidebar: true,
    //     icon: <ShoppingCartIcon />,
    //     title: 'title.vouchers',
    //     category: 'seller',
    // },
    // {
    //     path: "/vouchers/add",
    //     component: <VoucherAdd />,
    //     exact: true,
    //     permission: ['voucher_add'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // {
    //     path: "/vouchers/:id/details",
    //     component: <VoucherDetails />,
    //     exact: false,
    //     permission: ['voucher'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // {
    //     path: "/vouchers/:id",
    //     component: <VoucherEdit />,
    //     exact: true,
    //     permission: ['voucher_edit'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // ORDER
    {
        path: "/orders",
        component: <Orders />,
        exact: true,
        permission: ['order'],
        sidebar: true,
        icon: <LibraryBooks />,
        title: 'title.orders',
        badge: true,
        category: 'seller',
    },
    {
        path: "/orders/add",
        component: <OrderAdd />,
        exact: true,
        permission: ['order_edit'],
        sidebar: true,
        icon: <LibraryBooks />,
        title: 'order.addOrder',
        category: 'seller',
    },
    {
        path: "/orders/:id",
        component: <OrderEdit />,
        exact: true,
        permission: ['order_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/orders-all",
        component: <OrdersAll />,
        exact: true,
        permission: ['order_all'],
        sidebar: true,
        icon: <LibraryBooks />,
        title: 'title.orders_all',
        badge: true,
        category: 'seller',
    },
    // REVIEW
    {
        path: "/shop-reviews",
        component: <ShopReviews />,
        exact: true,
        permission: ['review'],
        sidebar: true,
        icon: <RateReview />,
        title: 'title.shopReviews',
        category: 'seller',
    },
    {
        path: "/product-reviews",
        component: <ProductReviews />,
        exact: true,
        permission: ['review'],
        sidebar: true,
        icon: <RateReview />,
        title: 'title.productReviews',
        category: 'seller',
    },
    // Q&A
    {
        path: "/product-questions",
        component: <ProductQuestions />,
        exact: true,
        permission: ['qna'],
        sidebar: true,
        icon: <QuestionAnswer />,
        title: 'title.productQuestions',
        category: 'seller',
    },
]

const memberRoutes = [
    // MEMBER
    {
        path: "/members",
        component: <Members />,
        exact: true,
        permission: ['member'],
        sidebar: true,
        icon: <People />,
        title: 'title.members',
        category: 'member',
    },
    {
        path: "/members/add",
        component: <MemberAdd />,
        exact: true,
        permission: ['member_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/members/:id",
        component: <MemberEdit />,
        exact: true,
        permission: ['member_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // SPONSOR GENEALOGY
    // {
    //     path: "/sponsor-genealogy",
    //     component: <SponsorGenealogy />,
    //     exact: true,
    //     permission: ['member_sponsor_genealogy'],
    //     sidebar: true,
    //     icon: <AccountTree />,
    //     title: 'title.sponsorGenealogy',
    //     category: 'member',
    // },
    // PLACEMENT GENEALOGY
    // {
    //     path: "/placement-genealogy",
    //     component: <GenealogyPlacement />,
    //     exact: true,
    //     permission: ['member_placement_genealogy'],
    //     sidebar: true,
    //     icon: <AccountTree />,
    //     title: 'title.placementGenealogy',
    //     category: 'member',
    // },
    // {
    //     path: "/placement-genealogy2",
    //     component: <GenealogyPlacement2 />,
    //     exact: true,
    //     permission: ['member_placement_genealogy'],
    //     sidebar: false,
    //     icon: <AccountTree />,
    //     title: 'title.placementGenealogy',
    //     category: 'member',
    // },
    // UPLINE
    // {
    //     path: "/upline",
    //     component: <Uplines />,
    //     exact: true,
    //     permission: ['member_upline'],
    //     sidebar: true,
    //     icon: <AccountTree />,
    //     title: 'title.upline',
    //     category: 'member',
    // },
]

const thirdPartyRoutes = [
    {
        path: "/third-party/account-link",
        component: <AccountLink />,
        exact: true,
        permission: ['third_party_account_link'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.reportAccountLink',
        category: 'thirdParty',
    },
    {
        path: "/third-party/order-claim",
        component: <OrderClaim />,
        exact: true,
        permission: ['third_party_order_claim'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.thirdPartyOrderClaim',
        category: 'thirdParty',
    },
    {
        path: "/third-party/order-refund",
        component: <OrderRefund />,
        exact: true,
        permission: ['third_party_order_refund'],
        sidebar: true,
        icon: <Assignment />,
        title: 'title.thirdPartyOrderRefund',
        category: 'thirdParty',
    },
    {
        path: "/third-party/:type/:id",
        component: <OrderApiDetails />,
        exact: true,
        permission: ['third_party_order_details'],
        sidebar: false,
        icon: null,
        title: 'title.thirdPartyOrderRefund',
        category: 'thirdParty',
    }
]

const settingRoutes = [
    // BONUS
    {
        path: "/bonuses",
        component: <Bonuses />,
        exact: true,
        permission: ['bonus'],
        sidebar: true,
        icon: <MonetizationOn />,
        title: 'title.bonuses',
        category: 'setting',
    },
    {
        path: "/bonuses/add",
        component: <BonusAdd />,
        exact: true,
        permission: ['bonus_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/bonuses/:id",
        component: <BonusEdit />,
        exact: true,
        permission: ['bonus_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // CATEGORY
    {
        path: "/categories",
        component: <Categories />,
        exact: true,
        permission: ['category'],
        sidebar: true,
        icon: <Category />,
        title: 'title.categories',
        category: 'setting',
    },
    {
        path: "/categories/add",
        component: <CategoryAdd />,
        exact: true,
        permission: ['category_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/categories/:id",
        component: <CategoryEdit />,
        exact: true,
        permission: ['category_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // CURRENCY
    {
        path: "/currency/add",
        component: <CurrencyAdd />,
        exact: true,
        permission: ['currency_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/currency/:id",
        component: <CurrencyEdit />,
        exact: true,
        permission: ['currency_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/currency",
        component: <Currency />,
        exact: true,
        permission: ['currency'],
        sidebar: true,
        icon: <AttachMoney />,
        title: 'title.currency',
        category: 'setting',
    },
    // PARAMETER
    {
        path: "/parameters",
        component: <Parameters />,
        exact: true,
        permission: ['parameter'],
        sidebar: true,
        icon: <Settings />,
        title: 'title.parameters',
        category: 'setting',
    },
    {
        path: "/parameters/add",
        component: <ParameterAdd />,
        exact: true,
        permission: ['parameter_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/parameters/:id",
        component: <ParameterEdit />,
        exact: false,
        permission: ['parameter_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // PERMISSION
    {
        path: "/permissions",
        component: <Permissions />,
        exact: true,
        permission: ['permission'],
        sidebar: true,
        icon: <Beenhere />,
        title: 'title.permissions',
        category: 'setting',
    },
    {
        path: "/permissions/add",
        component: <PermissionAdd />,
        exact: true,
        permission: ['permission_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/permissions/:id",
        component: <PermissionEdit />,
        exact: true,
        permission: ['permission_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // POINT
    // {
    //     path: "/points",
    //     component: <Points />,
    //     exact: true,
    //     permission: ['point'],
    //     sidebar: true,
    //     icon: <MonetizationOnIcon />,
    //     title: 'title.points',
    //     category: 'setting',
    // },
    // {
    //     path: "/points/add",
    //     component: <PointAdd />,
    //     exact: true,
    //     permission: ['point_add'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // {
    //     path: "/points/:id",
    //     component: <PointEdit />,
    //     exact: true,
    //     permission: ['point_edit'],
    //     sidebar: false,
    //     icon: null,
    //     title: '',
    // },
    // RANK
    {
        path: "/ranks",
        component: <Ranks />,
        exact: true,
        permission: ['rank'],
        sidebar: true,
        icon: <CardMembership />,
        title: 'title.ranks',
        category: 'setting',
    },
    {
        path: "/ranks/add",
        component: <RankAdd />,
        exact: true,
        permission: ['rank_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/ranks/:id",
        component: <RankEdit />,
        exact: true,
        permission: ['rank_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // ROLE
    {
        path: "/roles",
        component: <Roles />,
        exact: true,
        permission: ['role'],
        sidebar: true,
        icon: <AccountTree />,
        title: 'title.roles',
        category: 'setting',
    },
    {
        path: "/roles/add",
        component: <RoleAdd />,
        exact: true,
        permission: ['role_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/roles/:id",
        component: <RoleEdit />,
        exact: true,
        permission: ['role_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
    // WALLET
    {
        path: "/wallets",
        component: <Wallets />,
        exact: true,
        permission: ['wallet'],
        sidebar: true,
        icon: <AccountBalanceWallet />,
        title: 'title.wallets',
        category: 'setting',
    },
    {
        path: "/wallets/add",
        component: <WalletAdd />,
        exact: true,
        permission: ['wallet_add'],
        sidebar: false,
        icon: null,
        title: '',
    },
    {
        path: "/wallets/:id",
        component: <WalletEdit />,
        exact: true,
        permission: ['wallet_edit'],
        sidebar: false,
        icon: null,
        title: '',
    },
]

const routes = _.concat(basicRoutes, reportRoutes, adminRoutes, sellerRoutes, memberRoutes, thirdPartyRoutes, settingRoutes);

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/dashboard">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function PrivateRoute({ children, ...rest }) {
    const { accessToken, companyStatus, companyId } = useSelector(state => state.general);
    let history = useHistory();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                accessToken ? companyStatus == '4' ? (
                    history.push(`/seller/register?company_id=${companyId} `)
                ) : (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const App = (props) => {
    const classes = useStyles();
    const { accessToken, permissions, role, companyType, isAdmin } = useSelector(state => state.general);

    const Wrapper = children => (
        <NotificationLoadingProvider>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                {children}
                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );

    return (
        <Fragment>
            <div className={classes.root}>
                {
                    !_.includes(['/login', '/forgot-password', '/authorize', '/seller/register', '/sellers/tnc'], props.location.pathname) && !props.location['pathname'].includes('/reset-password/') ? <HeaderSidebar routeProps={routes} /> : ''
                }
                <main className={classes.content}>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/login">{accessToken ? <Redirect to={{ pathname: "/dashboard" }} /> : <Login />} </Route>
                        {PROJECT_TYPE === "mall" ? <Route exact path="/authorize"><LoginCatalog /></Route> : null}
                        <Route exact path="/forgot-password">{accessToken ? <Redirect to={{ pathname: "/dashboard" }} /> : <NotificationLoadingProvider><PasswordForgot /><NotificationLoading /></NotificationLoadingProvider>} </Route>
                        <Route exact path="/reset-password/:token">{accessToken ? <Redirect to={{ pathname: "/dashboard" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider>} </Route>
                        <Route exact path="/seller/register"> <NotificationLoadingProvider><SellerRegister /><NotificationLoading /></NotificationLoadingProvider></Route>
                        <Route exact path="/sellers/tnc">{<NotificationLoadingProvider><SellerTnc /><NotificationLoading /></NotificationLoadingProvider>} </Route>
                        <Route path="/maintenance" component={Maintenance} />
                        {
                            routes.map(route => {
                                let fulfilledAllPermission = true;
                                if (_.size(route.permission) > 0) {
                                    let permissionChecking = _.hasIn(route, 'permissionCheckType') ? route.permissionCheckType : 'AND';

                                    if (permissionChecking === 'AND') {
                                        _.map(route.permission, requiredPermission => {
                                            if (!_.includes(permissions, requiredPermission)) {
                                                fulfilledAllPermission = false;
                                            }
                                            if (_.includes(['merchant', 'merchant_edit', 'merchant_add', 'merchant_delete'], requiredPermission) && !isAdmin && parseInt(companyType) !== 1) {
                                                fulfilledAllPermission = false;
                                            }
                                        })
                                    } else if (permissionChecking === 'OR') {
                                        fulfilledAllPermission = false;
                                        _.map(route.permission, requiredPermission => {
                                            if (_.includes(['merchant', 'merchant_edit', 'merchant_add', 'merchant_delete'], requiredPermission) && !isAdmin && parseInt(companyType) !== 1) {
                                                fulfilledAllPermission = false;
                                            }

                                            if (_.includes(permissions, requiredPermission)) {
                                                fulfilledAllPermission = true;
                                            }
                                        });
                                    }

                                    if (route.path == "/wallets/balance" && isAdmin) {
                                        fulfilledAllPermission = false;
                                    }
                                }
                                if (fulfilledAllPermission) {
                                    return <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{Wrapper(route.component)}</PrivateRoute>
                                }
                            })
                        }
                        <Route><Redirect to={{ pathname: "/dashboard" }} /></Route>
                    </Switch>
                </main>
            </div>
        </Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

export default withRouter(App);