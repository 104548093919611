import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { makeStyles, Grid, Typography, LinearProgress, Toolbar, FormControl, Input, InputAdornment, Tooltip, IconButton, Icon, Button, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { OrgChartComponent } from '@components/OrgChart';
import '@css/tree.css';
import { useQuery } from '@utils/Tools';
import { useHistory } from 'react-router-dom';

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('genealogy.searchUsername')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, setValue, toolbarSearch];
}

export default function GenealogyPlacement2() {
    const [data, setData] = useState({});
    const [ranks, setRanks] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [searchValue, setSearchValue, searchToolbar] = TableToolbar({handleSubmit, val: ''});
    
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const query = useQuery();
    const searchUsername = query.get('username');
    const chartRef = useRef();
    
    useEffect(() => {
        if(searchUsername) {
            setUsername(searchUsername);
            setSearchValue(searchUsername);
        } else {
            setUsername('member');
            setSearchValue('member');
        }
    }, [searchUsername, setSearchValue]);

    useEffect(() => {
        let params = {'per_page': 100 };
        getUrl('admin/ranks', params).then(result => {
            setTableLoading(false);
            if(isMountedRef.current) {
                if(result.status === 1){
                    setRanks(result.data.data);
                }
            }
        });

    }, []);

    function callApi() {
        getUrl(`admin/genealogy/placement_v2`, {username}).then(response => {
            let {status, data} = response;
            if (status === 1 && isMountedRef.current) {
                // console.log("tree_data", data.tree_data);
                setData(data.tree_data);
                setTableLoading(false);
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setTableLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            }
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(username) > 0) {
            setTableLoading(true);
            callApi();
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [username]);

    function handleSubmit(event) {
        event.preventDefault();
        history.push(`/placement-genealogy2?username=${searchValue}`);
    }

    function onNodeClick(nodeId) {
        // console.log('clicked ' + nodeId);
        // console.log("data", data);
        const selectedNode = _.find(data, { nodeId });
        if(_.size(selectedNode)) {
            history.push(`/placement-genealogy2?username=${selectedNode.username}`);
        }
        // console.log("selectedNode", selectedNode)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('genealogy.placementGenealogy')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <RankLegends ranks={ranks} />
            </Grid>
            <Grid item xs={12} sm={6}>
                {searchToolbar}
            </Grid>
            <Grid item xs={12}>
                { tableLoading ? <LinearProgress />
                    : _.size(data) ? 
                    <Box border={"2px solid red"} style={{ pointerEvents: 'none' }}>
                        <OrgChartComponent
                            data={data}
                            ref={chartRef}
                            onNodeClick={onNodeClick}
                        />
                    </Box>
                    : null }
            </Grid>
        </Grid>
    );
}

const RankLegends = ({ ranks }) => {
    return (
        <Box display="flex" flexDirection="row">
            { _.map(ranks, rank => {
                return (
                    <Box paddingX={1} display="flex" flexDirection="column" alignItems='center' justifyContent="center" key={rank.id}>
                        <img src={`../images/rank_logo/rank-${rank.code}.png`} style={{ width: 30, height: 30 }} alt="" />
                        { rank.name_display }
                    </Box>
                )
            })}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));