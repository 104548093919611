import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PropTypes from 'prop-types';
// Icon
import ContactsIcon from '@material-ui/icons/Contacts';
import ListIcon from '@material-ui/icons/List';
// import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ApartmentIcon from '@material-ui/icons/Apartment';

import CompanyEditCompanyInfo from './CompanyEditCompanyInfo';
import CompanyEditMerchantList from './CompanyEditMerchantList';
import CompanyEditShippingRegion from './CompanyEditShippingRegion';
import CompanyEditIconBanner from './CompanyEditIconBanner';
// import CompanyEditWarehouses from './CompanyEditWarehouses';
import CompanyEditEasyParcel from './CompanyEditEasyParcel';
import CompanyEditOutlet from './CompanyEditOutlet';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SellerEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [tabValue, setTabValue] = useState(0);
    const { permissions,role, isAdmin, isMerchant } = useSelector(state => state.general);
    const [companyOwner, setCompanyOwner] = useState(false);
    
    const [companyType, setCompanyType] = useState([]);
    const [collaboratorList, setCollaboratorList] = useState([]);
    const [logisticList, setLogisticList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateGroup, setStateGroup] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityGroup, setCityGroup] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [districtGroup, setDistrictGroup] = useState([]);
    const [merchantList, setMerchantList] = useState([]);
    // const [warehouseList, setWarehouseList] = useState([]);
    const [epApiKey, setEpApiKey] = useState('');
    const [oldType, setOldType] = useState([]);
    const [mobileCodeList , setMobileCodeList]=useState({});
    const [state, setState] = useState({
        companyName: '',
        shopName: '',
        slug: '',
        email: '',
        mobileCode:'',
        mobile: '',
        register_no: '',
        type: '',
        pic: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        logisticPartner: '',
        about: '',
        bankName: '',
        bankHolderName: '',
        bankAccount: '',
        bankSwift: '',
        collaborator: '',
        business_nature: '',
        product_sell: '',
        referral_commission: '',
        shop_commission: '',
        referral: '',
        commencementDate: '',
        bv_threshold: '',
        cv_threshold: '',
        // status: 1,
        // refundTncEn: '',
        // refundTncCn: '',
        allow_self_pickup:false,
    });
    const [ep, setEp] = useState({
        email: '',
        firstName: '',
        lastName: '',
        apiKey: '',
    });
    const [shippingRegions, setShippingRegions] = useState([]);
    const [companyMedia, setCompanyMedia] = useState({});
    const [userId, setUserId] = useState(0);
    const [showEp, setShowEp] = useState(false);
    const [premiumStore, setPremiumStore]=useState(false);

    let { id } = useParams();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (_.size(countryList) > 0) {
            setLoading(true);
            if (state?.country !== '') {
                let codeList = {};
                let countryCode = state?.mobileCode;
                let code = _.find(countryList, { 'code': state?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : '';
                    }
                }

                setMobileCodeList(codeList);
                setState(prevState => ({ ...prevState, mobileCode: countryCode}));
            } else if (state?.country === '' && state?.mobileCode !== '') {
                let tempArr = _.clone(countryList);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, state?.mobileCode));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(tempArr[0]['tel_code']);
                }
            }
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [countryList, state?.country , state?.mobileCode]);

    useEffect(() => {
        isMountedRef.current = true;
        setCompanyOwner(_.includes(permissions, "company_owner") ? true : false);
        getUrl(`admin/companies/${id}`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setState({
                    companyName: response.data.company_name?response.data.company_name:'',
                    shopName: response.data.shop_name,
                    slug: response.data.slug,
                    email: response.data.email,
                    mobileCode: response.data.mobile_country_code,
                    mobile: response.data.mobile,
                    register_no: response.data.register_no?response.data.register_no:'',
                    type: response.data.type,
                    pic: response.data.pic,
                    address: response.data.address,
                    address2: response.data.address2,
                    city: response.data.city,
                    state: response.data.state,
                    zip: response.data.zip,
                    country: response.data.country,
                    logisticPartner: response.data.log_partner ? response.data.log_partner : [],
                    about: response.data.about,
                    bankName: response.data.bank_name?response.data.bank_name:'',
                    bankHolderName: response.data.bank_holder_name?response.data.bank_holder_name:'',
                    bankAccount: response.data.bank_account?response.data.bank_account:'',
                    bankSwift: response.data.bank_swift?response.data.bank_swift:'',
                    collaborator: response.data.collaborator?response.data.collaborator:'',
                    status: response.data.status,
                    business_nature: response.data.business_nature ? response.data.business_nature:"",
                    product_sell: response.data.product_sell ? response.data.product_sell : "",
                    referral_commission: response.data.referral_commission,
                    shop_commission: response.data.shop_commission,
                    referral: response.data.referral ? response.data.referral: "",
                    register_attachments: response.data.register_attachments,
                    // refundTncEn: response.data.refund_tnc_en?response.data.refund_tnc_en:'',
                    // refundTncCn: response.data.refund_tnc_cn?response.data.refund_tnc_cn:'',
                    commencementDate: response.data.commencement_date?response.data.commencement_date:'',
                    bv_threshold: response.data.wallet_threshold?response.data.wallet_threshold.bv:'',
                    cv_threshold: response.data.wallet_threshold?response.data.wallet_threshold.cv:'',
                    deduct_bv: response.data.deduct_bv,
                    deduct_cv: response.data.deduct_cv,
                    allow_self_pickup:response.data.allow_self_pickup ===1 ? true:false,
                });
                setOldType(response.data.type);
                setMerchantList(response.merchant_list);
                setUserId(response.own_id);
                setPremiumStore(response.data.premium_store ===1? true:false);
                // setWarehouseList(response.warehouse_list);
                if (response.easyParcel_acc) {
                    setEp({
                        email: response.easyParcel_acc.email ? response.easyParcel_acc.email : '',
                        firstName: response.easyParcel_acc.first_name ? response.easyParcel_acc.first_name : '',
                        lastName: response.easyParcel_acc.last_name ? response.easyParcel_acc.last_name : '',
                        apiKey: response.easyParcel_acc.api_key ? response.easyParcel_acc.api_key : '',
                    });
                    setEpApiKey(response.easyParcel_acc.api_key ? response.easyParcel_acc.api_key : '');
                }
                setShippingRegions(response.data.shipping_region_restriction);
                setCompanyMedia({ icon: response.data.merchant_company_icon, banners: _.sortBy(response.data.merchant_company_banners, ['priority']) });
                setShowEp(response.ep_merchant_api ? true : false);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/companies`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCompanyType(response.company_type);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/collaborators`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCollaboratorList(response.collaborator);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/address`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCountryList(response.country_list);
                setStateList(response.state_list);
                setStateGroup(response.state_group);
                setCityList(response.city_list);
                setCityGroup(response.city_group);
                setDistrictList(response.district_list);
                setDistrictGroup(response.district_group);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert]);

    useEffect(() => {
        if(state.country){
            getUrl(`admin/logistic_list/${state.country}`).then(response => {
                if (response.status === 1) {
                    setLogisticList(response.easy_parcel_logistic);
                }
            }).catch((error) => {
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            })
        }
        // eslint-disable-next-line
    }, [addAlert, state.country]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('seller.editSeller')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/sellers`} component={RouterLink}>{t('seller.sellers')}</Link>
                        <Typography style={{ color: 'black' }}>{t('seller.editSeller')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" justify="space-around" style={{ backgroundColor: 'transparent', color: theme.palette.primary.main, WebkitBoxShadow: 'none' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
                        <Tab icon={<ContactsIcon />} label={t('seller.info')}  value={0}{...a11yProps(0)} />
                        <Tab icon={<InsertPhotoIcon />} label={t('seller.insertPhoto')} value={1} {...a11yProps(1)} />
                        {
                            (isAdmin || _.size(_.intersection(role, ['super-merchant']))) &&
                            <Tab icon={<LocalShippingIcon />} label={t('seller.shippingRegion')}  value={2}{...a11yProps(2)} />
                        }
                        {
                            _.includes(permissions,"company_outlet") &&  
                            <Tab icon={<ApartmentIcon />} label={t('seller.outlet')}  value={3}{...a11yProps(3)} />
                        }
                        {
                            state.type === 1?
                            <Tab icon={<ListIcon />} label={t('seller.merchantList')} value={4} {...a11yProps(4)} />
                            :null
                        }
                        {/* <Tab icon={<HomeWorkIcon />} label={t('seller.warehouseList')} {...a11yProps(4)} /> */}
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.mt30 }}>
                    <TabPanel value={tabValue} index={0}>
                        <CompanyEditCompanyInfo id={id} companyOwner={companyOwner} state={state} setState={setState} companyType={companyType} collaboratorList={collaboratorList} logisticList={logisticList} countryList={countryList} stateList={stateList} stateGroup={stateGroup} cityList={cityList} cityGroup={cityGroup} districtList={districtList} districtGroup={districtGroup} setLoading={setLoading} addAlert={addAlert} oldType={oldType} premiumStore={premiumStore} setPremiumStore={setPremiumStore} mobileCodeList={mobileCodeList} />
                        {
                            showEp?
                            <CompanyEditEasyParcel id={id} companyOwner={companyOwner} epApiKey={epApiKey} setEpApiKey={setEpApiKey} ep={ep} setEp={setEp} setLoading={setLoading} addAlert={addAlert} />
                            : null
                        }
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <CompanyEditIconBanner id={id} companyInfo={state} setCompanyMedia={setCompanyMedia} companyMedia={companyMedia} setLoading={setLoading} addAlert={addAlert} />
                    </TabPanel>
                    {
                        (isAdmin || _.size(_.intersection(role, ['super-merchant']))) ?
                            _.size(countryList)
                                ? <TabPanel value={tabValue} index={2}><CompanyEditShippingRegion id={id} countryList={countryList} shippingRegions={shippingRegions} setShippingRegions={setShippingRegions} stateGroup={stateGroup} stateList={stateList} setLoading={setLoading} addAlert={addAlert} /></TabPanel>
                                : null
                            :
                            null
                    }
                    {
                        _.includes(permissions, "company_outlet") ?
                            <TabPanel value={tabValue} index={3}>
                                <CompanyEditOutlet id={id} companyInfo={state} setLoading={setLoading} addAlert={addAlert} />
                            </TabPanel>
                            : null
                    }
                    {
                        state.type === 1?
                        <TabPanel value={tabValue} index={4}>
                            <CompanyEditMerchantList id={id} userId={userId} merchantList={merchantList} />
                        </TabPanel>
                        :null
                    }
                    {/* <TabPanel value={tabValue} index={4}>
                        <CompanyEditWarehouses id={id} warehouseList={warehouseList} setWarehouseList={setWarehouseList} countryList={countryList} stateGroup={stateGroup} stateList={stateList} setLoading={setLoading} addAlert={addAlert} />
                    </TabPanel> */}
                </Paper>
            </Grid>

        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));