import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { getUrl, postUrl, putUrl } from '@utils/ApiAction';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, TextField, MenuItem, Button, Grid, Paper, IconButton, Modal, Switch } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

export default function ProductEditPointRewards({ data, setLoading, addAlert, setData }) {
    const classes = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();

    const [rewards, setRewards] = useState([]);
    const [wallets, setWallets] = useState([]);
    const amountTypeList = {'fix': t('product.fix'), 'percent': t('product.percent')};
    const [sourceList, setSourceList] = useState([]);
    const [selectedOptionIndex, setOptionIndex] = useState('');
    const [user_source, setSource] = useState('all');
    const [amount_type, setAmountType] = useState('fix');
    const [inputErrors, setInputErrors] = useState([]);
    const [walletAmount, setWalletAmount] = useState([]);

    const getRewardsApi = () => {
        getUrl(`admin/products/${data.id}/product_rewards`).then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                if (response.status === 1) {
                    setRewards(response.data);
                    setSourceList(response.source_list);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getRewardsApi();

        getUrl('admin/wallets').then((response) => {
            if(isMountedRef.current) {
                setLoading(false);
                if (response.status === 1) {
                    setWallets(response.data);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });

        return () => { isMountedRef.current = false };
    }, []);

    useEffect(() => {
        if (selectedOptionIndex !== "") {
            if (selectedOptionIndex === "new") {
                setAmountType("fix");
                setSource("all");
                setWalletAmount([]);
            } else {
                setAmountType(rewards[selectedOptionIndex].amount_type || "");
                setSource(rewards[selectedOptionIndex].user_source || "");
                setWalletAmount(rewards[selectedOptionIndex].wallet_amount || []);
            }
        }
    }, [selectedOptionIndex, setAmountType, setSource]);

    const saveOptions = () => {
        setLoading(true);
        setInputErrors([]);

        let apiData = {
            user_source,
            amount_type,
            wallet_amount: walletAmount
        }
        if (selectedOptionIndex !== "" && selectedOptionIndex !== "new") {
            putUrl(`admin/products/${data.id}/product_rewards/${rewards[selectedOptionIndex].id}`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        getRewardsApi();
                        setData(newData);
                    } else {
                        if (_.size(error) > 0) {
                            setInputErrors(error);
                            _.map(error, (value, key) => {
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
        } else {
            postUrl(`admin/products/${data.id}/product_rewards`, apiData)
                .then((response) => {
                    setLoading(false);
                    let { status, message, error, data: newData } = response;
                    if (status === 1) {
                        addAlert('', message, 'success', '');
                        getRewardsApi();
                        setData(newData);
                    } else {
                        if (_.size(error) > 0) {
                            setInputErrors(error);
                            _.map(error, (value, key) => {
                                if(key === "general")
                                    message+="\n"+value[0];
                            })
                        }
                        addAlert('', message, 'error', '');
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
                });
        }
    };

    const updateProductRewardStatus = (rowId, checked) => {
        setLoading(true);
        putUrl(`admin/products/${data.id}/product_rewards/${rowId}/status`, {status: checked ? 1:0})
            .then((response) => {
                setLoading(false);
                let { status, message, error, data: newData } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    getRewardsApi();
                    setData(newData);
                } else {
                    if (_.size(error) > 0) {
                        setInputErrors(error);
                        _.map(error, (value, key) => {
                            if(key === "general")
                                message+="\n"+value[0];
                        })
                    }
                    addAlert('', message, 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            });
    };

    return (
        <Grid container spacing={3} style={theme.p20}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h5" style={theme.textline} component="h1">{t('product.pointRewards')}</Typography>
                    <Button size="small" startIcon={<AddIcon />} onClick={() => setOptionIndex("new")}>{t('button.new')}</Button>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            {/* <Grid item xs={12}>
                <Typography variant="body2" style={{color:"#808080"}}>{t('product.pointRewardReminder')}</Typography>
            </Grid> */}
            <Grid item xs={12} container spacing={3} className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('product.source')}</TableCell>
                                <TableCell>{t('product.amountType')}</TableCell>
                                {
                                    _.map(wallets, (wallet, walletIndex) => {
                                        return (
                                            <TableCell key={walletIndex}>{wallet.name}</TableCell>
                                        )
                                    })
                                }
                                <TableCell>{t('product.action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.size(rewards) > 0 ? _.map(rewards, (row, rowIndex) => {
                                // let name = _.split(row.wallet_name, '|');
                                return (
                                    <TableRow key={rowIndex}>
                                        <TableCell>{sourceList[row.user_source]}</TableCell>
                                        <TableCell>{t(`product.${row.amount_type}`)}</TableCell>
                                        {
                                            _.map(wallets, (wallet, walletIndex) => {
                                                return (
                                                    <TableCell key={walletIndex}>{row['wallet_amount'] && row['wallet_amount'][wallet.id] ? row['wallet_amount'][wallet.id] : 0}</TableCell>
                                                )
                                            })
                                        }
                                        <TableCell>
                                            <Grid container alignItems="center" className={classes.actionRoot}>
                                                <IconButton aria-label="edit" size="small" color="primary" onClick={() => setOptionIndex(rowIndex)}><EditIcon /></IconButton>
                                                <Divider orientation="vertical" flexItem />
                                                <Switch
                                                    checked={row.status === 1 ? true : false}
                                                    onChange={(evt) => updateProductRewardStatus(row.id, evt.target.checked)}
                                                    color="primary"
                                                    name="productRewardStatus"
                                                    inputProps={{ 'aria-label': 'reward status checkbox' }}
                                                />
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Modal
                open={selectedOptionIndex !== '' ? true : false}
                onClose={() => {setOptionIndex(""); setInputErrors([]);} }
                aria-labelledby="point reward"
                aria-describedby="point reward modal"
                disableBackdropClick
            >
                <Grid container spacing={3} className={classes.modal}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h1">{selectedOptionIndex === "new" ? t('product.addPointReward') : t('product.editPointReward')}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        size="small"
                                        label={t('product.source')}
                                        value={user_source}
                                        onChange={({ target }) => setSource(target.value)}
                                        variant="outlined"
                                        fullWidth
                                        error={inputErrors['user_source'] ? true : false}
                                        helperText={inputErrors['user_source'] ? inputErrors['user_source'][0] : ''}
                                    >
                                        {/* <MenuItem value="all">
                                            {t('product.all')}
                                        </MenuItem> */}
                                        {_.map(sourceList, (option, key) => (
                                            <MenuItem key={key} value={key}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        size="small"
                                        label={t('product.amountType')}
                                        value={amount_type}
                                        onChange={({ target }) => setAmountType(target.value)}
                                        variant="outlined"
                                        fullWidth
                                        error={inputErrors['amount_type'] ? true : false}
                                        helperText={inputErrors['amount_type'] ? inputErrors['amount_type'][0] : ''}
                                    >
                                        {_.map(amountTypeList, (option, key) => (
                                            <MenuItem key={key} value={key}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('product.walletAmount')}</Typography>
                                    <Divider />
                                    {inputErrors['wallet_amount'] ? <Typography variant="subtitle2" color="error">{inputErrors['wallet_amount'][0]}</Typography> : null}
                                </Grid>
                                {
                                    _.map(wallets, (wallet, walletIndex) => {
                                        return (
                                            <Grid item xs={12} sm={6} key={walletIndex}>
                                                <TextField fullWidth size="small" label={wallet.name} error={inputErrors[`wallet_amount.${wallet.id}`] ? true : false} helperText={inputErrors[`wallet_amount.${wallet.id}`] ? inputErrors[`wallet_amount.${wallet.id}`][0] : ""} variant="outlined" value={walletAmount[wallet.id] ? walletAmount[wallet.id] : ""} onChange={e => setWalletAmount({...walletAmount, [wallet.id]: e.target.value })} type="number" />
                                            </Grid>
                                        )
                                    })
                                }
                                <Grid item xs={12} sm={12} container spacing={3} justify="center">
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="secondary" onClick={() => {setOptionIndex(""); setInputErrors([]);}}>{t('button.close')}</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Button type="button" fullWidth variant="contained" color="primary" onClick={saveOptions}>{t('button.save')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    actionRoot: {
        width: 'fit-content',
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        // height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        left: '2.5vw',
        // right: 0,
        top: '25%',
        // bottom: 0,
        margin: 'auto',
        overflow: 'auto',
    },
}));