import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { TextField, MenuItem, Button } from '@material-ui/core';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Snackbar, Typography, Divider, Breadcrumbs, Link, Grid, Paper, FormControlLabel, Switch } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { getUrl, postUrl } from '@utils/ApiAction';
import { useMessage } from '@utils/Tools';
import { API } from '@configs/AxiosConfig';

export default function OrderAdd() {
    const classes = useStyles();
    const theme = useTheme();
    const { accessToken } = useSelector(state => state.general);
    const { t } = useTranslation();

    API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    const [state, setState] = useState({
        username: '',
        merchant_company_id: '',
        product_type: '',
        rank: '',
        price: 0,
        bv: 0,
        profit_limit: 0,
        profit_limit_total: 0,
    });

    const [errorField, setErrorField] = useState({
        username: '',
        merchant_company_id: '',
        product_type: '',
        rank: '',
        price: '',
        bv: '',
        profit_limit: '',
        profit_limit_total: '',
    });
    const [merchant, setMerchant] = useState([]);
    const productType = [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}, {code: "repurchase", label: t('product.type.repurchase')}];
    const [rank, setRank] = useState([]);
    const { value:errorGeneral, setValue:setErrorGeneral, reset:resetErrorGeneral } = useMessage('');
    const { value:successGeneral, setValue:setSuccessGeneral, reset:resetSuccessGeneral } = useMessage('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    useEffect(() => {
        getUrl(`admin/companies_by_roles`).then(result => {
            setMerchant(result.data);
        })
    }, []);
    useEffect(() => {
        getUrl(`admin/grouped_rank_list`).then(result => {
            setRank(result.rank);
        })
    }, []);

    useEffect(() => {
        if(errorGeneral !== ""){
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        }
    }, [errorGeneral]);

    useEffect(() => {
        if(successGeneral !== ""){
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setErrorField({});
        }
    }, [successGeneral]);

    const handleChange = () => {
        setState({ ...state, status: !state.status });
    }

    const addOrder = () => {
        resetErrorGeneral();
        resetSuccessGeneral();
        postUrl('admin/orders', {
            username: state.username,
            merchant_company_id: state.merchant_company_id,
            product_type: state.product_type,
            rank: state.rank,
            price: state.price,
            bv: state.bv,
            profit_limit: state.profit_limit,
            profit_limit_total: state.profit_limit_total
        }).then(result => {
            if(result.status === 1) {
                setState({
                    username: '',
                    merchant_company_id: '',
                    product_type: '',
                    rank: '',
                    price: 0,
                    bv: 0,
                    profit_limit: 0,
                    profit_limit_total: 0,
                });
                setSuccessGeneral('Successfully updated');
            } else {
                setErrorField({});
                setErrorGeneral(result.message);
                if(result.error) {
                    let newErrorField = errorField;
                    _.map(newErrorField, (field, index) => {
                        if(_.size(result.error[index])){
                            newErrorField[index] = result.error[index];
                        }else{
                            newErrorField[index] = '';
                        }
                    });
                    setErrorField({ ...errorField, newErrorField })
                }
            }
        });
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{ t('order.addOrder') }</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/orders`} component={RouterLink}>{t('title.order')}</Link>
                        <Typography style={{ color: 'black' }}>{t('order.addOrder')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                        <MuiAlert elevation={6} variant="filled" onClose={closeSnackbar} severity={snackbarSeverity}>
                            {successGeneral || errorGeneral}
                        </MuiAlert>
                    </Snackbar>
                    <form className={classes.root} noValidate autoComplete="off" style={theme.p20}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="username" 
                                    label={ t('profile.username') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.username}
                                    onChange={({ target }) => setState({ ...state, username: target.value }) }    
                                    error={errorField.username ? true : false}
                                    helperText={errorField.username}
                                />
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField
                                    id="select-merchant"
                                    select
                                    label={ t('product.company') }
                                    value={state.merchant_company_id}
                                    onChange={({ target }) => setState({ ...state, merchant_company_id: target.value }) }
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                    error={errorField.merchant_company_id ? true : false}
                                    helperText={errorField.merchant_company_id}
                                >
                                    {
                                        _.map(merchant, (field, index) => (
                                            <MenuItem key={`merchant-${field.id}`} value={field.id}>
                                                {field.company_name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField
                                    id="select-productType"
                                    select
                                    label={ t('product.type.title') }
                                    value={state.product_type}
                                    onChange={({ target }) => setState({ ...state, product_type: target.value }) }
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                    error={errorField.product_type ? true : false}
                                    helperText={errorField.product_type}
                                >
                                    {
                                        _.map(productType, (field, index) => (
                                            <MenuItem key={field.code} value={field.code}>
                                                {field.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField
                                    id="select-rank"
                                    select
                                    label={ t('product.relatedRank') }
                                    value={state.rank}
                                    onChange={({ target }) => setState({ ...state, rank: target.value }) }
                                    variant="outlined"
                                    fullWidth
                                    className={classes.textField}
                                    error={errorField.rank ? true : false}
                                    helperText={errorField.rank}
                                >
                                    {
                                        _.map(rank, (field, index) => (
                                            <MenuItem key={`rank-${field.id}`} value={field.id}>
                                                {field.name_display}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="price" 
                                    label={ t('order.sellPrice') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.price}
                                    onChange={({ target }) => setState({ ...state, price: target.value }) }    
                                    error={errorField.price ? true : false}
                                    helperText={errorField.price}
                                />
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="bv" 
                                    label="BV"
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.bv}
                                    onChange={({ target }) => setState({ ...state, bv: target.value }) }    
                                    error={errorField.bv ? true : false}
                                    helperText={errorField.bv}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="profit_limit" 
                                    label={ t('editProfitLimit.profitLimit') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.profit_limit}
                                    onChange={({ target }) => setState({ ...state, profit_limit: target.value }) }    
                                    error={errorField.profit_limit ? true : false}
                                    helperText={errorField.profit_limit}
                                />
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="profit_limit_total" 
                                    label={ t('editProfitLimit.profitLimitTotal') }
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.profit_limit_total}
                                    onChange={({ target }) => setState({ ...state, profit_limit_total: target.value }) }    
                                    error={errorField.profit_limit_total ? true : false}
                                    helperText={errorField.profit_limit_total}
                                />
                            </Grid>
                        </Grid>
                            {/* <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={importProduct}>Import Now</Button>
                            </Grid> */}
                        {/* <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="value" 
                                    label={ t('parameter.value') }
                                    multiline
                                    rows={2}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.value}
                                    onChange={({ target }) => setState({ ...state, value: target.value }) }    
                                    error={errorField.value ? true : false}
                                    helperText={errorField.value}
                                />
                            </Grid>
                        </Grid>    
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <TextField 
                                    id="description" 
                                    label={ t('parameter.description') }
                                    multiline
                                    rows={4}
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.desc}
                                    onChange={({ target }) => setState({ ...state, desc: target.value }) }    
                                    error={errorField.desc ? true : false}
                                    helperText={errorField.desc}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <FormControlLabel
                                    control={<Switch checked={state.status} onChange={handleChange} />}
                                    label={ t('parameter.status') }
                                />
                            </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm>
                                <Button variant="contained" color="primary" onClick={addOrder}>{ t('button.add') }</Button>
                            </Grid>
                        </Grid>    
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));