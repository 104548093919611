import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Typography, Breadcrumbs, Link, Grid, Paper, IconButton, Collapse, Divider, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl, postUrl } from '@utils/ApiAction';
import _ from 'lodash';

export default function PaymentTransactionDetail() {
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams();
    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const [state, setState] = useState({
        id: '',
        purchase_no: '',
        source: '',
        request_amount: '',
        ref1: '',
        status: '',
        created_at: '',
        attempt: '',
        attempt_logs: [],
    });

    const [showLogs, setShowLogs] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [wallets, setWallets] = useState([]);

    useEffect(() => {
        setLoading(true);
        getUrl(`admin/payment-transaction-detail/${id}`).then(result => {
            setLoading(false);
            if (result.status === 1) {
                setState(result.data);
                setStatusList(result.status_display);
                setWallets(result.wallets);
            } else {
                addAlert('', t('error.contactSupport'));
            }
        }).catch(error => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg);
        });
        // eslint-disable-next-line
    }, [id, addAlert, t, i18n.language]);

    const formatRequestAmount = (amount) => {
        if (!amount || typeof amount !== 'object') return 'Unknown';
        return _.map(amount, (value, key) => {
            let displayWallet = _.find(wallets, function (w) { return parseInt(w.id) === parseInt(key); });
            if (displayWallet) {
                let displayName = displayWallet ? _.split(displayWallet.name, '|') : [];
                return `${i18n.language === 'cn' ? _.size(displayName) > 0 ? displayName[1] : displayName[0] : displayName[0]}: ${value}`;
            }
        }).join(', ');
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4">
                        {t('reports.paymentTransaction.detail')}
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink} className={classes.breadcrumb}>
                            {t('title.dashboard')}
                        </Link>
                        <Link to={`/reports/payment-transaction`} component={RouterLink} className={classes.breadcrumb}>
                            {t('title.reportPaymentTransaction')}
                        </Link>
                        <Typography color="textPrimary" className={classes.currentBreadcrumb}>
                            {t('reports.paymentTransaction.detail')}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.label}>
                                {t('reports.paymentTransaction.id')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.id}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('reports.paymentTransaction.purchaseNo')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.purchase_no}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('reports.paymentTransaction.source')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.source}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('reports.paymentTransaction.amount')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.request_amount}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('reports.paymentTransaction.ref1')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {formatRequestAmount(state.ref1)}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('reports.paymentTransaction.status')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {statusList[state.status] ? statusList[state.status][i18n.language] : state.status}
                            </Typography>
                        </Grid>

                        {state.attempt && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" className={classes.label}>
                                    {t('orderApi.attempt')}:
                                </Typography>
                                <Typography variant="body1" className={classes.value}>
                                    {state.attempt}
                                </Typography>
                            </Grid>
                        )}

                        {state.attempt_logs.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.label}>
                                    {t('orderApi.attemptLogs')}:
                                    <IconButton onClick={() => setShowLogs(!showLogs)} className={classes.expandButton}>
                                        {showLogs ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                </Typography>
                                <Collapse in={showLogs}>
                                    {state.attempt_logs.map((log, index) => (
                                        <div key={index}>
                                            <Typography variant="body2" className={classes.logItem}>
                                                {Object.entries(log).map(([key, value], idx) => {
                                                    const displayValue = typeof value === 'object' && value !== null ? JSON.stringify(value) : value;
                                                    return `${key}: ${displayValue}${idx < Object.entries(log).length - 1 ? ', ' : ''}`;
                                                }).join('')}
                                            </Typography>
                                        </div>
                                    ))}
                                </Collapse>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        color: theme.palette.primary.main,
    },
    currentBreadcrumb: {
        color: theme.palette.text.primary,
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        overflow: 'hidden',
    },
    label: {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    value: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
    },
    logItem: {
        marginBottom: theme.spacing(1),
    },
    responseItem: {
        marginBottom: theme.spacing(1),
    },
    expandButton: {
        marginLeft: theme.spacing(1),
    },
}));