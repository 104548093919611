import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useQuery } from '@utils/Tools';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, useTheme, TextField,Button } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import RestoreIcon from '@material-ui/icons/Restore';

import * as XLSX from 'xlsx';

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';


function TableToolbar(props) {
    const classes = useStyles();
    const { t  } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };
    const exportFile = () => {
        let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
        XLSX.writeFile(wb, `ReportSales${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
    }


    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="small" /></IconButton></Tooltip>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function ReportBVOrders() {
    const classes = useStyles();
    const theme = useTheme();

    const { t ,i18n} = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    // const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    // const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    // const [page, setPage] = useState(pageNumber);
    // const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [wallets , setWallets]=useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [orderStatus , setOrderStatus]=useState([]);
    const [dateFilter, setDateFilter] = useState({ sdate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }), edate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })});
    const [inputErrors, setInputErrors] = useState([]);
    const [reset, setReset]=useState(false);
    // const [dateFilter, setDateFilter] = useState(new Date());

    function handleSubmit(event) {
        event.preventDefault();        
        // setPage(1);
        // setUrl(1, rowsPerPage, searchValue);
        setUrl( searchValue);
        setQueryValue(searchValue);
        // handleReset();
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage+1);
    //     setUrl(newPage+1, '', searchValue);
    // };
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(1);
    //     setUrl(1, parseInt(event.target.value, 10), searchValue);
    // };

    const setUrl = (paging, rpp, sv) => {
        // let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        let params = { };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/simplified-sale${searchString !== '' ? '?'+searchString : ''}`);
    }

    const handleChangeSDateRange=(data)=>{
        setDateFilter({...dateFilter,sdate:data});
    }

    const handleChangeEDateRange=(value)=>{
        if(dateFilter.sdate){
            setDateFilter({...dateFilter,edate:value});
        }else{
            addAlert('',t('reports.bvOrder.insertEdateErr'), 'error', '');
        }
    }

    const handleFilter = () => {
        // let params = { 'page': page, 'per_page': rowsPerPage, 'q': querySearch, 'sdate': dateFilter.sdate, 'edate': dateFilter.edate };
        let params = { 'q': querySearch ,'sdate': dateFilter.sdate,'edate':dateFilter.edate };
        setTableLoading(true);

        if(dateFilter.sdate == ''){
            setTableLoading(false);
            addAlert('',t('reports.bvOrder.insertEdateErr'), 'error', '');
        }
        else{
            getUrl(`admin/bv_orders`,params).then(result => {
                setTableLoading(false);
                if (result.status === 1) {
                    setListing(result.data);
                    // setTotalCount(result.data.total);
                    setWallets(result.wallet);
                    setCurrencyData(result.currency_data);
                    setOrderStatus(result.order_status);
                    setReset(false);
                    setInputErrors([]);
                }else{
                    if(result.message){
                        addAlert('', result.message, 'error', '');
                    }else{
                        addAlert('', t('snackbarMsg.addError'), 'error', '');
                    }
                    if(result.error){
                        setInputErrors(result.error);
                    }
                    setReset(false);
                }
            }).catch((error) => {
                setTableLoading(false);
                setListing([]);
                setReset(false);
                setInputErrors([]);
                // setTotalCount(0);
                addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
            });
        }  
    }

    const handleReset = ()=>{
        setDateFilter({...dateFilter, sdate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),edate:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })});
        setReset(true);
    }

    function callApi(){
        // let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch  };
        let params = { 'q': querySearch ,'sdate': dateFilter.sdate,'edate':dateFilter.edate };
        setTableLoading(true);
        getUrl(`admin/bv_orders`,params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data);
                    // setTotalCount(result.data.total);
                    setWallets(result.wallet);
                    setCurrencyData(result.currency_data);
                    setOrderStatus(result.order_status);
                    setReset(false);
                    setInputErrors([]);
                }else{
                    if(result.message){
                        addAlert('', result.message, 'error', '');
                    }else{
                        addAlert('', t('snackbarMsg.addError'), 'error', '');
                    }
                    if(result.error){
                        setInputErrors(result.error);
                    }
                    setReset(false);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setReset(false);
                setInputErrors([]);
                // setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [ addAlert, querySearch]);

    useEffect(()=>{
        if(reset ==true){
            callApi();
        }
    },[reset]);
   
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.reportSimplifiedSale')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item>
                <TextField
                    id="datetime-local"
                    label={t('reports.bvOrder.from')}
                    type="date"
                    value={dateFilter.sdate}
                    onChange={({ target }) => handleChangeSDateRange(target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                    helperText={inputErrors.sdate ? inputErrors.sdate[0] :''}
                    error={inputErrors.sdate ? true : false}
                />
                <TextField
                    id="datetime-local"
                    label={t('reports.bvOrder.to')}
                    type="date"
                    value={dateFilter.edate}
                    onChange={({ target }) => handleChangeEDateRange(target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ padding: 5 }}
                    helperText={inputErrors.edate ? inputErrors.edate[0] :''}
                    error={inputErrors.edate ? true : false}
                />
                </Grid>
                <Grid item>
                    <Button size="medium" variant="outlined" color="inherit" onClick={() => handleReset()} startIcon={<RestoreIcon />}>{t('button.reset')}</Button>
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleFilter()} startIcon={<FilterListIcon />} style={{marginLeft:10}}>{t('button.filter')}</Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            id="exportTable"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('reports.bvOrder.orderNo')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.buyerUsername')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.product')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.bv')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.sellPrice')}</TableCell>
                                    <TableCell>{t('reports.bvOrder.quantity')}</TableCell>
                                    <TableCell>{t('reports.simplifiedSale.totalPrice')}</TableCell>
                                    <TableCell>{t('reports.simplifiedSale.totalBv')}</TableCell>
                                    {/* <TableCell>{t('reports.bvOrder.shopName')}</TableCell> */}
                                    <TableCell>{t('reports.bvOrder.createdAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    let title_translate = _.split(row.title, '|');
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row"><Link style={{color:"#929191"}} underline='none' to={`/orders/${row.order_no}`} component={RouterLink}>{row.order_no}</Link></TableCell>
                                            <TableCell>{ row.buyer_username ? row.buyer_username:row.buyer_name }</TableCell>
                                            <TableCell><Typography noWrap style={{width:'7vw'}}>{ i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0] }</Typography ></TableCell>
                                            <TableCell>{ row.bv }</TableCell>
                                            <TableCell>{ row.price }</TableCell>
                                            <TableCell>{ row.quantity }</TableCell>
                                            <TableCell>{ parseFloat(row.quantity*row.price).toFixed(2) }</TableCell>
                                            <TableCell>{ parseFloat(row.quantity*row.bv).toFixed(2) }</TableCell>
                                            {/* <TableCell>{ row.shop_name }</TableCell> */}
                                            <TableCell>{ row.created_at }</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={15} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    } */}
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));