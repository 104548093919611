import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '@utils/useNotificationLoading';

import _ from 'lodash';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Grid, Tooltip } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ProductPointSection({ pointList, pointData, setPointData }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    
    const [pointDialog, setPointDialog] = useState({
        open: false,
        key: 'none',
        id: 0,
        pointId: 0,
        pointValue: 0
    });
    const handlePointDialogOpen = (data) => {
        setPointDialog({
            open: true,
            key: data?data.key:'none',
            id: data?data.id:0,
            pointId: data?data.pointId:0,
            pointValue: data?data.pointValue:0,
        });
    };
    const handlePointDialogClose = () => {
        setPointDialog({
            open: false,
            key: 'none',
            id: 0,
            pointId: 0,
            pointValue: 0
        });
    };
    const pointDialogSave = () => {
        if(pointDialog.pointId === 0){
            addAlert('', t('general.pleaseSelect')+' '+t('product.pointType'), 'error', '');
        }else{
            let tempData = pointData;
            let error = false;
            _.map((tempData), (data, key) => {
                if(data.deleted === false && data.point_id === parseInt(pointDialog.pointId)){
                    if((pointDialog.id === 0 && pointDialog.key === 'none')||(data.id !== parseInt(pointDialog.id))||(pointDialog.key !== key)){
                        error = true;
                        addAlert('', t('product.duplicatePoint'), 'error', '');
                    }
                }
            })
            if(!error){
                let pointName = 'none';
                let pointSource = 'none';
                _.map((pointList), (data, key) => {
                    let name = _.split(data.name, '|');
                    if(data.id === parseInt(pointDialog.pointId)){
                        pointName = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                        pointSource = data.source;
                    }
                })
                if(pointDialog.id === 0 && pointDialog.key === 'none'){
                    tempData.push({id: pointDialog.id, point_id: parseInt(pointDialog.pointId), point_source: pointSource, point_name: pointName, value: pointDialog.pointValue, deleted: false});
                }else if(pointDialog.id !== 0){
                    _.map((tempData), (data, key) => {
                        if(data.id === parseInt(pointDialog.id)){
                            tempData[key] = {id: pointDialog.id, point_id: parseInt(pointDialog.pointId), point_source: pointSource, point_name: pointName, value: pointDialog.pointValue, deleted: false};
                        }
                    })
                }else{
                    tempData[pointDialog.key] = {id: pointDialog.id, point_id: parseInt(pointDialog.pointId), point_source: pointSource, point_name: pointName, value: pointDialog.pointValue, deleted: false};
                }
                setPointData(tempData);
                handlePointDialogClose();
            }
        }
    }
    const pointDialogDelete = (idata) => {
        let tempData = [];
        _.map((pointData), (data, key) => {
            if(key === idata.key){
                if(data.id !== 0){
                    tempData.push({id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: true});
                }
            }else{
                tempData.push({id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: data.deleted});
            }
        })
        setPointData(tempData);
    }

    return (
        <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5" component="h1">{t('product.points')}</Typography>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
                <Button variant="contained" color="primary" onClick={() => handlePointDialogOpen({key: 'none', id: 0, pointId: 0, pointValue: 0})}><AddIcon />{t('product.addProductPoint')}</Button>
            </Grid>
            {
                _.size(pointData) > 0
                ?
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>{ t('product.action') }</TableCell>
                                <TableCell>{ t('product.pointType') }</TableCell>
                                <TableCell>{ t('product.pointValue') }</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pointData.map((data, key) => {
                                if(data.deleted === false){
                                    let name = _.split(data.point_name, '|');
                                    return(
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Tooltip title={t('product.editProductPoint')}>
                                                    <IconButton variant="outlined" color="primary" onClick={() => handlePointDialogOpen({key: key, id: data.id, pointId: data.point_id, pointValue: data.value})}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t('product.deleteProductPoint')}>
                                                    <IconButton variant="outlined" color="primary" onClick={() => pointDialogDelete({key: key})}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{ data.point_source + ' ' + (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) }</TableCell>
                                            <TableCell>{ data.value }</TableCell>
                                        </TableRow>
                                    );
                                }else{
                                    return null;
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                null
            }
            <Dialog onClose={handlePointDialogClose} open={pointDialog.open} fullWidth>
                <DialogTitle onClose={handlePointDialogClose}>
                    {
                        pointDialog.id === 0 && pointDialog.key === 'none'
                        ?
                        t('product.addProductPoint')
                        :
                        t('product.editProductPoint')
                    }
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField 
                                id="point_id" 
                                label={t('product.pointType')}
                                variant="outlined" 
                                fullWidth 
                                select
                                value={pointDialog.pointId?pointDialog.pointId:''}
                                onChange={({ target }) => setPointDialog({ ...pointDialog, pointId: target.value})}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key={''} value={0}>
                                { t('general.pleaseSelect')+' '+t('product.pointType') }
                            </option>
                            {pointList.map((data, key) => {
                                let name = _.split(data.name, '|');
                                return(
                                    <option key={key} value={data.id} disabled={data.status?false:true}>
                                        { data.source + ' ' + (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) }
                                    </option>
                                );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                id="value" 
                                variant="outlined" 
                                fullWidth
                                label={t('product.pointValue')}
                                value={pointDialog.pointValue?pointDialog.pointValue:''}
                                onChange={({ target }) => setPointDialog({ ...pointDialog, pointValue: target.value})}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={pointDialogSave} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        flexGrow: 1
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    }

}));