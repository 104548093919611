import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Box, Button, FormControl, Grid, Icon, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Link, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ClearIcon from '@material-ui/icons/Clear';

import { getUrl } from '@utils/ApiAction';
import { useQuery } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function ReportPaymentTransaction() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];

    const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const searchSource = query.get("source") && query.get("source") !== '' ? query.get("source") : '';
    const searchStatus = query.get("status") && query.get("status") !== '' ? query.get("status") : '';
    const searchSdate = query.get("sdate") && query.get("sdate") !== '' ? query.get("sdate") : '';
    const searchEdate = query.get("edate") && query.get("edate") !== '' ? query.get("edate") : '';

    const [page, setPage] = useState(pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);

    const [tableLoading, setTableLoading] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [sources, setSources] = useState([]);
    const [searchData, setSearchData] = useState({
        source: searchSource,
        status: searchStatus,
        sdate: searchSdate,
        edate: searchEdate,
    });

    function handleSubmit(event) {
        event.preventDefault();        
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, searchData);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, searchData);
    };

    const setUrl = (paging, rpp, sv, sd=searchData) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(sd){
            _.map(sd, (value, key) => {
                if(value !== ''){
                    params[key] = value;
                }
            })
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/payment-transaction${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch };
        _.map(searchData, (value, key) => {
            if(value !== ''){
                params[key] = value;
            }
        })
        setTableLoading(true);
        getUrl('admin/payment-transaction-list', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                    setStatusList(result.status_display);
                    setWallets(result.wallets);
                    setSources(result.sources);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, searchSource, searchStatus, searchSdate, searchEdate]);

    const formatRequestAmount = (amount) => {
        if (!amount || typeof amount !== 'object') return 'Unknown';
        return _.map(amount, (value, key) => {
            let displayWallet = _.find(wallets, function (w) { return parseInt(w.id) === parseInt(key); });
            if (displayWallet) {
                let displayName = displayWallet ? _.split(displayWallet.name, '|') : [];
                return `${i18n.language === 'cn' ? _.size(displayName) > 0 ? displayName[1] : displayName[0] : displayName[0]}: ${value}`;
            }
        }).join(', ');
    };

    const handleSearchData = () => {
        setUrl(1, rowsPerPage, searchValue, searchData);
    }
    const clearSearchData = () => {
        let tempSearch = {
            source: '',
            status: '',
            sdate: '',
            edate: '',
        };
        setSearchData(tempSearch)
        setUrl(1, rowsPerPage, searchValue, tempSearch);
    }

    console.log('searchData', searchData);
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.reportPaymentTransaction')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{display:"flex", justifyContent: "flex-start"}}>
                <Grid container spacing={2}>

                <Grid item xs={6} sm={4} md={3}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>{t('reports.paymentTransaction.source')}</InputLabel>
                        <Select
                            name='source'
                            value={searchData.source}
                            onChange={({target}) => setSearchData({...searchData, [target.name]: target.value})}
                            label={t('reports.paymentTransaction.source')}
                        >
                            <MenuItem value="">
                                <em>{t('table.all')}</em>
                            </MenuItem>
                            {sources && _.map(sources, (source) => (
                                <MenuItem key={source} value={source}>
                                    {source}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>{t('reports.paymentTransaction.status')}</InputLabel>
                        <Select
                            name='status'
                            value={searchData.status}
                            onChange={({target}) => setSearchData({...searchData, [target.name]: target.value})}
                            label={t('reports.paymentTransaction.status')}
                        >
                            <MenuItem value="">
                                <em>{t('table.all')}</em>
                            </MenuItem>
                            {statusList && _.map(statusList, (status, key) => (
                                <MenuItem key={key} value={key}>
                                    {status[i18n.language]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <TextField
                        variant="outlined"
                        size="small"
                        label={t('reports.dailysales.from')}
                        type="date"
                        value={searchData.sdate}
                        name="sdate"
                        onChange={({ target }) => setSearchData({ ...searchData, [target.name]: target.value })}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <TextField
                        variant="outlined"
                        size="small"
                        label={t('reports.dailysales.to')}
                        type="date"
                        value={searchData.edate}
                        name="edate"
                        onChange={({ target }) => setSearchData({ ...searchData, [target.name]: target.value })}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Button variant="contained" color="primary" onClick={() => handleSearchData()} style={{marginRight: 20}}>
                        {t('table.search')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => clearSearchData()}>
                        {t('table.clear')}
                    </Button>
                </Grid>
                                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={theme.box1}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            id="exportTable"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('reports.paymentTransaction.id')}</TableCell>
                                    <TableCell>{t('reports.paymentTransaction.purchaseNo')}</TableCell>
                                    <TableCell>{t('reports.paymentTransaction.source')}</TableCell>
                                    <TableCell>{t('reports.paymentTransaction.amount')}</TableCell>
                                    <TableCell>{t('reports.paymentTransaction.ref1')}</TableCell>
                                    <TableCell>{t('reports.paymentTransaction.status')}</TableCell>
                                    <TableCell>{t('reports.paymentTransaction.createdAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                <Link underline='none' to={`/payment-transaction/${row.id}`} component={RouterLink}>{row.id}</Link>
                                            </TableCell>
                                            <TableCell>{ row.purchase_no }</TableCell>
                                            <TableCell>{ row.source }</TableCell>
                                            <TableCell>{ row.request_amount }</TableCell>
                                            <TableCell>{ formatRequestAmount(row.ref1) }</TableCell>
                                            <TableCell>{ statusList[row.status] ? statusList[row.status][i18n.language] : row.status }</TableCell>
                                            <TableCell>{ row.created_at }</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={15} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));