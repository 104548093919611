import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Typography, Breadcrumbs, Link, Grid, Paper, IconButton, Collapse, Divider, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl, postUrl } from '@utils/ApiAction';

export default function OrderApiDetails() {
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams();
    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const [state, setState] = useState({
        order_id: '',
        type: '',
        ref_type: '',
        username: '',
        source: '',
        request_amount: '',
        attempt: '',
        attempt_logs: [],
        response: [],
        status: '',
    });

    const [showLogs, setShowLogs] = useState(false);
    const [showResponse, setShowResponse] = useState(false);

    const orderType = window.location.pathname.includes('order-claim') ? 'order-claim' : 'order-refund';

    const statusMapping = {
        10: { en: 'Pending', cn: '待处理' },
        20: { en: 'Completed', cn: '已完成' },
        30: { en: 'Cancelled', cn: '已取消' },
    };

    const getLocalizedStatus = (statusCode) => {
        const language = i18n.language || 'en';
        return statusMapping[statusCode] ? statusMapping[statusCode][language] : 'Unknown';
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`admin/order/${orderType}/${id}`).then(result => {
            if (result.data && result.status === 1) {
                setState({
                    id: result.data.id || '',
                    type: result.data.type || '',
                    ref_type: result.data.ref_type || '',
                    username: result.data.username || '',
                    source: result.data.source || '',
                    request_amount: JSON.stringify(result.data.request_amount || ''),
                    attempt: result.data.attempt || '',
                    attempt_logs: result.data.attempt_logs || [],
                    response: result.data.response || [],
                    status: result.data.status || '',
                });
            } else {
                addAlert('', t('error.contactSupport'));
            }
            setLoading(false);
        }).catch(error => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg);
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [orderType, id, addAlert, t, i18n.language]);

    const handlePushToBonusChains = () => {
        setLoading(true);
        postUrl(`admin/order/push_to_bonus_chains/${id}`)
            .then(response => {
                if (response.status === 1) {
                    addAlert('', t('orderApi.successRequest'), 'success', '');
                } else {
                    addAlert('', t('error.contactSupport'), 'error', '');
                }
            })
            .catch(error => {
                addAlert(error + "\n" + t('error.contactSupport'), 'error');
            })
            .finally(() => {
                getUrl(`admin/order/${orderType}/${id}`).then(result => {
                    if (result.data && result.status === 1) {
                        setState({
                            order_id: result.data.order_id || '',
                            type: result.data.type || '',
                            ref_type: result.data.ref_type || '',
                            username: result.data.username || '',
                            source: result.data.source || '',
                            request_amount: JSON.stringify(result.data.request_amount || ''),
                            attempt: result.data.attempt || '',
                            attempt_logs: result.data.attempt_logs || [],
                            response: result.data.response || [],
                            status: result.data.status || '',
                        });
                    } else {
                        addAlert('', t('error.contactSupport'));
                    }
                    setLoading(false);
                }).catch(error => {
                    let msg = error + "\n" + t('error.contactSupport');
                    addAlert('', msg);
                    setLoading(false);
                });
            });
    };    

    const orderDetailsKey = orderType === 'order-claim' ? 'orderApi.orderClaim' : 'orderApi.orderRefund';
    const orderTitleKey = orderType === 'order-claim' ? 'orderApi.orderClaim' : 'orderApi.orderRefund';

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1" className={classes.title}>
                        {t(orderDetailsKey)}
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink} className={classes.breadcrumb}>
                            {t('title.dashboard')}
                        </Link>
                        <Link to={`/third-party/${orderType}`} component={RouterLink} className={classes.breadcrumb}>
                            {t(orderTitleKey)}
                        </Link>
                        <Typography color="textPrimary" className={classes.currentBreadcrumb}>
                            {t(orderDetailsKey)}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="flex-start">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePushToBonusChains}
                        className={classes.actionButton}
                    >
                        {t('orderApi.sendRequest', { type: orderType })}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.label}>
                                {t('orderApi.orderId')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.id}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('parameter.type')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.type}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('orderApi.refType')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.ref_type}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {orderType === 'order-claim' ? t('orderApi.shopName') : t('orderApi.username')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.username}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('orderApi.source')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.source}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.label}>
                                {t('orderApi.amount')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {state.request_amount}
                            </Typography>
                        </Grid>

                        {state.attempt && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" className={classes.label}>
                                    {t('orderApi.attempt')}:
                                </Typography>
                                <Typography variant="body1" className={classes.value}>
                                    {state.attempt}
                                </Typography>
                            </Grid>
                        )}

                        {state.attempt_logs.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.label}>
                                    {t('orderApi.attemptLogs')}:
                                    <IconButton onClick={() => setShowLogs(!showLogs)} className={classes.expandButton}>
                                        {showLogs ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                </Typography>
                                <Collapse in={showLogs}>
                                    {state.attempt_logs.map((log, index) => (
                                        <div key={index}>
                                            <Typography variant="body2" className={classes.logItem}>
                                                {Object.entries(log).map(([key, value], idx) => {
                                                    const displayValue = typeof value === 'object' && value !== null ? JSON.stringify(value) : value;
                                                    return `${key}: ${displayValue}${idx < Object.entries(log).length - 1 ? ', ' : ''}`;
                                                }).join('')}
                                            </Typography>
                                        </div>
                                    ))}
                                </Collapse>
                            </Grid>
                        )}

                        {state.response.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.label}>
                                    {t('orderApi.response')}:
                                    <IconButton onClick={() => setShowResponse(!showResponse)} className={classes.expandButton}>
                                        {showResponse ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                </Typography>
                                <Collapse in={showResponse}>
                                    {state.response.map((res, index) => (
                                        <Typography variant="body2" key={index} className={classes.responseItem}>
                                            {Object.entries(res).map(([key, value], idx) => (
                                                `${key}: ${typeof value === 'object' && value !== null ? JSON.stringify(value) : value}`
                                            )).join(', ')}
                                        </Typography>
                                    ))}
                                </Collapse>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.label}>
                                {t('parameter.status')}:
                            </Typography>
                            <Typography variant="body1" className={classes.value}>
                                {getLocalizedStatus(state.status)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 700,
    },
    breadcrumb: {
        color: theme.palette.primary.main,
    },
    currentBreadcrumb: {
        color: theme.palette.text.primary,
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        overflow: 'hidden',
    },
    label: {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    value: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
    },
    logItem: {
        marginBottom: theme.spacing(1),
    },
    responseItem: {
        marginBottom: theme.spacing(1),
    },
    expandButton: {
        marginLeft: theme.spacing(1),
    },
}));