// GENERAL
export const ADD_ERROR = 'add_error';
export const CHANGE_LANGUAGE = 'change_language';
export const STORE_UUID = 'store_uuid';
export const REMOVE_UUID = 'remove_uuid';
export const CART_TOTAL = 'cart_total';
export const CODE_STATE = 'code_state';
export const RESET_CODE_STATE = 'reset_code_state';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_FAIL = 'auth_fail'
export const ORDER_COUNT = 'order_count';
export const ANNOUNCE_UNREAD_COUNT = 'announce_unread_count';

// USER
export const STORE_PROFILE = 'store_profile';
export const RESET_PROFILE = 'reset_profile';