import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import { Typography, Button, Grid, Link } from '@material-ui/core';

export default function CompanyEditMerchantList({ id, userId, merchantList }) {
    const { t } = useTranslation();

    useEffect(() => {
        
    }, []);

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
                <Link style={{ color: 'black' }} underline='none' to={{ pathname: "/merchants/add/" + id }} component={RouterLink}><Button variant="outlined" style={{ marginBottom: "30px" }}><AddIcon />{t('seller.addMerchant')}</Button></Link>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable
                    title=""
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: t('profile.name'), field: 'name', render: rowData => (rowData.id !== userId) ? <Link underline='none' to={`/merchants/${rowData.id}`} component={RouterLink}>{rowData.name}</Link> : <Typography>{rowData.name}</Typography> },
                        { title: t('user.role'), field: 'user_role', render: rowData => t('role.' + rowData.user_role) },
                        { title: t('table.status'), field: 'status', render: rowData => t('user.status.' + rowData.status) },
                    ]}
                    data={merchantList}
                    options={{
                        pageSize: 10,
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: t('table.labelDisplayedRows'),
                            labelRowsSelect: t('table.rows'),
                            firstTooltip: t('table.firstPage'),
                            previousTooltip: t('table.previousPage'),
                            nextTooltip: t('table.nextPage'),
                            lastTooltip: t('table.lastPage'),
                        },
                        toolbar: {
                            searchTooltip: t('table.search'),
                            searchPlaceholder: t('table.search'),
                        },
                        body: {
                            emptyDataSourceMessage: t('table.noRecord'),
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}